import { createSelector } from 'reselect';
import { AppState, CountriesState, ICountry } from '../types/types';

// SELECTORS
export const getCountries = (state: AppState) => state.countries;
export const getCountryById = (id?: number) => {
    return createSelector(getCountries, (countries: CountriesState) => {
        if (id) return countries[id];
        return undefined;
    });
};
export const getCountryName = (id?: number) => {
    return createSelector(
        getCountryById(id),
        (country: ICountry | undefined) => country?.name,
    );
};
export const getCountryCode = (id?: number) => {
    return createSelector(
        getCountryById(id),
        (country: ICountry | undefined) => country?.iso_3166_2,
    );
};

export const getEeaCountries = createSelector(
    getCountries,
    (countries: CountriesState) =>
        Object.values(countries)?.filter((country: ICountry) => !!country.eea),
);

// ACTION TYPES
const COUNTRIES_SET = 'COUNTRIES_SET';
const COUNTRIES_UPDATE = 'COUNTRIES_UPDATE';

type ACTION =
    | {
          type: typeof COUNTRIES_SET;
          payload: ICountry[];
      }
    | {
          type: typeof COUNTRIES_UPDATE;
          payload: ICountry[];
      };

// ACTIONS
export const countriesSet = (payload: { [key: number]: ICountry }) => ({
    type: COUNTRIES_SET,
    payload,
});
export const countriesUpdate = (payload: { [key: number]: ICountry }) => ({
    type: COUNTRIES_UPDATE,
    payload,
});

const INITIAL_STATE: CountriesState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case COUNTRIES_SET:
            const countries: CountriesState = {};
            action.payload.forEach(
                country => (countries[country.id] = country),
            );
            return countries;

        case COUNTRIES_UPDATE:
            const nextCountries: CountriesState = {};
            action.payload.forEach(
                country => (nextCountries[country.id] = country),
            );
            return { ...state, nextCountries };

        default:
            return state;
    }
};
