import { createSelector } from 'reselect';
import {
    AppState,
    ExternalConnection,
    ExternalConnectionsState,
} from '../types/types';

// SELECTORS
export const getExternalConnections = (state: AppState) =>
    state.externalConnections;
export const getExternalConnectionById = (id: number) => {
    return createSelector(
        getExternalConnections,
        (externalConnections: ExternalConnectionsState) => {
            return externalConnections[id];
        },
    );
};

// ACTION TYPES
const EXTERNAL_CONNECTIONS_SET = 'EXTERNAL_CONNECTIONS_SET';
const EXTERNAL_CONNECTIONS_UPDATE = 'EXTERNAL_CONNECTIONS_UPDATE';
const EXTERNAL_CONNECTION_UPDATE = 'EXTERNAL_CONNECTION_UPDATE';
const EXTERNAL_CONNECTION_ADD = 'EXTERNAL_CONNECTION_ADD';
const EXTERNAL_CONNECTION_DELETE = 'EXTERNAL_CONNECTION_DELETE';

type ACTION =
    | {
          type: typeof EXTERNAL_CONNECTIONS_SET;
          payload?: ExternalConnection[];
      }
    | {
          type: typeof EXTERNAL_CONNECTIONS_UPDATE;
          payload?: ExternalConnection[];
      }
    | {
          type: typeof EXTERNAL_CONNECTION_UPDATE;
          payload?: ExternalConnection;
      }
    | {
          type: typeof EXTERNAL_CONNECTION_ADD;
          payload?: ExternalConnection;
      }
    | {
          type: typeof EXTERNAL_CONNECTION_DELETE;
          payload?: ExternalConnection['id'];
      };

// ACTIONS
export const externalConnectionsSet = (payload?: {
    [key: number]: ExternalConnection;
}) => ({
    type: EXTERNAL_CONNECTIONS_SET,
    payload,
});
export const externalConnectionsUpdate = (payload?: ExternalConnection[]) => ({
    type: EXTERNAL_CONNECTIONS_UPDATE,
    payload,
});

export const externalConnectionUpdate = (payload?: ExternalConnection) => ({
    type: EXTERNAL_CONNECTION_UPDATE,
    payload,
});
export const externalConnectionAdd = (payload?: ExternalConnection) => ({
    type: EXTERNAL_CONNECTION_ADD,
    payload,
});

export const externalConnectionDelete = (
    payload: ExternalConnection['id'],
) => ({
    type: EXTERNAL_CONNECTION_DELETE,
    payload,
});

const INITIAL_STATE: ExternalConnectionsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case EXTERNAL_CONNECTIONS_SET:
            const externalConnections: ExternalConnectionsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    externalConnection =>
                        (externalConnections[
                            externalConnection.id
                        ] = externalConnection),
                );
            }
            return externalConnections;
        case EXTERNAL_CONNECTIONS_UPDATE:
            const nextExternalConnections: ExternalConnectionsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    externalConnection =>
                        (nextExternalConnections[
                            externalConnection.id
                        ] = externalConnection),
                );
            }
            return { ...state, ...nextExternalConnections };
        case EXTERNAL_CONNECTION_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case EXTERNAL_CONNECTION_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case EXTERNAL_CONNECTION_DELETE:
            const externalConnectionsState = { ...state };
            if (action.payload) {
                delete externalConnectionsState[action.payload];
            }
            return externalConnectionsState;

        default:
            return state;
    }
};
