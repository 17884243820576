import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CreateTransactionModal } from './CreateTransactionModal';
import { FiltersHeader } from './FiltersHeader';
import { Tr } from './Tr';
import {
    AppHeader,
    CurrenciesFooter,
    PaginationButtons,
    Th,
} from '../../components';
import {
    asc,
    checkBoxFilter,
    desc,
    getHeaderHelper2,
} from '../../services/helpers';
import {
    acceptFactoringRequest,
    archiveTransactions,
    cancelFactoringRequest,
    factorTransactions,
    fetchTransactions,
    rejectFactoringRequest,
    sendToFactorTransactions,
} from '../../services/TransactionsService';
import {
    getCountTransactionsArchived,
    getCountTransactionsFactoringRequests,
    getCountTransactionsPayables,
    getCountTransactionsReceivables,
} from '../../state/collectionsCount';
import { getTransactions } from '../../state/transactions';
import { FactorRequestTransaction, Transaction } from '../../types/types';
import { TotalAmounts } from '../Home/TotalAmounts';
import { getConnectedFactoringOrganizations } from '../../state/connectedFactoringOrganizations';
import {
    fetchConnectedFactoringOrganizations,
    fetchCurrentOrganizationStatistics,
} from '../../services/OrganizationsService';
import {
    getAbilityToCreateTransactions,
    getAbilityToManageTransactions,
    getFactoringAbility,
    getMyCurrentOrganizationType,
    getMyFactoringOrganizations,
} from '../../state/myOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';

type SortField =
    | 'status'
    | 'created_at'
    | 'to_organization_id'
    | 'from_organization_id'
    | 'payment_status'
    | 'amount'
    | 'amount_due'
    | 'request_status';

const sort = (
    data: { [key: number]: any },
    order: 'ASC' | 'DESC',
    field: SortField,
): any[] => {
    const array: Transaction[] = Object.values(data);
    if (array.length) {
        array.sort((a, b) => {
            const A =
                field === 'created_at'
                    ? DateTime.fromSQL(a.created_at).valueOf()
                    : field === 'to_organization_id'
                    ? a?.title?.user
                    : field === 'from_organization_id'
                    ? a?.title2?.user
                    : field === 'request_status'
                    ? a?.factor_request_transaction?.status
                    : a[field];
            const B =
                field === 'created_at'
                    ? DateTime.fromSQL(b.created_at).valueOf()
                    : field === 'to_organization_id'
                    ? b?.title?.user
                    : field === 'from_organization_id'
                    ? b?.title2?.user
                    : field === 'request_status'
                    ? b?.factor_request_transaction?.status
                    : b[field];
            if (order === 'DESC') {
                return desc(A, B);
            }
            return asc(A, B);
        });
    }
    return array;
};

export const Transactions = () => {
    const transactionsState = useSelector(getTransactions);
    const connectedFactoringOrganizations = useSelector(
        getConnectedFactoringOrganizations,
    );

    const [sortField, setSortField] = useState<SortField>('created_at');
    const [sortOrder, setSortOrder] = useState<{
        [key: string]: 'ASC' | 'DESC';
    }>({
        status: 'DESC',
        created_at: 'DESC',
        to_organization_id: 'DESC',
        payment_status: 'DESC',
        amount: 'DESC',
        amount_due: 'DESC',
    });

    const setOrderMethod = useCallback(
        (value: SortField) => {
            setSortField(prevState => {
                if (prevState === value) {
                    if (sortOrder[value] === 'ASC') {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'DESC',
                        }));
                    } else {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'ASC',
                        }));
                    }
                }
                return value;
            });
        },
        [sortOrder],
    );

    const transactions: Transaction[] = useMemo(
        () => sort(transactionsState, sortOrder[sortField], sortField),
        [sortField, sortOrder, transactionsState],
    );

    const [isModalOpen, setModalOpen] = useState(false);

    const [transactionType, setTransactionType] = useState<'ptp' | 'invoice'>(
        'ptp',
    );

    const showModal = useCallback(() => {
        setModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const createPtp = useCallback(() => {
        setTransactionType('ptp');
        showModal();
    }, [showModal]);

    const createInvoice = useCallback(() => {
        setTransactionType('invoice');
        showModal();
    }, [showModal]);

    const [query, setQuery] = useState<string>('');

    const [delayQuery, setDelayQuery] = useState<string>();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setQuery(delayQuery?.toUpperCase() || '');
        }, 350);

        return () => clearTimeout(delayDebounceFn);
    }, [delayQuery]);

    const location = useLocation();

    const type = useMemo(
        () =>
            location?.pathname === '/transactions/receivables'
                ? 'receivable'
                : location?.pathname === '/transactions/archived'
                ? 'archived'
                : location?.pathname === '/factoring-requests/transactions'
                ? 'factor-request'
                : 'payable',
        [location?.pathname],
    );

    const [activeTabList, setActiveTabList] = useState<Transaction[]>(
        transactions,
    );

    useEffect(() => {
        if (type === 'payable') {
            setActiveTabList(
                transactions.filter(
                    item => item?.is_payer && item.status !== 'archived',
                ),
            );
        } else if (type === 'receivable') {
            setActiveTabList(
                transactions.filter(
                    item => !item?.is_payer && item.status !== 'archived',
                ),
            );
        } else if (type === 'factor-request') {
            setActiveTabList(
                transactions.filter(
                    item => !!item?.factor_request_transaction?.id,
                ),
            );
        } else if (type === 'archived') {
            setActiveTabList(
                transactions.filter(item => item.status === 'archived'),
            );
        }
    }, [transactions, type]);

    const payablesCount = useSelector(getCountTransactionsPayables);
    const receivablesCount = useSelector(getCountTransactionsReceivables);
    const archivedCount = useSelector(getCountTransactionsArchived);
    const factoringRequestCount = useSelector(
        getCountTransactionsFactoringRequests,
    );

    const count = useMemo(
        () =>
            type === 'receivable'
                ? receivablesCount
                : type === 'factor-request'
                ? factoringRequestCount
                : type === 'archived'
                ? archivedCount
                : payablesCount,
        [
            archivedCount,
            factoringRequestCount,
            payablesCount,
            receivablesCount,
            type,
        ],
    );

    const [transactionsMeta, setTransactionsMeta] = useState({
        current_page: 0,
        last_page: 1,
        total: count || 0,
    });

    const [isLoading, setLoading] = useState(false);
    const [status, setStatus] = useState<Transaction['status'][]>([]);
    const [paymentStatus, setPaymentStatus] = useState<
        Transaction['payment_status'][]
    >([]);

    const [factoringRequestStatus, setFactoringRequestStatus] = useState<
        FactorRequestTransaction['status'][]
    >([]);

    const myFactoringOrganizations = useSelector(getMyFactoringOrganizations);

    const fetchUserTransactions = useCallback(
        async (page = 1, update?: boolean) => {
            setLoading(true);
            const transactionsResponse = await fetchTransactions(
                {
                    page,
                    type,
                    status: status?.length ? status?.toString() : '',
                    payment_status: paymentStatus?.length
                        ? paymentStatus?.toString()
                        : '',
                    factoring_status: factoringRequestStatus?.length
                        ? factoringRequestStatus?.toString()
                        : '',
                    q: query,
                    sortField,
                    sortOrder: sortOrder[sortField],
                },
                update,
            );
            setLoading(false);
            if (transactionsResponse?.data?.meta) {
                setTransactionsMeta(transactionsResponse?.data?.meta);
            }
            setActionFilter([]);
            setAllPreparedForAction(false);
        },
        [
            factoringRequestStatus,
            paymentStatus,
            query,
            sortField,
            sortOrder,
            status,
            type,
        ],
    );

    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                await fetchUserTransactions();
                await fetchConnectedFactoringOrganizations();
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId, fetchUserTransactions]);

    const onPressNext = useCallback(async () => {
        await fetchUserTransactions(transactionsMeta.current_page + 1);
    }, [fetchUserTransactions, transactionsMeta.current_page]);

    const onPressPrev = useCallback(async () => {
        await fetchUserTransactions(transactionsMeta.current_page - 1);
    }, [fetchUserTransactions, transactionsMeta.current_page]);

    const history = useHistory();

    const onClick = useCallback(
        (id?: number) => {
            if (id) {
                if (type === 'factor-request') {
                    history.push(`/factoring-requests/transaction/${id}`);
                } else {
                    history.push(`/transactions/transaction/${id}`);
                }
            }
        },
        [history, type],
    );
    const headerTitle = useMemo(() => {
        return getHeaderHelper2(location?.pathname);
    }, [location?.pathname]);

    const [actionFilter, setActionFilter] = useState<string[]>([]);

    const abilityToManageTransactions = useSelector(
        getAbilityToManageTransactions,
    );

    const checkBoxRef = useRef<HTMLInputElement>(null);

    const onActionFilterChange = useCallback(event => {
        setActionFilter(prevState => {
            const nextState = checkBoxFilter(event, prevState) as any;
            if (!nextState?.length) {
                setAllPreparedForAction(false);
            }
            return nextState;
        });
    }, []);

    const factoringAbility = useSelector(getFactoringAbility);

    useEffect(() => {
        if (
            actionFilter?.length &&
            myFactoringOrganizations?.length &&
            factoringAbility
        ) {
            const checkedTransactions = activeTabList?.filter(tr =>
                actionFilter?.some(id => Number(id) === tr?.id),
            );
            setFactorOrganizations(
                myFactoringOrganizations.filter(org =>
                    checkedTransactions?.every(
                        ckd => org?.id !== ckd?.title?.id,
                    ),
                ),
            );
        }
    }, [
        actionFilter,
        activeTabList,
        factoringAbility,
        myFactoringOrganizations,
        myFactoringOrganizations?.length,
    ]);

    const [
        currentConnectedFactoringOrganizations,
        setCurrentConnectedFactoringOrganizations,
    ] = useState(connectedFactoringOrganizations);

    const [
        cancelFactorRequestId,
        setCancelFactorRequestId,
    ] = useState<number>();

    useEffect(() => {
        if (actionFilter?.length && abilityToManageTransactions) {
            const checkedTransactions = activeTabList?.filter(tr =>
                actionFilter?.some(id => Number(id) === tr?.id),
            );
            if (connectedFactoringOrganizations?.length) {
                setCurrentConnectedFactoringOrganizations(
                    connectedFactoringOrganizations.filter(org =>
                        checkedTransactions?.every(ckd => {
                            return (
                                org?.id !== ckd?.title?.id &&
                                (!ckd?.factor_request_transaction
                                    ?.factor_organization_id ||
                                    ckd?.factor_request_transaction?.status ===
                                        'Canceled' ||
                                    ckd?.factor_request_transaction?.status ===
                                        'Rejected')
                            );
                        }),
                    ),
                );
            }
            if (
                checkedTransactions?.length === 1 &&
                checkedTransactions[0]?.can_factor_cancel &&
                checkedTransactions[0]?.factor_request_transaction?.status ===
                    'Pending' &&
                checkedTransactions[0]?.factor_request_transaction?.id &&
                checkedTransactions[0]?.status === 'open'
            ) {
                setCancelFactorRequestId(
                    checkedTransactions[0]?.factor_request_transaction?.id,
                );
            } else {
                setCancelFactorRequestId(undefined);
            }
        }
    }, [
        abilityToManageTransactions,
        actionFilter,
        activeTabList,
        connectedFactoringOrganizations,
        currentOrganizationId,
        factoringAbility,
        myFactoringOrganizations,
        myFactoringOrganizations.length,
    ]);

    const [factorOrganizations, setFactorOrganizations] = useState(
        myFactoringOrganizations,
    );

    const potentialActionFilterLength = useMemo(() => {
        return activeTabList?.filter(tr => {
            if (
                abilityToManageTransactions &&
                factoringAbility &&
                type === 'receivable'
            ) {
                if (factorOrganizations?.length === 1) {
                    if (factorOrganizations[0]?.id === tr?.id) {
                        return tr?.can_archive;
                    }
                }
                return tr?.can_archive || tr?.status === 'open';
            }
            if (abilityToManageTransactions && type !== 'archived') {
                if (type === 'factor-request') {
                    return tr?.status === 'open' || tr?.can_archive;
                }
                return tr?.can_archive;
            }
            if (
                !abilityToManageTransactions &&
                factoringAbility &&
                type === 'receivable'
            ) {
                if (factorOrganizations?.length === 1) {
                    if (factorOrganizations[0]?.id === tr?.id) {
                        return undefined;
                    }
                }
                return tr?.status === 'open';
            }
            return undefined;
        })?.length;
    }, [
        abilityToManageTransactions,
        activeTabList,
        factorOrganizations,
        factoringAbility,
        type,
    ]);

    useEffect(() => {
        if (checkBoxRef?.current) {
            if (
                !!actionFilter?.length &&
                actionFilter?.length < potentialActionFilterLength
            ) {
                checkBoxRef.current.indeterminate = true;
            } else if (actionFilter?.length) {
                checkBoxRef.current.indeterminate = false;
                if (actionFilter?.length === potentialActionFilterLength) {
                    setAllPreparedForAction(true);
                }
            } else if (!actionFilter?.length) {
                checkBoxRef.current.indeterminate = false;
            }
        }
    }, [activeTabList, actionFilter?.length, potentialActionFilterLength]);

    const [isAllPreparedForAction, setAllPreparedForAction] = useState(false);

    const addAllToActionFilter = useCallback(
        event => {
            if (event.target.checked) {
                if (activeTabList?.length) {
                    setActionFilter(
                        activeTabList
                            ?.filter(tr => {
                                if (
                                    abilityToManageTransactions &&
                                    factoringAbility &&
                                    type === 'receivable'
                                ) {
                                    return (
                                        tr?.can_archive || tr?.status === 'open'
                                    );
                                }
                                if (
                                    abilityToManageTransactions &&
                                    type !== 'archived'
                                ) {
                                    if (type === 'factor-request') {
                                        return (
                                            tr?.status === 'open' ||
                                            tr?.can_archive
                                        );
                                    }
                                    return tr?.can_archive;
                                }
                                if (
                                    !abilityToManageTransactions &&
                                    factoringAbility &&
                                    type === 'receivable'
                                ) {
                                    return tr?.status === 'open';
                                }
                                if (
                                    abilityToManageTransactions &&
                                    type === 'receivable'
                                ) {
                                    return tr?.status === 'open';
                                }

                                return undefined;
                            })
                            ?.map(tr => tr?.id?.toString()),
                    );
                    setAllPreparedForAction(true);
                }
            } else {
                setActionFilter([]);
                setAllPreparedForAction(false);
            }
        },
        [abilityToManageTransactions, activeTabList, factoringAbility, type],
    );

    const myCurrentOrganizationType = useSelector(getMyCurrentOrganizationType);

    const canArchive = useMemo(() => {
        if (
            type === 'factor-request' &&
            myCurrentOrganizationType === 'factoring'
        ) {
            return false;
        }
        return abilityToManageTransactions && type !== 'archived'
            ? activeTabList?.every(tr => {
                  if (
                      !tr?.can_archive &&
                      actionFilter?.includes(tr?.id?.toString())
                  ) {
                      return false;
                  }
                  return true;
              })
            : false;
    }, [
        abilityToManageTransactions,
        actionFilter,
        activeTabList,
        myCurrentOrganizationType,
        type,
    ]);

    const canFactor = useMemo(() => factoringAbility && type === 'receivable', [
        factoringAbility,
        type,
    ]);

    const isFactorButtonVisible = useMemo(
        () =>
            canFactor
                ? activeTabList?.every(tr => {
                      if (
                          (tr?.status !== 'open' ||
                              tr?.factor_request_transaction?.status ===
                                  'Pending') &&
                          actionFilter?.includes(tr?.id?.toString())
                      ) {
                          return false;
                      }
                      return true;
                  })
                : false,
        [actionFilter, activeTabList, canFactor],
    );

    const canFactorRequest = useMemo(
        () =>
            abilityToManageTransactions &&
            (type === 'receivable' || type === 'factor-request') &&
            !!connectedFactoringOrganizations?.length,
        [
            abilityToManageTransactions,
            connectedFactoringOrganizations?.length,
            type,
        ],
    );

    const isFactorRequestButtonVisible = useMemo(
        () =>
            canFactorRequest
                ? activeTabList?.every(tr => {
                      if (
                          (tr?.status !== 'open' ||
                              tr?.factor_request_transaction?.status ===
                                  'Pending') &&
                          actionFilter?.includes(tr?.id?.toString())
                      ) {
                          return false;
                      }
                      return true;
                  })
                : false,
        [actionFilter, activeTabList, canFactorRequest],
    );

    const canAcceptOrRejectFactorRequest = useMemo(
        () =>
            abilityToManageTransactions &&
            ((myCurrentOrganizationType === 'factoring' &&
                type === 'factor-request') ||
                (myCurrentOrganizationType === 'regular' &&
                    (type === 'receivable' || type === 'factor-request'))),

        [abilityToManageTransactions, myCurrentOrganizationType, type],
    );

    const [
        acceptOrRejectFactorRequestId,
        setAcceptOrRejectFactorRequestId,
    ] = useState<number>();

    useEffect(() => {
        if (actionFilter?.length && canAcceptOrRejectFactorRequest) {
            const checkedTransactions = activeTabList?.filter(tr =>
                actionFilter?.some(id => Number(id) === tr?.id),
            );

            if (
                checkedTransactions?.length === 1 &&
                checkedTransactions[0]?.can_factor_action &&
                checkedTransactions[0]?.factor_request_transaction?.status ===
                    'Pending' &&
                checkedTransactions[0]?.factor_request_transaction?.id &&
                checkedTransactions[0]?.status === 'open'
            ) {
                setAcceptOrRejectFactorRequestId(
                    checkedTransactions[0]?.factor_request_transaction?.id,
                );
            } else {
                setAcceptOrRejectFactorRequestId(undefined);
            }
        }
    }, [
        actionFilter,
        activeTabList,
        canAcceptOrRejectFactorRequest,
        currentOrganizationId,
    ]);

    const onPressMore = useCallback(async () => {
        await fetchUserTransactions(transactionsMeta.current_page + 1, true);
    }, [fetchUserTransactions, transactionsMeta.current_page]);

    const onPressFirst = useCallback(async () => {
        await fetchUserTransactions(1);
    }, [fetchUserTransactions]);

    const onPressLast = useCallback(async () => {
        await fetchUserTransactions(transactionsMeta?.last_page);
    }, [fetchUserTransactions, transactionsMeta?.last_page]);

    const onPressPage = useCallback(
        async (page: number) => {
            await fetchUserTransactions(page);
        },
        [fetchUserTransactions],
    );

    const onArchive = useCallback(async () => {
        if (actionFilter?.length) {
            setLoading(true);
            const response = await archiveTransactions(actionFilter);
            setLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                await fetchUserTransactions();
                await fetchCurrentOrganizationStatistics();
            }
        }
    }, [actionFilter, fetchUserTransactions]);

    const onFactor = useCallback(
        async (id?: number) => {
            if (actionFilter?.length) {
                setLoading(true);
                const response = await factorTransactions(actionFilter, id);
                setLoading(false);
                if (response?.status === 200 || response?.status === 201) {
                    await fetchUserTransactions();
                    await fetchCurrentOrganizationStatistics();
                }
            }
        },
        [actionFilter, fetchUserTransactions],
    );

    const onSendToFactorOrganization = useCallback(
        async (factId?: number) => {
            setLoading(true);
            const response = await sendToFactorTransactions(
                actionFilter,
                factId,
            );
            setLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                setLoading(true);
                await fetchUserTransactions();
                setLoading(false);
                await fetchCurrentOrganizationStatistics();
            }
        },
        [actionFilter, fetchUserTransactions],
    );

    const onCancelFactoringRequest = useCallback(async () => {
        if (cancelFactorRequestId) {
            setLoading(true);
            const response = await cancelFactoringRequest(
                cancelFactorRequestId,
            );
            setLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                setCancelFactorRequestId(undefined);
                await fetchCurrentOrganizationStatistics();
            }
        }
    }, [cancelFactorRequestId]);

    const isActionCheckBoxVisible = useMemo(
        () =>
            (activeTabList?.find(tr => tr?.can_archive) &&
                type !== 'archived' &&
                abilityToManageTransactions) ||
            canFactor ||
            canFactorRequest ||
            canAcceptOrRejectFactorRequest,
        [
            abilityToManageTransactions,
            activeTabList,
            canAcceptOrRejectFactorRequest,
            canFactor,
            canFactorRequest,
            type,
        ],
    );

    const onAcceptFactoringRequest = useCallback(async () => {
        if (acceptOrRejectFactorRequestId) {
            setLoading(true);
            const response = await acceptFactoringRequest(
                acceptOrRejectFactorRequestId,
            );
            setLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                setAcceptOrRejectFactorRequestId(undefined);
                setActionFilter([]);
                await fetchCurrentOrganizationStatistics();
            }
        }
    }, [acceptOrRejectFactorRequestId]);

    const onRejectFactoringRequest = useCallback(async () => {
        if (acceptOrRejectFactorRequestId) {
            setLoading(true);
            const response = await rejectFactoringRequest(
                acceptOrRejectFactorRequestId,
            );
            setLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                setAcceptOrRejectFactorRequestId(undefined);
                setActionFilter([]);
                await fetchCurrentOrganizationStatistics();
            }
        }
    }, [acceptOrRejectFactorRequestId]);

    const canCreateTransactions = useSelector(getAbilityToCreateTransactions);

    return (
        <div className="app-container">
            <AppHeader
                title={headerTitle}
                transactionButton={canCreateTransactions}
                createPtp={createPtp}
                createInvoice={createInvoice}
                isButtonDisabled={isLoading}
            />
            <div className="app-content">
                <div className="app-body">
                    {abilityToManageTransactions && <TotalAmounts />}
                    <FiltersHeader
                        isLoading={isLoading && !!count}
                        archived={type === 'archived'}
                        isFactoringRequestStatusAvailable={
                            canFactorRequest || canAcceptOrRejectFactorRequest
                        }
                        isActionButtonDisabled={isLoading}
                        query={delayQuery}
                        status={status}
                        paymentStatus={paymentStatus}
                        selectedCount={actionFilter?.length}
                        canArchive={canArchive}
                        canFactor={isFactorButtonVisible}
                        canFactorRequest={isFactorRequestButtonVisible}
                        cancelSendToFactor={!!cancelFactorRequestId}
                        acceptOrRejectFactorRequest={
                            !!acceptOrRejectFactorRequestId
                        }
                        factorOrganizations={factorOrganizations}
                        currentConnectedFactoringOrganizations={
                            currentConnectedFactoringOrganizations
                        }
                        onQueryChange={setDelayQuery}
                        onStatusChange={setStatus}
                        onPaymentStatusChange={setPaymentStatus}
                        onFactoringRequestStatusChange={
                            setFactoringRequestStatus
                        }
                        onArchive={onArchive}
                        onFactor={onFactor}
                        onSendToFactorOrganization={onSendToFactorOrganization}
                        onCancelFactoringRequest={onCancelFactoringRequest}
                        onAcceptFactoringRequest={onAcceptFactoringRequest}
                        onRejectFactoringRequest={onRejectFactoringRequest}
                    />
                    {!!count && (
                        <div className="table" style={{ position: 'relative' }}>
                            <table>
                                <tbody>
                                    <tr className="tr-overview">
                                        {isActionCheckBoxVisible && (
                                            <th style={{ paddingRight: 0 }}>
                                                <div className="form-checkbox">
                                                    <input
                                                        ref={checkBoxRef}
                                                        type="checkbox"
                                                        id="archive-all"
                                                        name="archive-all"
                                                        checked={
                                                            isAllPreparedForAction
                                                        }
                                                        onChange={
                                                            addAllToActionFilter
                                                        }
                                                    />
                                                </div>
                                            </th>
                                        )}
                                        <Th
                                            title="Invoice status"
                                            field="status"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.status}
                                            onPress={() =>
                                                setOrderMethod('status')
                                            }
                                        />
                                        {(canFactor ||
                                            canFactorRequest ||
                                            canAcceptOrRejectFactorRequest) && (
                                            <Th
                                                title="Factoring request status"
                                                field="request_status"
                                                sortField={sortField}
                                                sortOrder={
                                                    sortOrder?.request_status
                                                }
                                                onPress={() =>
                                                    setOrderMethod(
                                                        'request_status',
                                                    )
                                                }
                                            />
                                        )}
                                        <Th
                                            title="Date"
                                            field="created_at"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.created_at}
                                            onPress={() =>
                                                setOrderMethod('created_at')
                                            }
                                        />

                                        <Th
                                            title={
                                                canAcceptOrRejectFactorRequest
                                                    ? 'Receive from'
                                                    : 'Recipient'
                                            }
                                            field="to_organization_id"
                                            sortField={sortField}
                                            sortOrder={
                                                sortOrder?.to_organization_id
                                            }
                                            onPress={() =>
                                                setOrderMethod(
                                                    'to_organization_id',
                                                )
                                            }
                                        />
                                        {canAcceptOrRejectFactorRequest && (
                                            <Th
                                                title="Send to"
                                                field="from_organization_id"
                                                sortField={sortField}
                                                sortOrder={
                                                    sortOrder?.from_organization_id
                                                }
                                                onPress={() =>
                                                    setOrderMethod(
                                                        'from_organization_id',
                                                    )
                                                }
                                            />
                                        )}
                                        <Th
                                            title="Payment status"
                                            field="payment_status"
                                            sortField={sortField}
                                            sortOrder={
                                                sortOrder?.payment_status
                                            }
                                            onPress={() =>
                                                setOrderMethod('payment_status')
                                            }
                                        />
                                        <Th
                                            title="Initial Amount"
                                            field="amount"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.amount}
                                            onPress={() =>
                                                setOrderMethod('amount')
                                            }
                                        />
                                        <Th
                                            title="Due Amount"
                                            field="amount_due"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.amount_due}
                                            onPress={() =>
                                                setOrderMethod('amount_due')
                                            }
                                        />
                                    </tr>

                                    {activeTabList?.length
                                        ? activeTabList.map(transaction => (
                                              <Tr
                                                  key={transaction?.id.toString()}
                                                  abilityToManageTransactions={
                                                      abilityToManageTransactions
                                                  }
                                                  canAcceptOrRejectFactorRequest={
                                                      canAcceptOrRejectFactorRequest
                                                  }
                                                  canFactor={canFactor}
                                                  canFactorRequest={
                                                      canFactorRequest
                                                  }
                                                  checked={actionFilter?.includes(
                                                      transaction?.id?.toString(),
                                                  )}
                                                  factoringAbility={
                                                      factoringAbility
                                                  }
                                                  isActionCheckBoxVisible={
                                                      isActionCheckBoxVisible
                                                  }
                                                  transaction={transaction}
                                                  type={type}
                                                  onActionFilterChange={
                                                      onActionFilterChange
                                                  }
                                                  onClick={onClick}
                                              />
                                          ))
                                        : isLoading
                                        ? Array.from(
                                              Array(Math.min(count, 10)).keys(),
                                          )?.map(item => (
                                              <Tr
                                                  key={item?.toString()}
                                                  abilityToManageTransactions={
                                                      abilityToManageTransactions
                                                  }
                                                  canAcceptOrRejectFactorRequest={
                                                      canAcceptOrRejectFactorRequest
                                                  }
                                                  canFactor={canFactor}
                                                  canFactorRequest={
                                                      canFactorRequest
                                                  }
                                                  checked={false}
                                                  factoringAbility={
                                                      factoringAbility
                                                  }
                                                  isActionCheckBoxVisible={
                                                      isActionCheckBoxVisible
                                                  }
                                                  type={type}
                                                  onActionFilterChange={
                                                      onActionFilterChange
                                                  }
                                                  onClick={onClick}
                                              />
                                          ))
                                        : null}
                                    {transactionsMeta?.current_page === 1 &&
                                    isLoading &&
                                    !!count &&
                                    activeTabList?.length &&
                                    activeTabList?.length !== 0 &&
                                    activeTabList?.length < Math.min(count, 10)
                                        ? Array.from(
                                              Array(
                                                  Math.min(count, 10) -
                                                      activeTabList?.length,
                                              ).keys(),
                                          )?.map(item => (
                                              <Tr
                                                  key={item?.toString()}
                                                  abilityToManageTransactions={
                                                      abilityToManageTransactions
                                                  }
                                                  canAcceptOrRejectFactorRequest={
                                                      canAcceptOrRejectFactorRequest
                                                  }
                                                  canFactor={canFactor}
                                                  canFactorRequest={
                                                      canFactorRequest
                                                  }
                                                  checked={false}
                                                  factoringAbility={
                                                      factoringAbility
                                                  }
                                                  isActionCheckBoxVisible={
                                                      isActionCheckBoxVisible
                                                  }
                                                  type={type}
                                                  onActionFilterChange={
                                                      onActionFilterChange
                                                  }
                                                  onClick={onClick}
                                              />
                                          ))
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <PaginationButtons
                        isLoading={isLoading}
                        prevDisabled={
                            transactionsMeta?.current_page === 1 || isLoading
                        }
                        nextDisabled={
                            transactionsMeta?.current_page ===
                                transactionsMeta?.last_page || isLoading
                        }
                        currentPage={transactionsMeta?.current_page}
                        lastPage={transactionsMeta?.last_page}
                        pagesDisabled={isLoading}
                        total={count}
                        onPressPrev={onPressPrev}
                        onPressNext={onPressNext}
                        onPressMore={onPressMore}
                        onPressFirst={onPressFirst}
                        onPressLast={onPressLast}
                        onPressPage={onPressPage}
                    />
                </div>
            </div>
            <CurrenciesFooter />

            <CreateTransactionModal
                type={transactionType}
                isModalOpen={isModalOpen}
                onRequestClose={closeModal}
            />
        </div>
    );
};
