import { AxiosRequestConfig, AxiosResponse } from 'axios';
import api from './axiosConfig';
import {
    ConnectOrganizationProps,
    CreateOrganizationProps,
    InvitationProps,
} from '../types/types';
import { store } from '../state/store';
import {
    organizationsSet,
    organizationsUpdate,
    organizationUpdate,
} from '../state/organizations';
import {
    factoringOrganizationsSet,
    factoringOrganizationsUpdate,
    factoringOrganizationUpdate,
} from '../state/organizationsFactoring';
import {
    countFactoringClientsSet,
    countFactoringOrganizationsSet,
} from '../state/collectionsCount';
import {
    factoringClientsSet,
    factoringClientsUpdate,
    factoringClientUpdate,
} from '../state/organizationsFactoringClients';
import { myOrganizationUpdate } from '../state/myOrganizations';
import { billingPlansSet } from '../state/billingPlans';
import { groupSet, groupsSet, groupsUpdate } from '../state/groups';

export const createOrganization: Promise<AxiosResponse<any>> | any = async (
    organization: CreateOrganizationProps,
) => {
    const response = await api.post('/organizations/new', organization);
    if (
        (response.status === 201 || response.status === 200) &&
        response?.data?.data
    ) {
        store.dispatch(myOrganizationUpdate(response.data.data));
        await fetchCurrentOrganizationStatistics();
    }
    return response;
};

export const editOrganization: Promise<AxiosResponse<any>> | any = async (
    organization: CreateOrganizationProps,
    id: number,
) => {
    const response = await api.put(`/organizations/new/${id}`, organization);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(myOrganizationUpdate(response.data.data));
        await fetchCurrentOrganizationStatistics();
    }
    return response;
};

export const fetchOrganization = async (id: number) => {
    const response = await api(`/organizations/${id}`);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(organizationUpdate(response.data.data));
    }
    return response;
};

export const fetchOrganizations = async (
    params?: AxiosRequestConfig['params'],
) => {
    const response = await api('/organizations', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            per_page: 15,
            ...params,
        },
    });
    if (response.status === 200 && response?.data?.data) {
        if (!params?.page || params?.page === 1) {
            store.dispatch(organizationsSet(response?.data?.data));
        } else {
            store.dispatch(organizationsUpdate(response?.data?.data));
        }
    }
    return response;
};

export const fetchFactoringOrganizations = async (
    params?: AxiosRequestConfig['params'],
    update?: boolean,
) => {
    const response = await api('/organizations/factoring', {
        params: {
            sortField: 'request_status',
            sortOrder: 'ASC',
            per_page: 7,
            ...params,
        },
    });
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(
            countFactoringOrganizationsSet(response.data?.meta?.total),
        );
        if (!update) {
            store.dispatch(factoringOrganizationsSet(response?.data?.data));
        } else {
            store.dispatch(factoringOrganizationsUpdate(response?.data?.data));
        }
    }
    return response;
};

export const fetchFactoringClients = async (
    params?: AxiosRequestConfig['params'],
    update?: boolean,
) => {
    const response = await api('/factor-request-connections', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            per_page: 7,
            ...params,
        },
    });
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(countFactoringClientsSet(response.data?.meta?.total));

        if (!update) {
            store.dispatch(factoringClientsSet(response?.data?.data));
        } else {
            store.dispatch(factoringClientsUpdate(response?.data?.data));
        }
    }
    return response;
};

export const fetchFactoringOrganization = async (id: number) => {
    const response = await api(`/organizations/${id}`);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(factoringOrganizationUpdate(response.data.data));
    }
    return response;
};

export const fetchFactoringClient = async (id: number) => {
    const response = await api(`/factor-request-connections/${id}`);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(factoringClientUpdate(response.data.data));
    }
    return response;
};

export const connectFactoringOrganization:
    | Promise<AxiosResponse<any>>
    | any = async (id: number, props: ConnectOrganizationProps) => {
    const response = await api.post(
        `/organizations/connect-factoring/${id}`,
        props,
    );
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(factoringOrganizationUpdate(response.data.data));
    }
    return response;
};

export const disconnectFactoringOrganization = async (id: number) => {
    const response = await api.post(
        `/organizations/disconnect-factoring/${id}`,
    );
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(factoringOrganizationUpdate(response.data.data));
    }
    return response;
};

export const activateClientOrganization = async (id: number) => {
    const response = await api.post(
        `/factor-request-connections/activate/${id}`,
    );
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(factoringClientUpdate(response.data.data));
    }
    return response;
};

export const rejectClientOrganization = async (id: number) => {
    const response = await api.post(`/factor-request-connections/reject/${id}`);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(factoringClientUpdate(response.data.data));
    }
    return response;
};

export const inviteOrganization: Promise<AxiosResponse<any>> | any = async (
    props: InvitationProps,
) => await api.post('/organizations/invite', props);

export const fetchCurrentOrganizationStatistics = async () =>
    await api.get('/organizations/statistics');

export const fetchMyOrganizations = async () =>
    await api.get('/organizations/account');

export const fetchConnectedFactoringOrganizations = async () =>
    await api.get('/organizations/factor-client');

export const fetchBillingPlans = async () => {
    const response = await api.get('/plans');
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(billingPlansSet(response.data.data));
    }
    return response;
};

export const buyBillingPlan: Promise<AxiosResponse<any>> | any = async (
    planId: number,
) => await api.post(`/plans/${planId}/buy`);

export const fetchMyGroups = async (
    params?: AxiosRequestConfig['params'],
    update?: boolean,
) => {
    const response = await api.get('/groups', {
        params: {
            // sortField: 'created_at',
            // sortOrder: 'DESC',
            per_page: 7,
            ...params,
        },
    });
    if (response.status === 200 && response?.data?.data) {
        if (!update) {
            store.dispatch(groupsSet(response.data));
        } else {
            store.dispatch(groupsUpdate(response.data));
        }
    }
    return response;
};

// /groups?q=Prime&status=owner

export const createGroup: Promise<AxiosResponse<any>> | any = async ({
    name,
}: {
    name: string;
}) => await api.post('/groups', { name });

export const editGroup: Promise<AxiosResponse<any>> | any = async ({
    id,
    name,
}: {
    id: number;
    name: string;
}) => {
    const response = await api.put(`/groups/${id}`, { name });
    if (
        (response?.status === 200 || response?.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(groupSet(response?.data?.data));
    }
    return response;
};

export const fetchGroup: Promise<AxiosResponse<any>> | any = async (
    id: number,
) => {
    const response = await api(`/groups/${id}`);
    if (
        (response?.status === 200 || response?.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(groupSet(response?.data?.data));
    }
    return response;
};

export const changeGroup: Promise<AxiosResponse<any>> | any = async (
    groupId: number,
) => {
    const response = await api.post(`/groups/${groupId}/change`);
    if (
        (response?.status === 200 || response?.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(groupSet(response?.data?.data));
        await fetchOrganizations();
    }
    return response;
};

export const inviteOrganizationsIntoGroup:
    | Promise<AxiosResponse<any>>
    | any = async (groupId: number, params: { id: number }) => {
    const response = await api.post(`/groups/${groupId}/invite`, params);
    if (
        (response?.status === 200 || response?.status === 201) &&
        response?.data?.data
    ) {
        // store.dispatch(groupSet(response?.data?.data));
        // await fetchOrganizations();
    }
    return response;
};

export const fetchGroupMembers = async (
    groupId: number,
    params?: AxiosRequestConfig['params'],
) =>
    await api(`groups/${groupId}/members`, {
        params: {
            // sortField: 'created_at',
            // sortOrder: 'DESC',
            per_page: 4,
            ...params,
        },
    });

export const groupInviteApprove: Promise<AxiosResponse<any>> | any = async (
    id: number,
) => {
    const response = await api.post(`/groups/${id}/invite/approve`);
    if (
        (response?.status === 200 || response?.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(groupSet(response?.data?.data));
        await fetchOrganizations();
    }
    return response;
};

export const groupInviteReject: Promise<AxiosResponse<any>> | any = async (
    id: number,
) => {
    const response = await api.post(`/groups/${id}/invite/reject`);
    if (
        (response?.status === 200 || response?.status === 201) &&
        response?.data?.data
    ) {
        store.dispatch(groupSet(response?.data?.data));
        await fetchOrganizations();
    }
    return response;
};

// {{apiUrl}}/groups/206/payment-histories

export const fetchGroupHistory: Promise<AxiosResponse<any>> | any = async (
    id: number,
    params?: AxiosRequestConfig['params'],
) => {
    const response = await api.get(`/groups/${id}/payment-histories`, {
        params: {
            per_page: 8,
            ...params,
        },
    });

    return response;
};
