import { AppState } from '../types/types';

// SELECTORS
export const getBackgroundNotification = (state: AppState) =>
    state.backgroundNotification;

// ACTION TYPES
const BACKGROUND_NOTIFICATION_SET = 'BACKGROUND_NOTIFICATION_SET';

type ACTION = {
    type: typeof BACKGROUND_NOTIFICATION_SET;
    payload: any;
};

// ACTIONS
export const backgroundNotificationSet = (payload: any) => ({
    type: BACKGROUND_NOTIFICATION_SET,
    payload,
});

const INITIAL_STATE = null;

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case BACKGROUND_NOTIFICATION_SET:
            return action.payload;
        default:
            return state;
    }
};
