import { createSelector } from 'reselect';
import { AppState, ReceiptsState, Receipt } from '../types/types';

// SELECTORS
export const getReceipts = (state: AppState) => state.receipts;
export const getReceiptById = (id: number) => {
    return createSelector(getReceipts, (receipts: ReceiptsState) => {
        return receipts[id];
    });
};

// ACTION TYPES
const RECEIPTS_SET = 'RECEIPTS_SET ';
const RECEIPTS_UPDATE = 'RECEIPTS_UPDATE';
const RECEIPT_UPDATE = 'RECEIPT_UPDATE';
const RECEIPT_ADD = 'RECEIPT_ADD';
const RECEIPT_DELETE = 'RECEIPT_DELETE';

type ACTION =
    | {
          type: typeof RECEIPTS_SET;
          payload?: Receipt[];
      }
    | {
          type: typeof RECEIPTS_UPDATE;
          payload?: Receipt[];
      }
    | {
          type: typeof RECEIPT_UPDATE;
          payload?: Receipt;
      }
    | {
          type: typeof RECEIPT_ADD;
          payload?: Receipt;
      }
    | {
          type: typeof RECEIPT_DELETE;
          payload?: Receipt['id'];
      };

// ACTIONS
export const receiptsSet = (payload?: { [key: number]: Receipt }) => ({
    type: RECEIPTS_SET,
    payload,
});
export const receiptsUpdate = (payload?: Receipt[]) => ({
    type: RECEIPTS_UPDATE,
    payload,
});

export const receiptUpdate = (payload?: Receipt) => ({
    type: RECEIPT_UPDATE,
    payload,
});
export const receiptAdd = (payload?: Receipt) => ({
    type: RECEIPT_ADD,
    payload,
});

export const receiptDelete = (payload?: Receipt['id']) => ({
    type: RECEIPT_DELETE,
    payload,
});

const INITIAL_STATE: ReceiptsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case RECEIPTS_SET:
            const receipts: ReceiptsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    receipt => (receipts[receipt.id] = receipt),
                );
            }
            return receipts;
        case RECEIPTS_UPDATE:
            const nextReceipts: ReceiptsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    receipt => (nextReceipts[receipt.id] = receipt),
                );
            }
            return { ...state, ...nextReceipts };
        case RECEIPT_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case RECEIPT_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case RECEIPT_DELETE:
            const receiptsState = { ...state };
            if (action.payload) {
                delete receiptsState[action.payload];
            }
            return receiptsState;

        default:
            return state;
    }
};
