import { createSelector } from 'reselect';
import { AppState, UsersState, User } from '../types/types';

// SELECTORS
export const getUsers = (state: AppState) => state.users;
export const getUserById = (id: number) => {
    return createSelector(getUsers, (users: UsersState) => {
        return users[id];
    });
};

// ACTION TYPES
const USERS_SET = 'USERS_SET';
const USERS_UPDATE = 'USERS_UPDATE';
const USER_UPDATE = 'USER_UPDATE';

type ACTION =
    | {
          type: typeof USERS_SET;
          payload: User[];
      }
    | {
          type: typeof USERS_UPDATE;
          payload: User[];
      }
    | {
          type: typeof USER_UPDATE;
          payload: User;
      };

// ACTIONS
export const usersSet = (payload: User[]) => ({
    type: USERS_SET,
    payload,
});
export const usersUpdate = (payload: User[]) => ({
    type: USERS_UPDATE,
    payload,
});
export const userUpdate = (payload: User) => ({
    type: USER_UPDATE,
    payload,
});

const INITIAL_STATE: UsersState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case USERS_SET:
            const users: UsersState = {};
            action.payload.forEach(user => (users[user.id] = user));
            return users;
        case USERS_UPDATE:
            const nextUsers: UsersState = {};
            action.payload.forEach(user => (nextUsers[user.id] = user));
            return { ...state, ...nextUsers };
        case USER_UPDATE:
            return {
                ...state,
                [action.payload.id]: action.payload,
            };

        default:
            return state;
    }
};
