import CryptoJS from 'crypto-js';
import parsePhoneNumberFromString, {
    AsYouType,
    parsePhoneNumber,
} from 'libphonenumber-js';
import { DateTime } from 'luxon';

export const isEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
};

export const getPhoneString = (phone?: string) => {
    // console.log(new AsYouType().input(phone));
    // const numberOfPhone = phone.replace(/[^\d]/g, '');
    // const phoneNumber = parsePhoneNumberFromString(`+${numberOfPhone}`);
    // if (phoneNumber?.isPossible()) {
    //     return phoneNumber.formatInternational();
    // }
    // return `+${numberOfPhone}`;
    return phone
        ? new AsYouType().input(phone.charAt(0) === '+' ? phone : `+${phone}`)
        : '';
};

export const removeSpaces = (str?: string) => str?.replace(/\s+/g, '');

export const getAmountInteger = (amount: number) => {
    return `${Math.trunc(amount)?.toLocaleString('en')}.`;
};

export const getAmountDecimal = (amount: number) => {
    return Math.abs(amount).toFixed(2).split('.')[1];
};

export const number = (text: string) => {
    let value;
    if (text.match(',') !== null) {
        value = text.replace(',', '.');
    } else {
        value = text;
    }
    if (value.substring(0, 1) === '.') {
        if (text.length > 1) {
            value = `0.${value.substring(1, value.length)}`;
        } else {
            return '0.';
        }
    }
    let result;
    if (value.match(/[*.*][0-9]*[*.*]/) !== null) {
        if (value.match(/\.$/)) {
            result = value.replace(/\.$/, '');
        } else {
            result = value.replace(/[.]/, '');
        }
    } else {
        result = value;
    }

    return result.replace(/[^\d.]/g, '');
};

export const comaNumber = (text: string) => {
    let result = text.replace(/[^\d]/g, '');

    if (result.length < 4) {
        for (let step = result.length; step < 4; step += 1) {
            result = 0 + result;
        }
    }

    if (result?.length > 4 && result.charAt(0) === '0') {
        result = result.substring(1);
    }

    result = `${result.slice(0, result.length - 2)}.${result.slice(
        result.length - 2,
    )}`;

    return result;
};

export const getInitials = (fullName?: string) => {
    const names = fullName?.split(' ');
    let initials = names?.length
        ? names[0]?.substring(0, 1)?.toUpperCase()
        : '';

    if (!!names && names?.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getInitial = (fullName?: string) => {
    const names = fullName?.split(' ');
    const initials = names?.length
        ? names[0]?.substring(0, 1)?.toUpperCase()
        : '';

    return initials;
};

export const getRegions = (regions: string) => {
    const splitRegions = regions?.split('|');
    return splitRegions.map(item => item.split('~')?.[0]);
};

export const getRegionsArray = (regions: string) => {
    const splitRegions = regions?.split('|');
    return splitRegions.map(item => item.split('~'));
};

export const hideEmail = (email: string) => {
    if (isEmail(email)) {
        const emailArr = email?.split('@');
        return `(${emailArr[0].substring(0, 3)}******@${emailArr[1]})`;
    }
    return `(${email.substring(0, 3)}******})`;
};

export const checkPassword = (password: string) => {
    if (password.match(/^(?=.*\d)(?=.*[A-Za-z])[\W\S_]{6,}$/)) {
        return true;
    }
    return false;
};

export const checkFullName = (name: string) => {
    if (name.match(/^([\w])+\s+([\w\s])+$/i)) {
        return true;
    }
    return false;
};

export const capitalizeFirstLetter = (string?: string) => {
    return string
        ? string?.charAt(0).toUpperCase() + string?.slice(1)?.toLowerCase()
        : '';
};

export const asc = (a?: number | string, b?: number | string) => {
    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    }
    const A = a?.toString()?.toLowerCase() || '';
    const B = b?.toString()?.toLowerCase() || '';

    return A.localeCompare(B);
};

export const desc = (a?: number | string, b?: number | string) => {
    if (typeof a === 'number' && typeof b === 'number') {
        return b - a;
    }

    const A = a?.toString() || '';
    const B = b?.toString() || '';

    return B.localeCompare(A);
};

export const amountOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};

export const getHeaderHelper = (text: string) => {
    let value = text;
    value = text.slice(1, text.length);
    const separator = '/';
    const words = value.split(separator);
    const capitalizeWords = words.map(word => capitalizeFirstLetter(word));
    return capitalizeWords.join('.');
};

export const getHeaderHelper2 = (text: string) => {
    let value = text;
    value = text.slice(1, text.length);
    const separator = '/';
    const words = value.split(separator);
    const capitalize =
        !!words?.length && words?.length > 1
            ? capitalizeFirstLetter(words[1])
            : !!words?.length && words?.length === 1
            ? capitalizeFirstLetter(words[0])
            : capitalizeFirstLetter(text);
    return capitalize;
};

export const checkBoxFilter = (
    event: React.ChangeEvent<HTMLInputElement>,
    prevState: string[],
) => {
    if (event.target.checked && !prevState.includes(event.target.name)) {
        if (prevState) {
            return [...prevState, event.target.name];
        }
        return [event.target.name];
    }
    if (!event.target.checked && prevState.includes(event.target.name)) {
        if (prevState.includes(event.target.name)) {
            return prevState.filter(item => item !== event.target.name);
        }
    }
    return prevState;
};

export const removeSnake = (s: string) => {
    const withoutSnake = s?.replace(/([-_.][a-z])/gi, $1 => {
        return $1.replace('-', ' ').replace('_', ' ').replace('.', ' ');
    });
    return withoutSnake
        ?.replace(/([A-Z])/g, ' $1')
        ?.replace(/^./, (str: any) => str?.toLowerCase())
        ?.toLowerCase();
};

export const getValueFromLastIndexOfSome = (s: string, some: string) => {
    const index = s.lastIndexOf(some) + some?.length;
    return s.substring(index + 1);
};

export const getCountryCodeFromPhone = (phone?: string) => {
    if (!!phone && phone?.length > 6) {
        if (parsePhoneNumberFromString(phone)) {
            return parsePhoneNumber(phone)?.country;
        }
        return undefined;
    }
    return undefined;
};

export const getSomeDigitLength = (text: string, length: number) => {
    let result = text.replace(/[^\d]/g, '');

    if (result.length < length) {
        for (let step = result.length; step < length; step += 1) {
            result = 0 + result;
        }
    }

    if (result?.length > length && result.charAt(0) === '0') {
        result = result.substring(1);
    }

    if (result?.length > length && result.charAt(0) !== '0') {
        result = result.substring(0, length);
    }

    return result;
};

export const setPercentageLimit = (text: string) => {
    if (text === '0') {
        return 0;
    }
    const length = text?.length;
    if (
        text?.charAt(length - 1) === '.' ||
        text?.charAt(length - 1) === ',' ||
        text?.substring(length - 2) === '.0' ||
        text?.substring(length - 2) === ',0' ||
        text?.substring(length - 3) === '.00' ||
        text?.substring(length - 3) === ',00'
    ) {
        return number(text);
    }
    const float = parseFloat(text?.toString());
    const min = Math.min(float * 100, 100 * 100);
    return min / 100;
};

export const getInteger = (text: string) => {
    const result = text.replace(/[^\d]/g, '');
    return result;
};

export const getFloat = (text: string) => {
    if (text === '0') {
        return 0;
    }
    const length = text?.length;
    if (
        text?.charAt(length - 1) === '.' ||
        text?.charAt(length - 1) === ',' ||
        text?.substring(length - 2) === '.0' ||
        text?.substring(length - 2) === ',0' ||
        text?.substring(length - 3) === '.00' ||
        text?.substring(length - 3) === ',00'
    ) {
        return number(text);
    }
    return parseFloat(text);
};

export const setNumberLimit = (value: string, limit: number) => {
    const parsedQty = Number.parseInt(value, 10);
    if (Number.isNaN(parsedQty)) {
        return 1;
    }
    if (parsedQty > limit) {
        return limit;
    }
    return parsedQty;
};

export const getParameterByName = (name: string, url: string) => {
    const parsedName = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const dataURLtoFile = (base64File: string, fileName: string) => {
    // const arr = dataUrl.split(',');
    // const mime = arr[0]?.match(/:(.*?);/)[1];
    // const bstr = atob(arr[1]);
    // let n = bstr.length;
    // const u8arr = new Uint8Array(n);
    // while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    // }

    // return new File([u8arr], filename, { type: mime });

    const i = base64File.indexOf('base64,');
    const buffer = Buffer.from(base64File.slice(i + 7), 'base64');
    const file = new File([buffer], fileName, { type: 'image/png' });
    return file;
};

export const decrypt = (
    encrypted?: string,
    secret_key?: string,
    secret_iv?: string,
) => {
    let decrypted;
    if (typeof encrypted === 'string' && secret_key && secret_iv) {
        const { Hex, Utf8 } = CryptoJS.enc;
        // const { Base64 } = CryptoJS.enc;
        const { AES, SHA256 } = CryptoJS;

        const key = SHA256(secret_key)?.toString(Hex)?.substring(0, 32);
        const iv = SHA256(secret_iv)?.toString(Hex)?.substring(0, 16);

        // Encryption
        // const output = AES.encrypt('test', Utf8.parse(key), {
        //     iv: Utf8.parse(iv),
        // }).toString();

        // const output2ndB64 = Utf8.parse(output).toString(Base64);

        // Decryption
        try {
            decrypted = AES.decrypt(encrypted, Utf8.parse(key), {
                iv: Utf8.parse(iv),
            }).toString(Utf8);
        } catch (e) {
            console.log("can't decrypt");
        }
        return decrypted;
    }
    return decrypted;
};

export const encrypt = (
    decrypted?: string,
    secret_key?: string,
    secret_iv?: string,
) => {
    let encrypted;
    if (typeof decrypted === 'string' && secret_key && secret_iv) {
        const { Hex, Utf8 } = CryptoJS.enc;
        // const { Base64 } = CryptoJS.enc;
        const { AES, SHA256 } = CryptoJS;

        const key = SHA256(secret_key)?.toString(Hex)?.substring(0, 32);
        const iv = SHA256(secret_iv)?.toString(Hex)?.substring(0, 16);

        try {
            encrypted = AES.encrypt(decrypted, Utf8.parse(key), {
                iv: Utf8.parse(iv),
            }).toString();
        } catch (e) {
            console.log("can't encrypt");
        }
        return encrypted;
    }
    return encrypted;
};

export const canInviteAgainToBeAuthUser = ({
    isAccepted,
    lastInvitedAt,
}: {
    isAccepted: boolean;
    lastInvitedAt?: string | null;
}) => {
    if (isAccepted) {
        return false;
    }
    if (!lastInvitedAt) {
        return true;
    }

    let lastInvited = DateTime.fromISO(lastInvitedAt, {
        zone: 'utc',
    });
    if (lastInvited?.invalidReason) {
        lastInvited = DateTime.fromSQL(lastInvitedAt, {
            zone: 'utc',
        });
    }
    if (lastInvited?.invalidReason) {
        return false;
    }
    const yesterday = DateTime.now().minus({ days: 1 })?.toUTC()?.valueOf();
    const isLastInviteWasMoreThan24HoursAgo =
        yesterday - lastInvited?.valueOf() > 0;

    return isLastInviteWasMoreThan24HoursAgo;
};
