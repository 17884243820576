import { createSelector } from 'reselect';
import { AppState, Organization, OrganizationsState } from '../types/types';

// SELECTORS
export const getFactoringClients = (state: AppState) =>
    state.organizationsFactoringClients;
export const getFactoringClientById = (id: number) => {
    return createSelector(
        getFactoringClients,
        (organizations: OrganizationsState) => {
            return organizations[id];
        },
    );
};

// ACTION TYPES
const FACTORING_CLIENTS_SET = 'FACTORING_CLIENTS_SET';
const FACTORING_CLIENTS_UPDATE = 'FACTORING_CLIENTS_UPDATE';
const FACTORING_CLIENT_UPDATE = 'FACTORING_CLIENT_UPDATE';
const FACTORING_CLIENT_ADD = 'FACTORING_CLIENT_ADD';
const FACTORING_CLIENT_DELETE = 'FACTORING_CLIENT_DELETE';

type ACTION =
    | {
          type: typeof FACTORING_CLIENTS_SET;
          payload?: Organization[];
      }
    | {
          type: typeof FACTORING_CLIENTS_UPDATE;
          payload?: Organization[];
      }
    | {
          type: typeof FACTORING_CLIENT_UPDATE;
          payload?: Organization;
      }
    | {
          type: typeof FACTORING_CLIENT_ADD;
          payload?: Organization;
      }
    | {
          type: typeof FACTORING_CLIENT_DELETE;
          payload?: Organization['id'];
      };

// ACTIONS
export const factoringClientsSet = (payload?: {
    [key: number]: Organization;
}) => ({
    type: FACTORING_CLIENTS_SET,
    payload,
});
export const factoringClientsUpdate = (payload?: Organization[]) => ({
    type: FACTORING_CLIENTS_UPDATE,
    payload,
});

export const factoringClientUpdate = (payload?: Organization) => ({
    type: FACTORING_CLIENT_UPDATE,
    payload,
});
export const factoringClientAdd = (payload?: Organization) => ({
    type: FACTORING_CLIENT_ADD,
    payload,
});

export const factoringClientDelete = (payload?: Organization['id']) => ({
    type: FACTORING_CLIENT_DELETE,
    payload,
});

const INITIAL_STATE: OrganizationsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case FACTORING_CLIENTS_SET:
            const organizations: OrganizationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    organization =>
                        (organizations[organization.id] = organization),
                );
            }
            return organizations;
        case FACTORING_CLIENTS_UPDATE:
            const nextOrganizations: OrganizationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    organization =>
                        (nextOrganizations[organization.id] = organization),
                );
            }
            return { ...state, ...nextOrganizations };
        case FACTORING_CLIENT_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case FACTORING_CLIENT_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case FACTORING_CLIENT_DELETE:
            const organizationsState = { ...state };
            if (action.payload) {
                delete organizationsState[action.payload];
            }
            return organizationsState;

        default:
            return state;
    }
};
