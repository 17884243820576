import { createSelector } from 'reselect';
import { AppState, Organization, OrganizationsState } from '../types/types';

// SELECTORS
export const getFactoringOrganizations = (state: AppState) =>
    state.organizationsFactoring;
export const getFactoringOrganizationById = (id: number) => {
    return createSelector(
        getFactoringOrganizations,
        (organizations: OrganizationsState) => {
            return organizations[id];
        },
    );
};

// ACTION TYPES
const FACTORING_ORGANIZATIONS_SET = 'FACTORING_ORGANIZATIONS_SET';
const FACTORING_ORGANIZATIONS_UPDATE = 'FACTORING_ORGANIZATIONS_UPDATE';
const FACTORING_ORGANIZATION_UPDATE = 'FACTORING_ORGANIZATION_UPDATE';
const FACTORING_ORGANIZATION_ADD = 'FACTORING_ORGANIZATION_ADD';
const FACTORING_ORGANIZATION_DELETE = 'FACTORING_ORGANIZATION_DELETE';

type ACTION =
    | {
          type: typeof FACTORING_ORGANIZATIONS_SET;
          payload?: Organization[];
      }
    | {
          type: typeof FACTORING_ORGANIZATIONS_UPDATE;
          payload?: Organization[];
      }
    | {
          type: typeof FACTORING_ORGANIZATION_UPDATE;
          payload?: Organization;
      }
    | {
          type: typeof FACTORING_ORGANIZATION_ADD;
          payload?: Organization;
      }
    | {
          type: typeof FACTORING_ORGANIZATION_DELETE;
          payload?: Organization['id'];
      };

// ACTIONS
export const factoringOrganizationsSet = (payload?: {
    [key: number]: Organization;
}) => ({
    type: FACTORING_ORGANIZATIONS_SET,
    payload,
});
export const factoringOrganizationsUpdate = (payload?: Organization[]) => ({
    type: FACTORING_ORGANIZATIONS_UPDATE,
    payload,
});

export const factoringOrganizationUpdate = (payload?: Organization) => ({
    type: FACTORING_ORGANIZATION_UPDATE,
    payload,
});
export const factoringOrganizationAdd = (payload?: Organization) => ({
    type: FACTORING_ORGANIZATION_ADD,
    payload,
});

export const factoringOrganizationDelete = (payload?: Organization['id']) => ({
    type: FACTORING_ORGANIZATION_DELETE,
    payload,
});

const INITIAL_STATE: OrganizationsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case FACTORING_ORGANIZATIONS_SET:
            const organizations: OrganizationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    organization =>
                        (organizations[organization.id] = organization),
                );
            }
            return organizations;
        case FACTORING_ORGANIZATIONS_UPDATE:
            const nextOrganizations: OrganizationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    organization =>
                        (nextOrganizations[organization.id] = organization),
                );
            }
            return { ...state, ...nextOrganizations };
        case FACTORING_ORGANIZATION_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case FACTORING_ORGANIZATION_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case FACTORING_ORGANIZATION_DELETE:
            const organizationsState = { ...state };
            if (action.payload) {
                delete organizationsState[action.payload];
            }
            return organizationsState;

        default:
            return state;
    }
};
