import { useEffect, useRef, useState } from 'react';

export const useDelayDebounceQuery = (delay = 350) => {
    const delayRef = useRef(delay);
    const [query, setQuery] = useState<string>('');
    const [delayQuery, setDelayQuery] = useState<string>();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setQuery(delayQuery?.toUpperCase() || '');
        }, delayRef.current);

        return () => clearTimeout(delayDebounceFn);
    }, [delayQuery]);
    return { query, delayQuery, setDelayQuery };
};
