import { AppState, LegalForm } from '../types/types';

// SELECTORS
export const getLegalForms = (state: AppState) => state.legalForms;

// ACTION TYPES
const LEGAL_FORMS_SET = 'LEGAL_FORMS_SET';
const LEGAL_FORMS_UPDATE = 'LEGAL_FORMS_UPDATE';

type ACTION =
    | {
          type: typeof LEGAL_FORMS_SET;
          payload: LegalForm[];
      }
    | {
          type: typeof LEGAL_FORMS_UPDATE;
          payload: LegalForm[];
      };

// ACTIONS
export const legalFormsSet = (payload: LegalForm[]) => ({
    type: LEGAL_FORMS_SET,
    payload,
});
export const legalFormsUpdate = (payload: LegalForm[]) => ({
    type: LEGAL_FORMS_UPDATE,
    payload,
});

const INITIAL_STATE: LegalForm[] = [];

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case LEGAL_FORMS_SET:
            return action.payload;
        case LEGAL_FORMS_UPDATE:
            return [...state, ...action.payload];

        default:
            return state;
    }
};
