import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../../state/token';
import { getMyUserId } from '../../state/user';

export function PrivateRoute({ children, ...rest }: { children: any }) {
    const token = useSelector(getToken);
    const userId = useSelector(getMyUserId);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                // auth.user ? (
                token && userId ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/sign-in',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
