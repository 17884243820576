/* eslint-disable func-names */
import axios from 'axios';
import { signOut } from './SignOutService';
import { fetchUser } from './UsersService';
import {
    fetchCurrentOrganizationStatistics,
    fetchMyOrganizations,
} from './OrganizationsService';
import { config } from '../config';
import { store } from '../state/store';
import { tokenSet } from '../state/token';
import { myUserSet, myUserUpdate } from '../state/user';
import { currenciesSet } from '../state/currencies';
import { countriesSet } from '../state/countries';
import { legalFormsSet } from '../state/legalForms';
import { permissionsSet } from '../state/permissions';
import { externalConnectionsSet } from '../state/externalConnections';
import { myOrganizationsSet } from '../state/myOrganizations';
import { statisticsSet } from '../state/statistics';
import { connectedFactoringOrganizationsSet } from '../state/connectedFactoringOrganizations';

const api = axios.create({
    baseURL: config.apiUrl,
});

api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'token',
)}`;
api.defaults.timeout = 30000;
api.defaults.headers.common.Accept = 'application/json';
api.defaults.headers.common['Accept-Language'] = 'en';
api.defaults.headers.common['Content-Type'] = 'application/json';

api.interceptors.response.use(
    async function (response) {
        if (response?.status < 400) {
            console.log(response?.config?.url, response?.status, response);
        }
        if (
            (response?.config?.url === '/auth/login' ||
                response?.config?.url === '/auth/register' ||
                response?.config?.url === '/auth/set-password') &&
            response?.data?.token
        ) {
            api.defaults.headers.common.Authorization = `Bearer ${response.data?.token}`;
            localStorage.setItem('token', response.data?.token);
            store.dispatch(tokenSet(response.data?.token));
            await fetchCurrentOrganizationStatistics();
            await fetchMyOrganizations();
            return await fetchUser();
        }
        if (response?.data?.data) {
            if (response?.config?.url === '/account') {
                store.dispatch(myUserSet(response.data.data));
            }
            if (
                response?.config?.url === '/organizations/account' ||
                response?.config?.url === '/account/change-organization-new'
            ) {
                store.dispatch(myOrganizationsSet(response.data.data));
                if (
                    response?.config?.url === '/account/change-organization-new'
                ) {
                    await fetchCurrentOrganizationStatistics();
                }
            }

            if (response?.config?.url === '/organizations/statistics') {
                store.dispatch(statisticsSet(response.data.data));
            }
            if (response?.config?.url === '/organizations/factor-client') {
                store.dispatch(
                    connectedFactoringOrganizationsSet(response.data.data),
                );
            }
            if (response?.config?.url === '/account/fcm-token') {
                store.dispatch(myUserUpdate(response.data.data));
            }
            if (response?.config?.url === '/currencies') {
                store.dispatch(currenciesSet(response.data.data));
            }
            if (response?.config?.url === '/countries') {
                store.dispatch(countriesSet(response.data.data));
            }
            if (response?.config?.url === '/legal-forms') {
                store.dispatch(legalFormsSet(response.data.data));
            }
            if (response?.config?.url === '/permissions') {
                store.dispatch(permissionsSet(response.data.data));
            }
            if (response?.config?.url === '/external-sources') {
                store.dispatch(externalConnectionsSet(response.data.data));
            }
        }

        return response;
    },
    function (error) {
        if (error.response) {
            if (
                error.response.status === 401 &&
                error?.response?.config?.url !== '/auth/login' &&
                error?.response?.config?.url !== '/auth/logout'
            ) {
                signOut();
            }
            if (error.response.status === 500) {
                alert(
                    `error ${error.response.status}\n${
                        error?.message ? error?.message : ''
                    }\n${error?.response?.data?.message || ''}`,
                );
            }
            console.log(
                'ERROR response',
                error?.response,
                error?.message,
                error?.response?.data?.message,
                error?.response?.data?.errors,
            );
        } else if (error?.request) {
            console.log('error request', error?.request);
            console.log('error', error);
            alert('Error request\nTimeout server error');
        } else {
            console.log('Error', error?.message);
            alert(
                `Error\n${
                    error?.message
                        ? error?.message
                        : 'Something went wrong, please try again later'
                }`,
            );
        }
        return error;
    },
);

export default api;
