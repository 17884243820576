import { createSelector } from 'reselect';
import { AppState, Chain, ChainsState } from '../types/types';

// SELECTORS
export const getChains = (state: AppState) => state.chains;
export const getChainById = (id: number) => {
    return createSelector(getChains, (chains: ChainsState) => {
        return chains[id];
    });
};

// ACTION TYPES
const CHAINS_SET = 'CHAINS_SET';
const CHAINS_UPDATE = 'CHAINS_UPDATE';
const CHAIN_UPDATE = 'CHAIN_UPDATE';
const CHAIN_ADD = 'CHAIN_ADD';
const CHAIN_DELETE = 'CHAIN_DELETE';

type ACTION =
    | {
          type: typeof CHAINS_SET;
          payload?: Chain[];
      }
    | {
          type: typeof CHAINS_UPDATE;
          payload?: Chain[];
      }
    | {
          type: typeof CHAIN_UPDATE;
          payload?: Chain;
      }
    | {
          type: typeof CHAIN_ADD;
          payload?: Chain;
      }
    | {
          type: typeof CHAIN_DELETE;
          payload?: Chain['id'];
      };

// ACTIONS
export const chainsSet = (payload?: { [key: number]: Chain }) => ({
    type: CHAINS_SET,
    payload,
});
export const chainsUpdate = (payload?: Chain[]) => ({
    type: CHAINS_UPDATE,
    payload,
});

export const chainUpdate = (payload?: Chain) => ({
    type: CHAIN_UPDATE,
    payload,
});
export const chainAdd = (payload?: Chain) => ({
    type: CHAIN_ADD,
    payload,
});

export const chainDelete = (payload?: Chain['id']) => ({
    type: CHAIN_DELETE,
    payload,
});

const INITIAL_STATE: ChainsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case CHAINS_SET:
            const chains: ChainsState = {};
            if (action.payload?.length) {
                action.payload.forEach(chain => (chains[chain.id] = chain));
            }
            return chains;
        case CHAINS_UPDATE:
            const nextChains: ChainsState = {};
            if (action.payload?.length) {
                action.payload.forEach(chain => (nextChains[chain.id] = chain));
            }
            return { ...state, ...nextChains };
        case CHAIN_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case CHAIN_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case CHAIN_DELETE:
            const chainsState = { ...state };
            if (action.payload) {
                delete chainsState[action.payload];
            }
            return chainsState;

        default:
            return state;
    }
};
