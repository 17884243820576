import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import authLogo from '../../assets/img/auth-logo.svg';
import { acceptInvitation } from '../../services/AuthService';
import { signOut } from '../../services/SignOutService';
import { getToken } from '../../state/token';

const transitionStyles = {
    entering: { opacity: 0.5, transition: 'opacity 1s ease-in-out' },
    entered: { opacity: 1, transition: 'opacity 1s ease-in-out' },
    exiting: { opacity: 0.5, transition: 'opacity 1s ease-in-out' },
    exited: { opacity: 0, transition: 'opacity 1s ease-in-out' },
};

export const AcceptInvite = () => {
    const history = useHistory();
    const location = useLocation();

    const urlSearchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search],
    );

    const tempToken = useMemo(
        () => urlSearchParams?.get('token'),

        [urlSearchParams],
    );

    const isExistUser = useMemo(() => {
        const isUserExistString = urlSearchParams?.get('user');

        if (!isUserExistString) {
            return false;
        }
        if (isUserExistString !== 'true') {
            return false;
        }

        return true;
    }, [urlSearchParams]);
    console.log('isExistUser', isExistUser);

    const goHome = useCallback(() => {
        history.push('/');
    }, [history]);

    useEffect(() => {
        if (!tempToken) {
            goHome();
        }
    }, [goHome, tempToken]);

    const currentAuthToken = useSelector(getToken);

    const [currentAuthTokenWasReset, resetCurrentAuthToken] = useState(false);

    useEffect(() => {
        if (currentAuthToken && !currentAuthTokenWasReset && !isExistUser) {
            (async () => {
                resetCurrentAuthToken(true);
                await signOut();
            })();
        }
    }, [currentAuthToken, currentAuthTokenWasReset, isExistUser]);

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [tokenError, setTokenError] = useState(false);

    const [tokenErrors, setTokenErrors] = useState<string[] | undefined>();

    const [successText, setSuccessText] = useState<string>();

    const onSubmit = useCallback(async () => {
        if (tempToken) {
            setButtonDisabled(true);
            const response = await acceptInvitation(tempToken);
            setButtonDisabled(false);
            if (response?.status === 200 || response?.status === 201) {
                if (!isExistUser) {
                    setSuccessText(
                        'Please check your email for further instructions',
                    );
                } else {
                    goHome();
                }
            }
            if (response?.response?.data?.errors) {
                const { token, ...other } = response?.response?.data?.errors;
                if (token) {
                    setTokenError(true);
                    setTokenErrors([
                        ...token,
                        !isExistUser
                            ? 'Please ask for a new invitation or register'
                            : 'Please ask for a new invitation',
                    ]);
                }

                if (Object.keys(other).length)
                    console.log('other errors:', other);
            }
        }
    }, [goHome, isExistUser, tempToken]);

    const transitionRef = useRef(null);

    const register = useCallback(() => {
        history.push('/sign-up');
    }, [history]);

    return (
        <div className="block block-auth">
            <form
                className="auth-content"
                action=""
                style={{ position: 'relative' }}
            >
                <div className="auth-container">
                    <div className="auth-wrapper auth-wrapper-lg">
                        <div className="auth-icon">
                            <img
                                src={authLogo}
                                alt="auth logo"
                                style={{
                                    width: 120,
                                    height: 60,
                                    marginBottom: 20,
                                }}
                            />
                        </div>
                        <div className="auth-title">Accept invite</div>
                        <div className="auth-description">
                            {tokenErrors?.length
                                ? tokenErrors?.map((error: any) => `${error}\n`)
                                : 'You are invited to the platform'}
                        </div>
                    </div>
                    {!tokenError && (
                        <div className="auth-wrapper auth-wrapper-lg">
                            {successText || (
                                <button
                                    type="button"
                                    className="button button-lg button-fill button-dark"
                                    onClick={onSubmit}
                                    disabled={isButtonDisabled}
                                >
                                    Accept invitation
                                </button>
                            )}
                        </div>
                    )}

                    <Transition
                        nodeRef={transitionRef}
                        in={!!tokenError}
                        timeout={2000}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {state => {
                            return (
                                <div
                                    className="auth-wrapper auth-wrapper-lg"
                                    style={{
                                        ...transitionStyles[state],
                                    }}
                                    ref={transitionRef}
                                >
                                    {!isExistUser ? (
                                        <button
                                            type="button"
                                            className={`button button-lg button-fill button-dark button-dark-outline ${
                                                !!isButtonDisabled &&
                                                'button-disabled'
                                            }`}
                                            disabled={isButtonDisabled}
                                            onClick={register}
                                        >
                                            REGISTER A NEW ACCOUNT
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className={`button button-lg button-fill button-dark button-dark-outline ${
                                                !!isButtonDisabled &&
                                                'button-disabled'
                                            }`}
                                            disabled={isButtonDisabled}
                                            onClick={goHome}
                                        >
                                            GO HOME
                                        </button>
                                    )}
                                </div>
                            );
                        }}
                    </Transition>
                </div>
                {!!isButtonDisabled && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Loader
                            type="MutatingDots"
                            color="#f37749"
                            visible
                            height={100}
                            width={100}
                        />
                    </div>
                )}
            </form>

            <div className="auth-aside auth-aside-sign_up" />
        </div>
    );
};
