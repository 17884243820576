import styled from 'styled-components';
import { BASE_FONT } from '../../../theme/fonts';

export const PlanContainer = styled.div`
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 18px 20px;

    background: #ffffff;
    border: 1px solid #1069f7;
    border-radius: 5px;
`;

export const CheckIcon = styled.div`
    width: 16px;
    height: 16px;

    background: #399d0a;
    border-radius: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 6px;
`;

export const CurrentPlanTitle = styled(BASE_FONT)`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #202531;
`;

export const CurrentPlanName = styled(BASE_FONT)`
    margin-top: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
`;

export const Price = styled(BASE_FONT)`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    color: #080808;
`;

export const Period = styled(BASE_FONT)`
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    /* identical to box height, or 100% */
    text-align: right;

    /* Heading 1 */
    color: #080808;
`;
