import { createSelector } from 'reselect';
import { AppState, TransactionsState, Transaction } from '../types/types';

// SELECTORS
export const getTransactions = (state: AppState) => state.transactions;
export const getTransactionById = (id: number) => {
    return createSelector(
        getTransactions,
        (transactions: TransactionsState) => {
            return transactions[id];
        },
    );
};

// ACTION TYPES
const TRANSACTIONS_SET = 'TRANSACTIONS_SET';
const TRANSACTIONS_UPDATE = 'TRANSACTIONS_UPDATE';
const TRANSACTION_UPDATE = 'TRANSACTION_UPDATE';
const TRANSACTION_ADD = 'TRANSACTION_ADD';
const TRANSACTION_DELETE = 'TRANSACTION_DELETE';

type ACTION =
    | {
          type: typeof TRANSACTIONS_SET;
          payload?: Transaction[];
      }
    | {
          type: typeof TRANSACTIONS_UPDATE;
          payload?: Transaction[];
      }
    | {
          type: typeof TRANSACTION_UPDATE;
          payload?: Transaction;
      }
    | {
          type: typeof TRANSACTION_ADD;
          payload?: Transaction;
      }
    | {
          type: typeof TRANSACTION_DELETE;
          payload?: Transaction['id'];
      };

// ACTIONS
export const transactionsSet = (payload?: { [key: number]: Transaction }) => ({
    type: TRANSACTIONS_SET,
    payload,
});
export const transactionsUpdate = (payload?: Transaction[]) => ({
    type: TRANSACTIONS_UPDATE,
    payload,
});

export const transactionUpdate = (payload?: Transaction) => ({
    type: TRANSACTION_UPDATE,
    payload,
});
export const transactionAdd = (payload?: Transaction) => ({
    type: TRANSACTION_ADD,
    payload,
});

export const transactionDelete = (payload?: Transaction['id']) => ({
    type: TRANSACTION_DELETE,
    payload,
});

const INITIAL_STATE: TransactionsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case TRANSACTIONS_SET:
            const transactions: TransactionsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    transaction => (transactions[transaction.id] = transaction),
                );
            }
            return transactions;
        case TRANSACTIONS_UPDATE:
            const nextTransactions: TransactionsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    transaction =>
                        (nextTransactions[transaction.id] = transaction),
                );
            }
            return { ...state, ...nextTransactions };
        case TRANSACTION_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;

        case TRANSACTION_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case TRANSACTION_DELETE:
            const transactionsState = { ...state };
            if (action.payload) {
                delete transactionsState[action.payload];
            }
            return transactionsState;

        default:
            return state;
    }
};
