import { useCallback, useState } from 'react';
import Modal from 'react-modal';
import { Content } from './styles';
import {
    CloseButton,
    CloseButtonText,
    Footer,
    Header,
    HeaderSubtitle,
    HeaderTitle,
} from '../../../screens/OrganizationMembers/styles';
import { FooterButtons, FormError, Spinner } from '..';
import { verifyPassword } from '../../../services/AuthService';
import { EditOrganizationBlock } from '../../../types/types';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
        zIndex: 99999,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type Props = {
    block?: EditOrganizationBlock | string;
    subtitle?: string;
    editMethod: (block: EditOrganizationBlock | string) => void;
    onCloseCheckPasswordForm: () => void;
};

export const CheckPasswordFormModal = ({
    block,
    subtitle,
    editMethod,
    onCloseCheckPasswordForm,
}: Props) => {
    const onClose = useCallback(() => {
        setPassword(undefined);
        setErrors({});
        onCloseCheckPasswordForm();
    }, [onCloseCheckPasswordForm]);

    const [password, setPassword] = useState<string>();

    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const passwordChanged = useCallback(
        (value: string) => {
            setPassword(value);
            if (!value) {
                const errorMessage = 'The password field is required.';
                if (!errors.hasOwnProperty('password'))
                    setErrors(prevState => {
                        return { ...prevState, password: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { password: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors],
    );

    const checkEmptyValues = useCallback(() => {
        if (!errors.hasOwnProperty('password') && !password) {
            const errorMessage = `The password field is required`;
            setErrors(prevState => {
                return {
                    password: [errorMessage],
                    ...prevState,
                };
            });
            return false;
        }

        return true;
    }, [errors, password]);

    const onSubmit = useCallback(async () => {
        if (!Object.keys(errors).length && block) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = await verifyPassword(password);
                setButtonDisabled(false);
                if (response?.status === 200 || response?.status === 201) {
                    onClose();
                    editMethod(block);
                }
                if (response?.response?.data?.errors) {
                    if (response?.response?.data?.errors?.password)
                        setErrors({
                            password:
                                typeof response?.response?.data?.errors
                                    ?.password === 'object' &&
                                !!response?.response?.data?.errors?.password
                                    ?.length
                                    ? response?.response?.data?.errors?.password
                                    : [
                                          response?.response?.data?.errors
                                              ?.password,
                                      ],
                        });
                    if (Object.keys(response?.response?.data?.errors).length)
                        console.log(
                            'errors:',
                            response?.response?.data?.errors,
                        );
                }
            }
        }
    }, [block, checkEmptyValues, editMethod, errors, onClose, password]);

    return (
        <Modal
            isOpen={!!block}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Check password form"
        >
            <Header>
                <div>
                    <HeaderTitle>Enter password</HeaderTitle>
                    <HeaderSubtitle>
                        {subtitle ||
                            'Enter your current troccircle password to edit this settings block.'}
                    </HeaderSubtitle>
                </div>
                <CloseButton onClick={onClose}>
                    <CloseButtonText className="mdi mdi-close start" />
                </CloseButton>
            </Header>
            <Content>
                <div className="form form-control-wrapper">
                    <div className="form-group">
                        <div className="form-label">Password</div>
                        <input
                            className="form-control form-control-lg"
                            id="password"
                            name="password"
                            type="password"
                            placeholder="PASSWORD"
                            value={password || ''}
                            onChange={e => passwordChanged(e.target.value)}
                        />
                        <FormError errors={errors?.password} />
                    </div>
                </div>
            </Content>
            <Footer>
                <FooterButtons
                    disabled={isButtonDisabled || !password}
                    submitText="Ok"
                    onCancel={onClose}
                    onSubmit={onSubmit}
                />
            </Footer>
            {!!isButtonDisabled && <Spinner />}
        </Modal>
    );
};
