import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from '.';

const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['products'],
    blacklist: ['backgroundNotification'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeWithDevTools());

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
