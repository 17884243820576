import styled from 'styled-components';

export const Header = styled.div`
    width: 100%;
    left: 0px;
    top: 0px;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 27px;
    border: 1px solid #eaecf0;

    display: flex;
    align-items: flex-start;
`;

export const HeaderTitle = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #202531;

    margin-bottom: 12px;
`;

export const HeaderSubtitle = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    color: #919191;
`;

export const CloseButton = styled.button`
    border: 0;
    padding: 0;
    background: white;
    &:hover {
        cursor: pointer;
    }
`;

export const CloseButtonText = styled.em`
    font-size: 24px;
`;

export const Content = styled.div<{ alignItems?: 'flex-start' | 'center' }>`
    display: flex;
    flex-direction: column;
    align-items: ${({ alignItems = 'unset' }) => alignItems};
    background: #fbfbfb;
    padding-top: 35px;
    padding-bottom: 35px;
    position: relative;
    padding-left: 28.5px;
    padding-right: 28.5px;
    /* max-height: 600px; */
    /* overflow-y: scroll; */
`;

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Footer = styled.div`
    width: 100%;
    height: 73px;
    left: 0px;
    bottom: 0px;

    background: #ffffff;
    border-top: 1px solid #eaecf0;
    box-shadow: inset 0px -1px 0px #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
