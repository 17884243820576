import { Icon, MessageContainer, MessageText } from './styles';

type Props = {
    type: 'success' | 'fail';
};

export const Message = ({ type }: Props) => {
    return (
        <MessageContainer type={type}>
            <Icon
                className={`mdi mdi-${type === 'success' ? 'check' : 'close'}`}
                type={type}
            />
            <MessageText>
                {type === 'success' ? 'Payment submitted' : 'Payment failed'}
            </MessageText>
        </MessageContainer>
    );
};
