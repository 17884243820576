import { createSelector } from 'reselect';
import { AppState, Statistics } from '../types/types';

// SELECTORS
export const getStatistics = (state: AppState) => state.statistics;

export const getMyCurrentOrganizationId = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.current_organization_id,
);

export const getSumNotifications = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.sum_notifications,
);
export const getSumNotificationsTransaction = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.sum_notifications_transaction,
);
export const getSumNotificationsCircle = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.sum_notifications_circle,
);
export const getSumNotificationsChain = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.sum_notifications_chain,
);
export const getSumNotificationsReport = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.sum_notifications_report,
);
export const getSumNotificationsFactorRequestConnections = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.sum_factor_request_connections,
);
export const getSumNotificationsFactorRequestTransactions = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.sum_factor_request_transactions,
);

export const getTotalNetted = createSelector(
    getStatistics,
    (statistics: Statistics) => statistics?.total_netted_amount,
);

// ACTION TYPES
const STATISTICS_SET = 'STATISTICS_SET';
const STATISTICS_UPDATE = 'STATISTICS_UPDATE';

type ACTION =
    | {
          type: typeof STATISTICS_SET;
          payload?: Statistics;
      }
    | {
          type: typeof STATISTICS_UPDATE;
          payload?: Partial<Statistics>;
      };

// ACTIONS
export const statisticsSet = (payload?: Statistics) => ({
    type: STATISTICS_SET,
    payload,
});
export const statisticsUpdate = (payload?: Partial<Statistics>) => ({
    type: STATISTICS_UPDATE,
    payload,
});

const INITIAL_STATE = {
    sum_notifications: 0,
    sum_notifications_transaction: 0,
    sum_notifications_circle: 0,
    sum_notifications_chain: 0,
    sum_notifications_report: 0,
    sum_factor_request_transactions: 0,
    sum_factor_request_connections: 0,
    total_receivables: 0,
    total_payables: 0,
    circular_netted: 0,
    chain_netted: 0,
    total_netted_amount: 0,
};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case STATISTICS_SET:
            if (action.payload) {
                return action.payload;
            }
            return INITIAL_STATE;
        case STATISTICS_UPDATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
