import { AxiosResponse } from 'axios';
import api from './axiosConfig';
import {
    Credentials,
    RegisterProps,
    ResetPasswordProps,
    SetPasswordProps,
} from '../types/types';

export const login: Promise<AxiosResponse<any>> | any = async (
    credentials: Credentials,
) => await api.post('/auth/login', credentials);

export const register: Promise<AxiosResponse<any>> | any = async (
    registerData: RegisterProps,
) => await api.post('/auth/register', registerData);

export const passwordRecovery: Promise<AxiosResponse<any>> | any = async (
    email: string,
) =>
    await api.post('/auth/password/email', {
        email,
        device_type: 'android',
    });

export const resetPassword: Promise<AxiosResponse<any>> | any = async (
    data: ResetPasswordProps,
) => await api.post('/auth/password/reset', data);

export const setPassword: Promise<AxiosResponse<any>> | any = async (
    props: SetPasswordProps,
) => await api.post('/auth/set-password', props);

export const acceptInvitation: Promise<AxiosResponse<any>> | any = async (
    token: string,
) => await api.post('/auth/accept-invite', { token });

export const verifyPassword: Promise<AxiosResponse<any>> | any = async (
    password: string,
) => await api.post('/account/verify-password', { password });
