import { AxiosRequestConfig, AxiosResponse } from 'axios';
import api from './axiosConfig';
import { store } from '../state/store';
import { usersSet, usersUpdate, userUpdate } from '../state/users';
import { UpdateUserProps } from '../types/types';

export const fetchUser: Promise<AxiosResponse<any>> | any = async () =>
    await api.get('/account');

export const updateMyUser: Promise<AxiosResponse<any>> | any = async (
    props: UpdateUserProps,
) => await api.patch('/account', props);

export const updateFcmToken = async (props: {
    fcm_token: string;
    uuid: string;
}) => await api.post('/account/fcm-token', props);

export const fetchUsers = async (params?: AxiosRequestConfig['params']) => {
    const fetchUsersResponse = await api('/users', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            // page: 1,
            per_page: 20,
            ...params,
        },
    });
    if (
        fetchUsersResponse?.status === 200 &&
        !!fetchUsersResponse?.data?.data
    ) {
        if (!params?.page || params?.page === 1) {
            store.dispatch(usersSet(fetchUsersResponse.data.data));
        } else {
            store.dispatch(usersUpdate(fetchUsersResponse.data?.data));
        }
    }
    return fetchUsersResponse;
};

export const fetchCommonUser = async (id: number) => {
    const response = await api(`/users/${id}`);
    if (response.status === 200 && response?.data?.data) {
        store.dispatch(userUpdate(response.data.data));
        return {
            success: true,
            status: response.status,
            users: response.data.data,
        };
    }
    return response;
};

export const changeOrganization: Promise<AxiosResponse<any>> | any = async (
    id: number,
) =>
    await api.put('/account/change-organization-new', {
        current_organization_id: id,
    });

export const deleteAccount:
    | Promise<AxiosResponse<any>>
    | any = async (credentials: { password: string }) =>
    await api.delete('/account/delete', { params: credentials });
