// import firebase from 'firebase/app';
// import 'firebase/messaging';

import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyCou6XKC7I8KJBuf1vE31yKJ-ChnwBZmlQ',
    authDomain: 'troc-react.firebaseapp.com',
    projectId: 'troc-react',
    storageBucket: 'troc-react.appspot.com',
    messagingSenderId: '835867822863',
    appId: '1:835867822863:web:26ea0b0db0f5f93e518c7a',
    measurementId: 'G-GMP4HZ98J3',
};

// firebase.initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);

// eslint-disable-next-line import/no-mutable-exports
// let messaging: firebase.messaging.Messaging | undefined;

// if (firebase?.messaging?.isSupported()) {
//     messaging = firebase.messaging();
// }

// eslint-disable-next-line import/no-mutable-exports

const messagingPromise = async () => {
    const isMessagingSupported = await isSupported();
    if (isMessagingSupported) {
        return getMessaging(firebaseApp);
    }
    return undefined;
};

export { messagingPromise };
