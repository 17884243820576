import { createSelector } from 'reselect';

import { getMyCurrentOrganizationId } from './statistics';

import { getCountries } from './countries';
import { AppState, Organization, OrganizationsState } from '../types/types';

// SELECTORS
export const getMyOrganizations = (state: AppState) => state.myOrganizations;
export const getMyOrganizationById = (id?: number) => {
    return createSelector(
        getMyOrganizations,
        (organizations: OrganizationsState) => {
            if (!!id && organizations?.hasOwnProperty(id)) {
                return organizations[id];
            }
            return undefined;
        },
    );
};
export const getMyOrganizationNameById = (id?: number) =>
    createSelector(
        getMyOrganizationById(id),
        (organization?: Organization) => organization?.company_name,
    );

export const getMyCurrentOrganization = createSelector(
    getMyOrganizations,
    getMyCurrentOrganizationId,
    (organizations: OrganizationsState, id?: number) => {
        if (!!id && organizations?.hasOwnProperty(id)) {
            return organizations[id];
        }
        return undefined;
    },
);

export const getMyCurrentOrganizationCountryId = createSelector(
    getMyCurrentOrganization,
    (organization?: Organization) => organization?.country_id,
);

export const getMyFactoringOrganizations = createSelector(
    getMyOrganizations,
    getMyCurrentOrganization,
    (organizations: OrganizationsState, currentOrganization?: Organization) => {
        if (currentOrganization?.type_organization === 'factoring') {
            return [];
        }
        return Object.values(organizations)?.filter(
            (organization?: Organization) =>
                organization?.type_organization === 'factoring',
        );
    },
);

export const getMyCurrentOrganizationType = createSelector(
    getMyCurrentOrganization,
    (organization?: Organization) => organization?.type_organization,
);

export const getMyCurrentOrganizationFileUid = createSelector(
    getMyCurrentOrganization,
    (organization?: Organization) => organization?.file?.uid,
);

export const getMyCurrentOrganizationName = createSelector(
    getMyCurrentOrganization,
    (organization: Organization | undefined) => organization?.company_name,
);

export const selectCurrentOrganizationMerchant = createSelector(
    getMyCurrentOrganization,
    (organization: Organization | undefined) => organization?.merchant,
);

export const selectIsCurrentOrganizationHasApprovedMerchant = createSelector(
    selectCurrentOrganizationMerchant,
    merchant => !!merchant?.activated_at,
);

export const getCurrentPermissions = createSelector(
    getMyCurrentOrganization,
    (organization: Organization | undefined) => {
        return organization?.permissions;
    },
);

export const getAbilityToCreateTransactions = createSelector(
    getCurrentPermissions,
    currentPermissions => {
        if (currentPermissions?.length && currentPermissions?.includes(1)) {
            return true;
        }
        return false;
    },
);

export const getAbilityToManageTransactions = createSelector(
    getCurrentPermissions,
    currentPermissions => {
        if (currentPermissions?.length && currentPermissions?.includes(2)) {
            return true;
        }
        return false;
    },
);

export const getAbilityToManageCirclesAndChains = createSelector(
    getCurrentPermissions,
    currentPermissions => {
        if (currentPermissions?.length && currentPermissions?.includes(3)) {
            return true;
        }
        return false;
    },
);

export const getAbilityToPerformPayments = createSelector(
    getCurrentPermissions,
    currentPermissions => {
        if (currentPermissions?.length && currentPermissions?.includes(4)) {
            return true;
        }
        return false;
    },
);

export const getAbilityToManageMembers = createSelector(
    getCurrentPermissions,
    currentPermissions => {
        if (currentPermissions?.length && currentPermissions?.includes(5)) {
            return true;
        }
        return false;
    },
);

export const getAbilityEditCurrentOrganizationSettings = createSelector(
    getCurrentPermissions,
    currentPermissions => {
        if (currentPermissions?.length && currentPermissions?.includes(6)) {
            return true;
        }
        return false;
    },
);

export const getFactoringAbility = createSelector(
    getCurrentPermissions,
    currentPermissions => {
        if (currentPermissions?.length && currentPermissions?.includes(7)) {
            return true;
        }
        return false;
    },
);

export const selectCurrentOrganizationCurrentGroupId = createSelector(
    getMyCurrentOrganization,
    organization => organization?.current_group_id,
);

export const selectIsCurrentOrganizationInStripeEu = createSelector(
    getMyCurrentOrganizationCountryId,
    getCountries,
    (id, countries) => !!id && !!countries[id]?.is_stripe_eu,
);

// ACTION TYPES
const MY_ORGANIZATIONS_SET = 'MY_ORGANIZATIONS_SET';
const MY_ORGANIZATIONS_UPDATE = 'MY_ORGANIZATIONS_UPDATE';
const MY_ORGANIZATION_UPDATE = 'MY_ORGANIZATION_UPDATE';
const MY_ORGANIZATION_PARTIAL_UPDATE = 'MY_ORGANIZATION_PARTIAL_UPDATE';
const MY_ORGANIZATION_ADD = 'MY_ORGANIZATION_ADD';
const MY_ORGANIZATION_DELETE = 'MY_ORGANIZATION_DELETE';

type ACTION =
    | {
          type: typeof MY_ORGANIZATIONS_SET;
          payload?: Organization[];
      }
    | {
          type: typeof MY_ORGANIZATIONS_UPDATE;
          payload?: Organization[];
      }
    | {
          type: typeof MY_ORGANIZATION_UPDATE;
          payload?: Organization;
      }
    | {
          type: typeof MY_ORGANIZATION_PARTIAL_UPDATE;
          payload?: Organization;
      }
    | {
          type: typeof MY_ORGANIZATION_ADD;
          payload?: Organization;
      }
    | {
          type: typeof MY_ORGANIZATION_DELETE;
          payload?: Organization['id'];
      };

// ACTIONS
export const myOrganizationsSet = (payload?: Organization[]) => ({
    type: MY_ORGANIZATIONS_SET,
    payload,
});
export const myOrganizationsUpdate = (payload?: Organization[]) => ({
    type: MY_ORGANIZATIONS_UPDATE,
    payload,
});

export const myOrganizationUpdate = (payload?: Organization) => ({
    type: MY_ORGANIZATION_UPDATE,
    payload,
});
export const myOrganizationPartialUpdate = (
    payload?: Partial<Organization>,
) => ({
    type: MY_ORGANIZATION_PARTIAL_UPDATE,
    payload,
});
export const myOrganizationAdd = (payload?: Organization) => ({
    type: MY_ORGANIZATION_ADD,
    payload,
});

export const myOrganizationDelete = (payload?: Organization['id']) => ({
    type: MY_ORGANIZATION_DELETE,
    payload,
});

const INITIAL_STATE: OrganizationsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case MY_ORGANIZATIONS_SET:
            const organizations: OrganizationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    organization =>
                        (organizations[organization.id] = organization),
                );
            }
            return organizations;
        case MY_ORGANIZATIONS_UPDATE:
            const nextOrganizations: OrganizationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    organization =>
                        (nextOrganizations[organization.id] = organization),
                );
            }
            return { ...state, ...nextOrganizations };
        case MY_ORGANIZATION_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case MY_ORGANIZATION_PARTIAL_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: {
                        ...state[action.payload.id],
                        ...action?.payload,
                    },
                };
            }
            return state;
        case MY_ORGANIZATION_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case MY_ORGANIZATION_DELETE:
            const organizationsState = { ...state };
            if (action.payload) {
                delete organizationsState[action.payload];
            }
            return organizationsState;

        default:
            return state;
    }
};
