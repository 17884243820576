import { useCallback, useEffect, useRef, useState } from 'react';

export const useComponentVisible = (initialIsVisible: boolean) => {
    const [isComponentVisible, setIsComponentVisible] = useState(
        initialIsVisible,
    );

    const ref = useRef<any>(null);

    const buttonRef = useRef<any>(null);

    const handleHideDropdown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape' && isComponentVisible) {
                setIsComponentVisible(false);
            }
        },
        [isComponentVisible],
    );

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                ref.current &&
                !ref.current?.contains(event.target) &&
                !buttonRef.current?.contains(event.target) &&
                isComponentVisible
            ) {
                setIsComponentVisible(false);
            }
        },
        [isComponentVisible],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, buttonRef, isComponentVisible, setIsComponentVisible };
};
