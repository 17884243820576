import { AxiosRequestConfig } from 'axios';
import { getToken } from 'firebase/messaging';
import { messagingPromise } from './firebase';
import { updateFcmToken } from './UsersService';
import api from './axiosConfig';
import {
    notificationsSet,
    notificationsUpdate,
    notificationUpdate,
} from '../state/notifications';
import { store } from '../state/store';
import { fcmTokenSet, getFcmToken } from '../state/token';

export const fetchNotifications = async (
    params?: AxiosRequestConfig['params'],
) => {
    const response = await api('/notifications', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            per_page: 20,
            ...params,
        },
    });
    if (response?.status === 200 && !!response?.data?.data) {
        if (!params?.page || params?.page === 1) {
            store.dispatch(notificationsSet(response.data?.data));
        } else {
            store.dispatch(notificationsUpdate(response.data?.data));
        }
    }
    return response;
};

export const fetchNotification = async (id: number) => {
    const response = await api(`/notifications/${id}`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(notificationUpdate(response.data?.data));
    }
    return response;
};

export const readNotifications = async () =>
    await api.post('/notifications/read');

function checkNotificationPromise() {
    try {
        Notification.requestPermission().then();
    } catch (e) {
        return false;
    }

    return true;
}

export async function askNotificationPermission() {
    // function to actually ask the permissions
    function handlePermission(permission: NotificationPermission) {
        console.log('permission', permission);
        let currentPermission = Notification?.permission;
        if (!('permission' in Notification)) {
            currentPermission = permission;
        }
        // set the button to shown or hidden, depending on what the user answers
        if (currentPermission === 'denied' || currentPermission === 'default') {
            return false;
        }
        return true;
    }

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
        console.log('This browser does not support notifications.');
    } else if (checkNotificationPromise()) {
        Notification.requestPermission().then(async permission => {
            const enabled = handlePermission(permission);
            if (enabled) {
                await getFCMToken();
            }
        });
    } else {
        Notification.requestPermission(async permission => {
            const enabled = handlePermission(permission);
            if (enabled) {
                await getFCMToken();
            }
        });
    }
}

export async function getFCMToken() {
    const messaging = await messagingPromise();

    if (messaging) {
        // {
        //     vapidKey:
        //         'BC4BzAv2RSKHcy-aC5IWOhVCrKw7qOWZ1m8W4ReL3lIOd6b8j3Oe8oCzdn-EoMtOpU24Nox8iPCAs8ixxA8D42I',
        // }
        try {
            // const token = await messaging.getToken();
            const token = await getToken(messaging);
            // console.log('token', token);
            if (token) {
                const localFcmToken = getFcmToken(store.getState());
                if (localFcmToken !== token) {
                    store.dispatch(fcmTokenSet(token));
                }
                const uuid = 'web';
                // const serverFcmTokens = getMyUserFcmTokens(store.getState());

                // if (
                //     !serverFcmTokens[uuid] ||
                //     (!!serverFcmTokens[uuid] && token !== serverFcmTokens[uuid])
                // ) {

                await updateFcmToken({
                    fcm_token: token,
                    uuid,
                });
                // }
            }
        } catch (e) {
            console.log('fcm token error', e);
        }
    }
}
