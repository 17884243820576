import React, { memo } from 'react';
import Loader from 'react-loader-spinner';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row } from '../../Row';

type HeaderMenuProps = {
    location: RouteComponentProps['location'];
    type?: 'headerMenu' | 'breadcrumbs';
    title?: string;
    isLoading?: boolean;
    headerMenuItems?: { title: string; link?: string }[];
    headerSubtitles?: { subtitle?: string; subtitleLink?: string }[];
};

export const HeaderMenu = memo(
    ({
        location,
        type,
        title,
        isLoading,
        headerMenuItems,
        headerSubtitles,
    }: HeaderMenuProps) => {
        return type === 'headerMenu' ? (
            <>
                <div className="header-menu">
                    <div className="header-menu-item" />
                </div>

                <div className="header-menu">
                    {headerMenuItems?.map((item, index) => (
                        <Link
                            key={index.toString()}
                            className={`header-menu-item ${
                                location?.pathname === item.link ? 'active' : ''
                            }`}
                            to={item.link || '/'}
                        >
                            {' '}
                            {item.title}
                        </Link>
                    ))}
                </div>
            </>
        ) : type === 'breadcrumbs' ? (
            <div className="flex">
                <div className="header-breadcrumbs">
                    {headerSubtitles?.map((item, index) => (
                        <Link
                            key={index.toString()}
                            className="header-breadcrumb"
                            to={item.subtitleLink || '/'}
                        >
                            {item.subtitle}
                            <div className="header-breadcrumb-arrow mdi mdi-menu-right" />
                        </Link>
                    ))}
                    {!!title && (
                        <Link className="header-breadcrumb active" to="/">
                            {title}
                            <div className="header-breadcrumb-arrow mdi mdi-menu-right" />
                        </Link>
                    )}
                    {isLoading && (
                        <div style={{ marginTop: 3 }}>
                            <Loader
                                type="Watch"
                                color="#f37749"
                                visible
                                height={30}
                                width={30}
                            />
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <Row>
                <div className="header-title">
                    {headerSubtitles?.map((item, index) => (
                        <span key={index.toString()} className="text-muted">
                            {item.subtitle} •{' '}
                        </span>
                    ))}
                    {title}
                </div>
                {isLoading && (
                    <Loader
                        type="Watch"
                        color="#f37749"
                        visible
                        height={30}
                        width={30}
                    />
                )}
            </Row>
        );
    },
);
