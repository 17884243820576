import React, { forwardRef, useCallback } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

type Props = {
    type?: 'blank';
    path?: string;
    label?: string;
    src?: string;
    isNotificationsVisible?: boolean;
    sumNotifications?: number;
    style?: React.CSSProperties | undefined;
    openNotifications?: () => void;
};

export const MenuLink = forwardRef(
    (
        {
            type,
            path,
            label,
            src,
            isNotificationsVisible,
            sumNotifications,
            style,
            openNotifications,
        }: Props,
        ref,
    ) => {
        const location = useLocation();
        const history = useHistory();

        const onClick = useCallback(() => {
            if (path && type === 'blank') {
                const win = window.open(path, '_blank');
                win?.focus();
            } else if (path && !type) {
                history.push(path);
            } else if (openNotifications) {
                openNotifications();
            }
        }, [history, openNotifications, path, type]);

        return (
            <div
                className={`menu-item ${
                    path === location?.pathname ||
                    (!path && isNotificationsVisible)
                        ? 'active'
                        : ''
                }`}
                style={style}
                ref={ref as React.LegacyRef<HTMLDivElement> | undefined}
            >
                <div
                    role="button"
                    tabIndex={0}
                    className="menu-item-link"
                    onClick={onClick}
                    onKeyDown={onClick}
                >
                    <div className="menu-item-icon">
                        <img src={src} alt="menu icon profile" />
                        {!!sumNotifications && (
                            <div className="menu-item-notes">
                                {sumNotifications}
                            </div>
                        )}
                    </div>

                    {!!label && <div className="menu-item-label">{label}</div>}
                </div>
            </div>
        );
    },
);
