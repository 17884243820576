import * as countries from 'countries-list';
import parsePhoneNumberFromString, {
    parsePhoneNumber,
} from 'libphonenumber-js';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { CountryRegionData } from 'react-country-region-selector';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FactoringEditableBlock } from './FactoringEditableBlock';
import { TypeCard } from './TypeCard';
import { MerchantCard } from './MerchantCard';
import {
    AppHeader,
    CheckPasswordFormModal,
    CountryPhoneSelector,
    CurrenciesFooter,
    Emoji,
    FormError,
    SelectComponent,
} from '../../components';
import '../../scss/components/Calendar.css';
import '../../scss/components/DatePicker.css';
import { fetchCountries } from '../../services/CountriesService';
import {
    amountOptions,
    getPhoneString,
    getRegions,
    isEmail,
    removeSnake,
} from '../../services/helpers';
import { fetchLegalForms } from '../../services/LegalFormsService';
import { deleteMedia, uploadMedia } from '../../services/MediaService';
import {
    createOrganization,
    editOrganization,
    fetchCurrentOrganizationStatistics,
    fetchMyGroups,
    fetchMyOrganizations,
} from '../../services/OrganizationsService';
import { getCountryCode } from '../../state/countries';
import { getLegalForms } from '../../state/legalForms';
import {
    CreateOrganizationProps,
    EditOrganizationBlock,
} from '../../types/types';
import {
    deleteDraftContractFile,
    uploadFile,
} from '../../services/FilesService';
import {
    getAbilityEditCurrentOrganizationSettings,
    getMyOrganizationById,
    selectIsCurrentOrganizationInStripeEu,
} from '../../state/myOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { createMerchant } from '../../services/MerchantsService';
import { selectGroupsMeta } from '../../state/groups';

const countriesOptions = CountryRegionData.map(item => ({
    value: item[1],
    label: item[0],
    regions: item[2],
}));

const regularRequired = [
    'address',
    'city',
    'company_name',
    'country_code',
    'business_type',
    'postal_code',
    'registration_number',
    'state',
];
const factoringRequired = ['commission', 'min_amount_to_factor', 'description'];

export const Organization = () => {
    const { id } = useParams<{ id: string }>();
    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const history = useHistory();

    const [isFooterButtonsVisible, setFooterButtonsVisible] = useState(
        id === undefined || !id,
    );

    useEffect(() => {
        (async () => {
            if (id) {
                await fetchMyGroups();
                const response = await fetchMyOrganizations();
                if (response?.status !== 200) {
                    history.push('/');
                }
            }
        })();
    }, [history, id]);

    useEffect(() => {
        (async () => {
            await fetchLegalForms();
            await fetchCountries();
            await fetchCurrentOrganizationStatistics();
        })();
    }, []);

    const [avatar, setAvatar] = useState<
        | {
              data: string | ArrayBuffer | null;
              path: string;
              file: string;
          }
        | undefined
    >();

    const canChangeOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );

    useEffect(() => {
        if (!canChangeOrganizationSettings && !!currentOrganizationId && !!id) {
            history.push(`/`);
        }
    }, [canChangeOrganizationSettings, currentOrganizationId, history, id]);

    useEffect(() => {
        if (
            !!id &&
            !!currentOrganizationId &&
            Number(id) !== currentOrganizationId
        ) {
            history.push(`/organization/${currentOrganizationId}`);
        }
        setFooterButtonsVisible(id === undefined || !id);
        setAvatar(undefined);
    }, [canChangeOrganizationSettings, currentOrganizationId, history, id]);

    const storedOrganization = useSelector(getMyOrganizationById(Number(id)));

    const storedOrganizationCountryCode = useSelector(
        getCountryCode(storedOrganization?.country_id),
    );

    const isCurrentOrganizationInStripeEu = useSelector(
        selectIsCurrentOrganizationInStripeEu,
    );

    const legalForms = useSelector(getLegalForms);

    const storedOrganizationForEdit = useMemo<Partial<CreateOrganizationProps>>(
        () => ({
            address: storedOrganization?.address,
            bank_account: storedOrganization?.bank_account,
            bank_code: storedOrganization?.bank_code,
            bank_name: storedOrganization?.bank_name,
            city: storedOrganization?.city,
            company_name: storedOrganization?.company_name,
            country_code: storedOrganizationCountryCode,
            email: storedOrganization?.email,
            business_type: storedOrganization?.business_type,
            phone: storedOrganization?.phone
                ? getPhoneString(storedOrganization?.phone)
                : '',
            postal_code: storedOrganization?.postal_code,
            registration_number: storedOrganization?.registration_number,
            state: storedOrganization?.state,
            type: storedOrganization?.type
                ? (storedOrganization?.type?.toUpperCase() as any)
                : undefined,
            vat_number: storedOrganization?.vat_number,
            website: storedOrganization?.website,
            type_organization: storedOrganization?.type_organization,
            commission: storedOrganization?.commission,
            min_amount_to_factor: storedOrganization?.min_amount_to_factor,
            description: storedOrganization?.description,
            media_uid: storedOrganization?.logo?.uid,
            file_uid: storedOrganization?.file?.uid,
        }),
        [
            storedOrganization?.address,
            storedOrganization?.bank_account,
            storedOrganization?.bank_code,
            storedOrganization?.bank_name,
            storedOrganization?.city,
            storedOrganization?.company_name,
            storedOrganization?.email,
            storedOrganization?.business_type,
            storedOrganization?.phone,
            storedOrganization?.postal_code,
            storedOrganization?.registration_number,
            storedOrganization?.state,
            storedOrganization?.type,
            storedOrganization?.vat_number,
            storedOrganization?.website,
            storedOrganization?.type_organization,
            storedOrganization?.commission,
            storedOrganization?.min_amount_to_factor,
            storedOrganization?.description,
            storedOrganization?.logo?.uid,
            storedOrganization?.file?.uid,
            storedOrganizationCountryCode,
        ],
    );

    const [organization, setOrganization] = useState<
        Partial<CreateOrganizationProps>
    >(
        storedOrganizationForEdit?.company_name
            ? storedOrganizationForEdit
            : { type: 'PUBLIC', type_organization: 'regular' },
    );

    const businessOptions = legalForms.map(item => ({
        value: item.slug,
        label: item.name,
    }));

    const [countryOption, setCountryOption] = useState<{
        value: string;
        label: string;
        regions: string;
    } | null>(
        countriesOptions.filter(
            item => item.value === storedOrganizationCountryCode,
        )[0] || null,
    );

    const regionsOptions = countryOption?.regions
        ? getRegions(countryOption?.regions)?.map(
              (item: string, index: number) => ({
                  value: index.toString(),
                  label: item,
              }),
          )
        : undefined;

    const onCancel = useCallback(() => {
        setErrors({});
        history.push('/');
    }, [history]);

    const location = useLocation();

    const locationPathname = useRef(location?.pathname);

    const deleteAttachment = useCallback(() => {
        setAttachment(undefined);
        setFooterButtonsVisible(true);
    }, []);

    useEffect(() => {
        if (location?.pathname !== locationPathname?.current) {
            locationPathname.current = location?.pathname;
            setOrganization(
                storedOrganizationForEdit?.company_name
                    ? storedOrganizationForEdit
                    : { type: 'PUBLIC', type_organization: 'regular' },
            );
            setDetailsEditable(false);
            setContactInformationEditable(false);
            setAddressEditable(false);
            setFactoringDataEditable(false);
            setCompanyTypeEditable(false);
            setFinancialInformationEditable(false);
            setErrors({});
            const co =
                countriesOptions?.filter(
                    item => item.value === storedOrganizationCountryCode,
                )[0] || null;
            setCountryOption(co);
            const ro = co?.regions
                ? getRegions(co?.regions)?.map(
                      (item: string, index: number) => ({
                          value: index.toString(),
                          label: item,
                      }),
                  )
                : undefined;
            setRegionOption(
                ro?.filter(
                    item => item?.label === storedOrganization?.state,
                )[0] || null,
            );
            setLegalOption(
                businessOptions?.filter(
                    item => item?.value === storedOrganization?.business_type,
                )[0] || null,
            );
            setPhoneCountryCode(
                !!storedOrganization?.phone &&
                    storedOrganization?.phone?.length > 6
                    ? parsePhoneNumber(storedOrganization?.phone)?.country
                    : '',
            );
            deleteAttachment();
        }
    }, [
        businessOptions,
        countryOption?.regions,
        deleteAttachment,
        location?.pathname,
        storedOrganizationCountryCode,
        storedOrganization?.business_type,
        storedOrganization?.phone,
        storedOrganization?.state,
        storedOrganizationForEdit,
    ]);

    const requiredFields = useMemo(() => {
        return organization?.type_organization === 'factoring'
            ? [...regularRequired, ...factoringRequired]
            : regularRequired;
    }, [organization?.type_organization]);

    const [phoneCountryCode, setPhoneCountryCode] = useState(
        !!organization?.phone &&
            organization?.phone?.length > 6 &&
            !!parsePhoneNumberFromString(organization?.phone)
            ? parsePhoneNumber(organization?.phone)?.country
            : '',
    );

    const emoji = phoneCountryCode
        ? countries?.getEmojiFlag(phoneCountryCode)
        : '';

    const [errors, setErrors] = useState<{ [key: string]: any }>({});
    const [otherErrors, setOtherErrors] = useState<string[]>([]);

    const organizationChanged = useCallback(
        (key: keyof CreateOrganizationProps, value: string | number) => {
            setOtherErrors([]);
            setFooterButtonsVisible(true);
            if (
                key === 'phone' &&
                !!value &&
                typeof value === 'string' &&
                value?.length > 6 &&
                !!parsePhoneNumberFromString(value)
            ) {
                if (parsePhoneNumber(value)?.country) {
                    setPhoneCountryCode(parsePhoneNumber(value)?.country);
                }
            }
            setOrganization(prevState => ({
                ...prevState,
                [key]:
                    key === 'phone' ? getPhoneString(value as string) : value,
            }));
            if (
                (!value && value !== 0 && requiredFields?.includes(key)) ||
                (key === 'email' && !isEmail(value as string) && !!value) ||
                (key === 'phone' &&
                    !!value &&
                    !parsePhoneNumberFromString(value as string)?.isPossible())
            ) {
                const errorMessage =
                    key === 'email'
                        ? 'The email must be a valid email address'
                        : key === 'phone'
                        ? 'The phone must be a valid and possible phone number'
                        : key === 'type'
                        ? 'The company type is required'
                        : key === 'country_code'
                        ? 'The country field is required'
                        : key === 'bank_code'
                        ? 'The swift code field is required'
                        : `The ${removeSnake(key)} field is required`;
                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors, requiredFields],
    );

    const updatePhonePrefix = useCallback(
        (countryCode: string, phonePrefix: string) => {
            setPhoneCountryCode(countryCode);
            organizationChanged('phone', phonePrefix);
        },
        [organizationChanged],
    );

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                (!organization?.hasOwnProperty(item) ||
                    organization[item] === undefined ||
                    organization[item] === null ||
                    organization[item] === '')
            ) {
                allFieldsFilled = false;
                if (
                    item === 'address' ||
                    item === 'city' ||
                    item === 'country_code' ||
                    item === 'postal_code' ||
                    item === 'state'
                ) {
                    setAddressEditable(true);
                } else if (
                    item === 'bank_account' ||
                    item === 'bank_code' ||
                    item === 'bank_name'
                ) {
                    setFinancialInformationEditable(true);
                } else if (
                    item === 'company_name' ||
                    item === 'business_type' ||
                    item === 'registration_number' ||
                    item === 'vat_number'
                ) {
                    setDetailsEditable(true);
                } else if (
                    item === 'email' ||
                    item === 'phone' ||
                    item === 'website'
                ) {
                    setContactInformationEditable(true);
                } else if (item === 'type') {
                    setCompanyTypeEditable(true);
                } else if (
                    item === 'commission' ||
                    item === 'min_amount_to_factor' ||
                    item === 'description'
                ) {
                    setFactoringDataEditable(true);
                }
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        return allFieldsFilled;
    }, [errors, organization, requiredFields]);

    const [attachment, setAttachment] = useState<
        | {
              name?: string;
              file?: string;
          }
        | undefined
    >({
        name: id ? storedOrganization?.file?.original_name : undefined,
    });

    const saveOrganization = useCallback(async () => {
        if (!Object.keys(errors).length) {
            if (organization?.type_organization === 'regular') {
                factoringRequired?.forEach(field => {
                    if (Object.keys(organization)?.includes(field)) {
                        delete organization[field];
                    }
                });
            }
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                let mediaUid = organization?.media_uid;
                if (avatar?.file) {
                    const addNewAvatar = await uploadMedia(
                        avatar?.file as any,
                        'logo',
                    );
                    if (
                        addNewAvatar.status === 200 ||
                        addNewAvatar.status === 201
                    ) {
                        if (mediaUid) {
                            await deleteMedia(mediaUid);
                        }
                        mediaUid = addNewAvatar?.data?.data?.uid;
                    }
                }

                let fileUid = organization?.file_uid;
                if (attachment?.file) {
                    const uploadNewFile = await uploadFile(
                        attachment?.file as any,
                        'organization_file',
                    );
                    if (
                        uploadNewFile?.status === 200 ||
                        uploadNewFile?.status === 201
                    ) {
                        if (!!id && !!fileUid) {
                            await deleteDraftContractFile(fileUid);
                        }
                        fileUid = uploadNewFile?.data?.data?.uid;
                    } else {
                        alert(
                            'Accepted extensions: .pdf, .doc, .docx. Maximum file size - 4MB',
                        );
                    }
                }

                const organizationProps = { ...organization };
                if (mediaUid) {
                    organizationProps.media_uid = mediaUid;
                }
                if (fileUid) {
                    organizationProps.file_uid = fileUid;
                }

                const response = id
                    ? await editOrganization(
                          organizationProps as CreateOrganizationProps,
                          id,
                      )
                    : await createOrganization(
                          organizationProps as CreateOrganizationProps,
                      );

                setButtonDisabled(false);
                if (
                    (response?.status === 201 || response?.status === 200) &&
                    !id
                ) {
                    history.push(`/organization/${response?.data?.data?.id}`);
                }
                if (response?.status === 200 && !!id) {
                    history.push(`/`);
                }
                if (response?.response?.data?.errors) {
                    const {
                        address,
                        bank_account,
                        bank_code,
                        bank_name,
                        city,
                        company_name,
                        country_code,
                        email,
                        business_type,
                        phone,
                        postal_code,
                        registration_number,
                        state,
                        type,
                        vat_number,
                        website,
                        type_organization,
                        commission,
                        min_amount_to_factor,
                        description,
                        ...other
                    } = response?.response?.data?.errors;
                    if (address) {
                        setErrors(prevState => ({
                            ...prevState,
                            address,
                        }));
                        setAddressEditable(true);
                    }
                    if (bank_account) {
                        setErrors(prevState => ({
                            ...prevState,
                            bank_account,
                        }));
                        setFinancialInformationEditable(true);
                    }
                    if (bank_code) {
                        setErrors(prevState => ({ ...prevState, bank_code }));
                        setFinancialInformationEditable(true);
                    }
                    if (bank_name) {
                        setErrors(prevState => ({ ...prevState, bank_name }));
                        setFinancialInformationEditable(true);
                    }
                    if (city) {
                        setErrors(prevState => ({ ...prevState, city }));
                        setAddressEditable(true);
                    }
                    if (company_name) {
                        setErrors(prevState => ({
                            ...prevState,
                            company_name,
                        }));
                        setDetailsEditable(true);
                    }
                    if (country_code) {
                        setErrors(prevState => ({
                            ...prevState,
                            country_code,
                        }));
                        setAddressEditable(true);
                    }
                    if (email) {
                        setErrors(prevState => ({ ...prevState, email }));
                        setContactInformationEditable(true);
                    }
                    if (business_type) {
                        setErrors(prevState => ({
                            ...prevState,
                            business_type,
                        }));
                        setDetailsEditable(true);
                    }
                    if (phone) {
                        setErrors(prevState => ({ ...prevState, phone }));
                        setContactInformationEditable(true);
                    }
                    if (postal_code) {
                        setErrors(prevState => ({ ...prevState, postal_code }));
                        setAddressEditable(true);
                    }
                    if (registration_number) {
                        setErrors(prevState => ({
                            ...prevState,
                            registration_number,
                        }));
                        setDetailsEditable(true);
                    }
                    if (state) {
                        setErrors(prevState => ({ ...prevState, state }));
                        setAddressEditable(true);
                    }
                    if (type) {
                        setErrors(prevState => ({ ...prevState, type }));
                        setCompanyTypeEditable(true);
                    }
                    if (vat_number) {
                        setErrors(prevState => ({ ...prevState, vat_number }));
                        setDetailsEditable(true);
                    }
                    if (website) {
                        setErrors(prevState => ({ ...prevState, website }));
                        setContactInformationEditable(true);
                    }
                    if (type_organization) {
                        setErrors(prevState => ({
                            ...prevState,
                            type_organization,
                        }));
                    }
                    if (commission) {
                        setErrors(prevState => ({
                            ...prevState,
                            commission,
                        }));
                        setFactoringDataEditable(true);
                    }
                    if (min_amount_to_factor) {
                        setErrors(prevState => ({
                            ...prevState,
                            min_amount_to_factor,
                        }));
                        setFactoringDataEditable(true);
                    }
                    if (description) {
                        setErrors(prevState => ({
                            ...prevState,
                            description,
                        }));
                        setFactoringDataEditable(true);
                    }

                    if (Object.keys(other).length) {
                        console.log('other errors:', other);
                        setOtherErrors(other);
                    }
                }

                if (
                    response?.status === 500 &&
                    response?.response?.data?.message
                ) {
                    setOtherErrors([response?.response?.data?.message]);
                }
            }
        }
    }, [
        errors,
        organization,
        checkEmptyValues,
        avatar?.file,
        id,
        attachment?.file,
        history,
    ]);

    const readFile = useCallback(e => {
        if (FileReader && e.target?.files && e.target?.files.length) {
            const avatars = [...e.target?.files];
            const fr = new FileReader();
            fr.readAsDataURL(avatars[0]);
            let path = avatars[0]?.name;
            path = path.replace(/\s/g, '/');
            fr.onload = () => {
                setAvatar({
                    data: fr.result as string,
                    path: `/files/${path}`,
                    file: avatars[0],
                });
                setFooterButtonsVisible(true);
            };
        }
        e.target.value = null;
    }, []);

    const [legalOption, setLegalOption] = useState<{
        value: string;
        label: string;
    } | null>(
        businessOptions?.filter(
            item => item?.value === storedOrganization?.business_type,
        )[0] || null,
    );

    const onLegalFormChange = useCallback(
        item => {
            setLegalOption(item);
            organizationChanged('business_type', item?.value);
        },
        [organizationChanged],
    );

    const onCountryChange = useCallback(
        item => {
            setCountryOption(item);
            organizationChanged('country_code', item?.value);
            setRegionOption(null);
            organizationChanged('state', '');
        },
        [organizationChanged],
    );

    const [regionOption, setRegionOption] = useState<{
        value: string;
        label: string;
    } | null>(
        regionsOptions?.filter(
            item => item?.label === storedOrganization?.state,
        )[0] || null,
    );

    const onRegionChange = useCallback(
        item => {
            setRegionOption(item);
            organizationChanged('state', item?.label);
        },
        [organizationChanged],
    );

    // const setOrganizationActive = useCallback(async () => {
    //     await changeOrganization(Number(id));
    // }, [id]);

    const [isDetailsEditable, setDetailsEditable] = useState(false);

    const [
        isContactInformationEditable,
        setContactInformationEditable,
    ] = useState(false);
    const [isAddressEditable, setAddressEditable] = useState(false);
    const [isFactoringDataEditable, setFactoringDataEditable] = useState(false);
    const [isCompanyTypeEditable, setCompanyTypeEditable] = useState(false);
    const [
        isFinancialInformationEditable,
        setFinancialInformationEditable,
    ] = useState(false);

    const [passwordFormBlock, setPasswordFormBlock] = useState<
        EditOrganizationBlock | undefined
    >();

    const [
        isPasswordForSomeBlockEntered,
        setPasswordForSomeBlockEntered,
    ] = useState(false);

    const editMethod = useCallback(
        async (block: EditOrganizationBlock | string) => {
            setPasswordForSomeBlockEntered(true);
            if (block === 'details') {
                setDetailsEditable(true);
            } else if (block === 'contacts') {
                setContactInformationEditable(true);
            } else if (block === 'address') {
                setAddressEditable(true);
            } else if (block === 'financial') {
                setFinancialInformationEditable(true);
            } else if (block === 'factoring') {
                setFactoringDataEditable(true);
            } else if (block === 'type') {
                setCompanyTypeEditable(true);
            } else if (block === 'create-merchant') {
                setButtonDisabled(true);
                const response = await createMerchant();
                await fetchMyOrganizations();
                setButtonDisabled(false);
                if (response?.data?.url) {
                    const win = window.open(response?.data?.url, '_blank');
                    win?.focus();
                }
            }
        },
        [],
    );

    const editMethodModal = useCallback(
        (block: EditOrganizationBlock) => {
            if (!isPasswordForSomeBlockEntered) {
                setPasswordFormBlock(block);
            } else {
                editMethod(block);
            }
        },
        [editMethod, isPasswordForSomeBlockEntered],
    );

    const openMembers = useCallback(() => {
        history.push(`/organization${id}/members`);
    }, [history, id]);

    const createNewMerchant = useCallback(() => {
        editMethodModal('create-merchant');
    }, [editMethodModal]);

    const [isCountrySelectorOpen, setCountrySelectorOpen] = useState(false);

    const onOpenCountrySelector = useCallback(() => {
        setCountrySelectorOpen(true);
    }, []);

    const onCloseCountrySelector = useCallback(() => {
        setCountrySelectorOpen(false);
    }, []);

    // const onDateChange = useCallback(
    //     (date: Date | Date[] | null | undefined) => {
    //         if (!!date && !(date instanceof Array)) {
    //             organizationChanged(
    //                 'date_in_service',
    //                 DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
    //             );
    //         } else {
    //             organizationChanged('date_in_service', '');
    //         }
    //     },
    //     [organizationChanged],
    // );

    const openFile = useCallback(e => {
        if (FileReader && e.target?.files && e.target?.files.length) {
            const files = [...e.target?.files];
            const fr = new FileReader();
            fr.readAsDataURL(files[0]);
            console.log(files[0]);
            fr.onload = () => {
                setAttachment({ name: files[0]?.name, file: files[0] });
                setFooterButtonsVisible(true);
            };
        }
        e.target.value = null;
    }, []);

    const groupsMeta = useSelector(selectGroupsMeta);

    return (
        <div className="app-container">
            <AppHeader
                type="breadcrumbs"
                isLoading={!!isButtonDisabled}
                tabs={[
                    { name: 'Company details', link: `/organization/${id}` },
                    {
                        name: 'Billing details',
                        link: `/organization${id}/billing-details`,
                    },
                    {
                        name: `My groups (${groupsMeta?.total})`,
                        link: `/organization${id}/my-groups`,
                    },
                ]}
                title={
                    !storedOrganization?.id
                        ? 'New organization'
                        : storedOrganization?.company_name
                }
                headerSubtitles={[
                    {
                        subtitle: 'My organizations',
                        subtitleLink: `/organization/${id}`,
                    },
                ]}
                // headerMenuLink="/new-organization"
                membersButton={!!id && canChangeOrganizationSettings}
                isButtonDisabled={isButtonDisabled}
                openMembers={openMembers}
            />

            <div className="app-content">
                <div className="app-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="block block-media-picker">
                                <label
                                    htmlFor="file-input"
                                    className="picker-media"
                                >
                                    <input
                                        id="file-input"
                                        name="myFile"
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        disabled={
                                            !canChangeOrganizationSettings &&
                                            !!id
                                        }
                                        onChange={readFile}
                                    />
                                    {storedOrganization?.logo?.sizes
                                        ?.thumbnail || avatar?.data ? (
                                        <img
                                            src={
                                                (avatar?.data as string) ||
                                                storedOrganization?.logo?.sizes
                                                    ?.thumbnail
                                            }
                                            alt="company logo"
                                            className="picker-media-image"
                                        />
                                    ) : (
                                        // <div className="mdi mdi-upload" />
                                        <div className="picker-media-abbr">
                                            ?
                                        </div>
                                    )}
                                </label>
                                <div className="picker-details">
                                    <div className="picker-details-title">
                                        {id
                                            ? 'Edit organization'
                                            : 'Adding a new organization'}
                                    </div>
                                    <div className="picker-details-subtitle">
                                        {id
                                            ? 'Edit the organization on Troc Circle'
                                            : 'Registering a new organization on Troc Circle'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!!id &&
                            (storedOrganizationCountryCode?.toUpperCase() ===
                                'US' ||
                                !!storedOrganization?.merchant?.created_at ||
                                !!isCurrentOrganizationInStripeEu) && (
                                <MerchantCard
                                    merchant={storedOrganization?.merchant}
                                    companyName={organization?.company_name}
                                    createNewMerchant={createNewMerchant}
                                />
                            )}
                        {!id && (
                            <>
                                <div className="col-lg-6 col-sm-12 form">
                                    <div className="form-title">
                                        Please choose the type of your
                                        organization
                                    </div>
                                    <div className="row">
                                        <TypeCard
                                            type="regular"
                                            active={
                                                organization?.type_organization ===
                                                'regular'
                                            }
                                            onPress={() => {
                                                deleteAttachment();
                                                organizationChanged(
                                                    'type_organization',
                                                    'regular',
                                                );
                                            }}
                                        />
                                        <TypeCard
                                            type="factoring"
                                            active={
                                                organization?.type_organization ===
                                                'factoring'
                                            }
                                            onPress={() =>
                                                organizationChanged(
                                                    'type_organization',
                                                    'factoring',
                                                )
                                            }
                                        />
                                    </div>
                                    <FormError
                                        errors={errors?.type_organization}
                                    />
                                    <div className="form form-actions" />
                                </div>
                            </>
                        )}

                        <div className="col-lg-12 form">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 form">
                                    {!isDetailsEditable && !!id ? (
                                        <>
                                            <div className="block block-title">
                                                Organization details
                                                {canChangeOrganizationSettings && (
                                                    <div className="block-title-actions">
                                                        <button
                                                            type="button"
                                                            className="button button-default button-sm"
                                                            onClick={() =>
                                                                editMethodModal(
                                                                    'details',
                                                                )
                                                            }
                                                        >
                                                            <em className="mdi mdi-pencil start primary" />
                                                            Edit
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="block block-overview-list">
                                                <div className="row">
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Legal name (business
                                                            name)
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.company_name ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Alternative names
                                                        </div>
                                                        <div className="overview-item-value">
                                                            Weget Enterprise, Fine Art
                                                            Room
                                                        </div>
                                                    </div> */}
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Business type
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {legalOption?.label ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Registration number
                                                            / EIN
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.registration_number ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            VAT number
                                                            (Optional)
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.vat_number ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6 col-xs-12 overview-item">
                                                <div className="overview-item-label">
                                                    Incorporation date
                                                </div>
                                                <div className="overview-item-value">
                                                    14 January 2014
                                                </div>
                                            </div> */}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="form-title">
                                                Organization details
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        Legal name (business
                                                        name)
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Legal name (business name)"
                                                            value={
                                                                organization?.company_name ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'company_name',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container form-control-info-container-dim">
                                                                Legal name
                                                                <br />
                                                                (business name)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={
                                                            errors?.company_name
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        Business type
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <SelectComponent
                                                            withInfo
                                                            isClearable
                                                            placeholder="Business type"
                                                            value={legalOption}
                                                            onChange={
                                                                onLegalFormChange
                                                            }
                                                            options={
                                                                businessOptions as any
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container form-control-info-container-dim">
                                                                Business
                                                                <br />
                                                                type
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={
                                                            errors?.business_type
                                                        }
                                                    />
                                                </div>
                                                {/* <div className="form-group col-lg-6 col-xs-12">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Alternative names (optional)"
                                    />
                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        Registration number /
                                                        EIN
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Registration number / EIN"
                                                            value={
                                                                organization?.registration_number ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'registration_number',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container form-control-info-container-dim">
                                                                Registration
                                                                <br />
                                                                number
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={
                                                            errors?.registration_number
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        VAT number (Optional)
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="VAT number (Optional)"
                                                            value={
                                                                organization?.vat_number ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'vat_number',
                                                                    e.target.value.toUpperCase(),
                                                                )
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container">
                                                                VAT number
                                                                (Optional)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={
                                                            errors?.vat_number
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                <div className="form-group col-lg-6 col-xs-12">
                                    <DatePicker
                                        format={
                                            organization?.date
                                                ? 'yyyy-MM-dd'
                                                : 'dd'
                                        }
                                        dayPlaceholder="Incorporation date"
                                        className="form-control form-control-small-padding"
                                        onChange={onDateChange}
                                        value={
                                            organization?.date
                                                ? DateTime.fromSQL(
                                                      organization?.date,
                                                  ).toJSDate()
                                                : undefined
                                        }
                                        calendarIcon={
                                            <img
                                                src={calendarIcon}
                                                alt="calendar icon"
                                            />
                                        }
                                    />
                                    <FormError errors={errors?.date} />
                                </div>
                            </div> */}
                                        </>
                                    )}

                                    {!isContactInformationEditable && !!id ? (
                                        <>
                                            <div className="block block-title">
                                                Contact information
                                                {canChangeOrganizationSettings && (
                                                    <div className="block-title-actions">
                                                        <button
                                                            type="button"
                                                            className="button button-default button-sm"
                                                            onClick={() =>
                                                                editMethodModal(
                                                                    'contacts',
                                                                )
                                                            }
                                                        >
                                                            <em className="mdi mdi-pencil start primary" />
                                                            Edit
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="block block-overview-list">
                                                <div className="row">
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Phone number
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.phone ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Website
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.website ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            E-mail
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.email ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="form-title">
                                                Contact information
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        Phone
                                                    </div>

                                                    <div
                                                        className="form-control-wrapper"
                                                        style={{
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Phone"
                                                            value={
                                                                organization?.phone ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'phone',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            style={{
                                                                paddingLeft: 55,
                                                            }}
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container form-control-info-container-dim">
                                                                Phone
                                                            </div>
                                                        </div>
                                                        <Emoji
                                                            onPress={
                                                                onOpenCountrySelector
                                                            }
                                                            symbol={emoji}
                                                        />
                                                    </div>

                                                    <FormError
                                                        errors={errors?.phone}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        E-mail
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type="email"
                                                            placeholder="E-mail"
                                                            value={
                                                                organization?.email ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'email',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container form-control-info-container-dim">
                                                                E-mail
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={errors?.email}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        Website
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Website"
                                                            value={
                                                                organization?.website ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'website',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container form-control-info-container-dim">
                                                                Website
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={errors?.website}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {!isAddressEditable && !!id ? (
                                        <>
                                            <div className="block block-title">
                                                Address
                                                {canChangeOrganizationSettings && (
                                                    <div className="block-title-actions">
                                                        <button
                                                            type="button"
                                                            className="button button-default button-sm"
                                                            onClick={() =>
                                                                editMethodModal(
                                                                    'address',
                                                                )
                                                            }
                                                        >
                                                            <em className="mdi mdi-pencil start primary" />
                                                            Edit
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="block block-overview-list">
                                                <div className="row">
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Address
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.address ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Postal code
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.postal_code ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            City
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.city}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            Country
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {storedOrganizationCountryCode ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 overview-item">
                                                        <div className="overview-item-label">
                                                            State(region)
                                                        </div>
                                                        <div className="overview-item-value">
                                                            {organization?.state ||
                                                                '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="form-title">
                                                Address
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-12 col-xs-12">
                                                    <div className="form-label">
                                                        Address (street, house
                                                        number and addition)
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Address (street, house number and addition)"
                                                            value={
                                                                organization?.address ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'address',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container">
                                                                Address (street,
                                                                house
                                                                <br />
                                                                number and
                                                                addition)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={errors?.address}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        City
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="City"
                                                            value={
                                                                organization?.city ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'city',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container">
                                                                City
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={errors?.city}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        Zip or Postal code
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Zip or Postal code"
                                                            value={
                                                                organization?.postal_code ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                organizationChanged(
                                                                    'postal_code',
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container">
                                                                Zip or Postal
                                                                code
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={
                                                            errors?.postal_code
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        Country
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <SelectComponent
                                                            withInfo
                                                            isClearable
                                                            placeholder="Country"
                                                            value={
                                                                countryOption
                                                            }
                                                            onChange={
                                                                onCountryChange
                                                            }
                                                            options={
                                                                countriesOptions as any
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container">
                                                                Country
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={
                                                            errors?.country_code
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6 col-xs-12">
                                                    <div className="form-label">
                                                        State (region)
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <SelectComponent
                                                            withInfo
                                                            isClearable
                                                            placeholder="State (region)"
                                                            value={regionOption}
                                                            onChange={
                                                                onRegionChange
                                                            }
                                                            options={
                                                                regionsOptions as any
                                                            }
                                                        />
                                                        <div className="form-control-info">
                                                            <em className="mdi mdi-information form-control-info-icon" />
                                                            <div className="form-control-info-container">
                                                                State (region)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError
                                                        errors={errors?.state}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <FactoringEditableBlock
                                        visible={
                                            !id &&
                                            organization?.type_organization ===
                                                'factoring'
                                        }
                                        commission={organization?.commission}
                                        min_amount_to_factor={
                                            organization?.min_amount_to_factor
                                        }
                                        description={organization?.description}
                                        errors={errors}
                                        attachmentName={attachment?.name}
                                        organizationChanged={
                                            organizationChanged
                                        }
                                        openFile={openFile}
                                        deleteAttachment={deleteAttachment}
                                    />
                                </div>

                                {!!id && (
                                    <div className="col-lg-6 col-sm-12">
                                        {!isFinancialInformationEditable ? (
                                            <>
                                                <div className="block block-title">
                                                    Financial information
                                                    {canChangeOrganizationSettings && (
                                                        <div className="block-title-actions">
                                                            <button
                                                                type="button"
                                                                className="button button-default button-sm"
                                                                onClick={() =>
                                                                    editMethodModal(
                                                                        'financial',
                                                                    )
                                                                }
                                                            >
                                                                <em className="mdi mdi-pencil start primary" />
                                                                Edit
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="block block-overview-list">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-xs-12 overview-item">
                                                            <div className="overview-item-label">
                                                                Bank name
                                                            </div>
                                                            <div className="overview-item-value">
                                                                {organization?.bank_name ||
                                                                    '-'}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xs-12 overview-item">
                                                            <div className="overview-item-label">
                                                                Bank account
                                                            </div>
                                                            <div className="overview-item-value">
                                                                {organization?.bank_account ||
                                                                    '-'}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-xs-12 overview-item">
                                                            <div className="overview-item-label">
                                                                Swift code
                                                            </div>
                                                            <div className="overview-item-value">
                                                                {organization?.bank_code ||
                                                                    '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="form-title">
                                                    Financial information
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-xs-12">
                                                        <div className="form-label">
                                                            Bank name
                                                        </div>
                                                        <div className="form-control-wrapper">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Bank name"
                                                                value={
                                                                    organization?.bank_name ||
                                                                    ''
                                                                }
                                                                onChange={e =>
                                                                    organizationChanged(
                                                                        'bank_name',
                                                                        e.target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <FormError
                                                            errors={
                                                                errors?.bank_name
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group col-lg-6 col-xs-12">
                                                        <div className="form-label">
                                                            Bank account
                                                        </div>
                                                        <div className="form-control-wrapper">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Bank account"
                                                                value={
                                                                    organization?.bank_account ||
                                                                    ''
                                                                }
                                                                onChange={e =>
                                                                    organizationChanged(
                                                                        'bank_account',
                                                                        e.target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <FormError
                                                            errors={
                                                                errors?.bank_account
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-xs-12">
                                                        <div className="form-label">
                                                            Swift code
                                                        </div>
                                                        <div className="form-control-wrapper">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Swift code"
                                                                value={
                                                                    organization?.bank_code ||
                                                                    ''
                                                                }
                                                                onChange={e =>
                                                                    organizationChanged(
                                                                        'bank_code',
                                                                        e.target.value.toUpperCase(),
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <FormError
                                                            errors={
                                                                errors?.bank_code
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {!isCompanyTypeEditable ? (
                                            <>
                                                <div className="block block-title">
                                                    Company type
                                                    {canChangeOrganizationSettings && (
                                                        <div className="block-title-actions">
                                                            <button
                                                                type="button"
                                                                className="button button-default button-sm"
                                                                onClick={() =>
                                                                    editMethodModal(
                                                                        'type',
                                                                    )
                                                                }
                                                            >
                                                                <em className="mdi mdi-pencil start primary" />
                                                                Edit
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="block block-overview-list">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-xs-12 overview-item">
                                                            <div className="overview-item-label">
                                                                Company type
                                                            </div>
                                                            <div className="overview-item-value">
                                                                {organization?.type?.toLocaleLowerCase() ===
                                                                'public'
                                                                    ? 'Public'
                                                                    : 'Private'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="form-title">
                                                    Company type
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-lg-12 col-xs-12">
                                                        <div className="flex">
                                                            <div className="form-radio radio-narrow radio-inline">
                                                                <input
                                                                    name="PUBLIC"
                                                                    type="radio"
                                                                    id="PUBLIC"
                                                                    value="PUBLIC"
                                                                    checked={
                                                                        organization?.type ===
                                                                        'PUBLIC'
                                                                    }
                                                                    onChange={e =>
                                                                        organizationChanged(
                                                                            'type',
                                                                            e
                                                                                .currentTarget
                                                                                .value,
                                                                        )
                                                                    }
                                                                />
                                                                <label
                                                                    className="radio-label"
                                                                    htmlFor="PUBLIC"
                                                                >
                                                                    <div className="radio-circle" />
                                                                    Public
                                                                </label>
                                                            </div>
                                                            <div className="form-radio radio-narrow radio-inline">
                                                                <input
                                                                    name="PRIVATE"
                                                                    type="radio"
                                                                    id="PRIVATE"
                                                                    value="PRIVATE"
                                                                    checked={
                                                                        organization?.type?.toUpperCase() ===
                                                                        'PRIVATE'
                                                                    }
                                                                    onChange={e =>
                                                                        organizationChanged(
                                                                            'type',
                                                                            e
                                                                                .currentTarget
                                                                                .value,
                                                                        )
                                                                    }
                                                                />
                                                                <label
                                                                    className="radio-label"
                                                                    htmlFor="PRIVATE"
                                                                >
                                                                    <div className="radio-circle" />
                                                                    Private
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <FormError
                                                            errors={
                                                                errors?.type
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {organization?.type_organization ===
                                            'factoring' && (
                                            <>
                                                {!isFactoringDataEditable ? (
                                                    <>
                                                        <div className="block block-title">
                                                            Factoring
                                                            organization
                                                            specific settings
                                                            {canChangeOrganizationSettings && (
                                                                <div className="block-title-actions">
                                                                    <button
                                                                        type="button"
                                                                        className="button button-default button-sm"
                                                                        onClick={() =>
                                                                            editMethodModal(
                                                                                'factoring',
                                                                            )
                                                                        }
                                                                    >
                                                                        <em className="mdi mdi-pencil start primary" />
                                                                        Edit
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="block block-overview-list">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-xs-12 overview-item">
                                                                    <div className="overview-item-label">
                                                                        Minimum
                                                                        amount
                                                                        to
                                                                        factor
                                                                    </div>
                                                                    <div className="overview-item-value">
                                                                        {organization?.min_amount_to_factor
                                                                            ? organization?.min_amount_to_factor?.toLocaleString(
                                                                                  'en',
                                                                                  amountOptions,
                                                                              )
                                                                            : '-'}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-xs-12 overview-item">
                                                                    <div className="overview-item-label">
                                                                        Average
                                                                        interest
                                                                        rate
                                                                    </div>
                                                                    <div className="overview-item-value">
                                                                        {organization?.commission ||
                                                                            '-'}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-xs-12 overview-item">
                                                                    <div className="overview-item-label">
                                                                        Description
                                                                    </div>
                                                                    <div
                                                                        className="overview-item-value"
                                                                        style={{
                                                                            lineHeight:
                                                                                '2.5ex',
                                                                            height:
                                                                                '2.5ex',
                                                                            overflow:
                                                                                'hidden',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                        }}
                                                                    >
                                                                        {organization?.description ||
                                                                            '-'}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-xs-12 overview-item">
                                                                    <div className="overview-item-label">
                                                                        Draft
                                                                        contract
                                                                    </div>
                                                                    <div
                                                                        className="overview-item-value"
                                                                        style={{
                                                                            lineHeight:
                                                                                '2.5ex',
                                                                            height:
                                                                                '2.5ex',
                                                                            overflow:
                                                                                'hidden',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                        }}
                                                                    >
                                                                        {attachment?.name ||
                                                                            '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <FactoringEditableBlock
                                                        visible
                                                        commission={
                                                            organization?.commission
                                                        }
                                                        min_amount_to_factor={
                                                            organization?.min_amount_to_factor
                                                        }
                                                        description={
                                                            organization?.description
                                                        }
                                                        errors={errors}
                                                        attachmentName={
                                                            attachment?.name
                                                        }
                                                        organizationChanged={
                                                            organizationChanged
                                                        }
                                                        openFile={openFile}
                                                        deleteAttachment={
                                                            deleteAttachment
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 form">
                                {!!otherErrors?.length && (
                                    <>
                                        <div className="form-title">
                                            {`Other error${
                                                otherErrors?.length > 1
                                                    ? 's'
                                                    : ''
                                            }`}
                                        </div>
                                        <FormError errors={otherErrors} />
                                    </>
                                )}
                                {isFooterButtonsVisible && (
                                    <div className="form-actions">
                                        <div className="button-group">
                                            <button
                                                type="button"
                                                className={`button button-primary button-sm ${
                                                    isButtonDisabled
                                                        ? 'button-disabled'
                                                        : ''
                                                }`}
                                                disabled={isButtonDisabled}
                                                onClick={saveOrganization}
                                            >
                                                {' '}
                                                <em className="mdi mdi-check start" />
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className="button button-default button-primary button-sm"
                                                disabled={isButtonDisabled}
                                                onClick={onCancel}
                                            >
                                                {' '}
                                                <em className="mdi mdi-close start" />
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-grow" />
                        </div>
                    </div>
                </div>
            </div>
            <CurrenciesFooter />
            <CountryPhoneSelector
                isCountrySelectorOpen={isCountrySelectorOpen}
                onCloseCountrySelector={onCloseCountrySelector}
                countryCode={phoneCountryCode}
                updatePhonePrefix={updatePhonePrefix}
            />
            <CheckPasswordFormModal
                block={passwordFormBlock}
                editMethod={editMethod}
                onCloseCheckPasswordForm={() => setPasswordFormBlock(undefined)}
            />
        </div>
    );
};
