import { createSelector } from 'reselect';
import { AppState, CollectionsCount } from '../types/types';

// SELECTORS
export const getCollectionsCount = (state: AppState) => state.collectionsCount;

export const getCountAccounts = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countAccounts;
    },
);
export const getCountAccountTransactions = (id?: string) =>
    createSelector(
        getCollectionsCount,
        (collectionsCount: CollectionsCount) => {
            if (
                id &&
                collectionsCount?.countAccountsTransactions?.hasOwnProperty(id)
            ) {
                return collectionsCount?.countAccountsTransactions[id];
            }
            return 0;
        },
    );
export const getCountCards = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countCards;
    },
);

export const getCountSharedCards = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countSharedCards;
    },
);
export const getCountCardTransactions = (id?: string) =>
    createSelector(
        getCollectionsCount,
        (collectionsCount: CollectionsCount) => {
            if (
                id &&
                collectionsCount?.countCardsTransactions?.hasOwnProperty(id)
            ) {
                return collectionsCount?.countCardsTransactions[id];
            }
            return 0;
        },
    );
export const getCountTransactionsAll = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countTransactionAll;
    },
);

export const getCountTransactionsPayables = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countTransactionPayables;
    },
);

export const getCountTransactionsReceivables = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countTransactionsReceivables;
    },
);

export const getCountTransactionsFactoringRequests = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countTransactionsFactoringRequests;
    },
);

export const getCountTransactionsArchived = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countTransactionsArchived;
    },
);

export const getCountCirclesAll = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countCirclesAll;
    },
);

export const getCountChainsAll = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countChainsAll;
    },
);

export const getCountReportsAll = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countReportsAll;
    },
);

export const getCountReceiptsAll = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countReceiptsAll;
    },
);

export const getCountFactoringOrganizations = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countFactoringOrganizations;
    },
);

export const getCountFactoringClients = createSelector(
    getCollectionsCount,
    (collectionsCount: CollectionsCount) => {
        return collectionsCount.countFactoringClients;
    },
);

// ACTION TYPES
const COUNTS_RESET = 'COUNTS_RESET';
const COUNT_ACCOUNTS_SET = 'COUNT_ACCOUNTS_SET';
const COUNT_ACCOUNT_TRANSACTIONS_SET = 'COUNT_ACCOUNT_TRANSACTIONS_SET';
const COUNT_CARDS_SET = 'COUNT_CARDS_SET';
const COUNT_SHARED_CARDS_SET = 'COUNT_SHARED_CARDS_SET';
const COUNT_CARD_TRANSACTIONS_SET = 'COUNT_CARD_TRANSACTIONS_SET';
const COUNT_TRANSACTIONS_ALL_SET = 'COUNT_TRANSACTIONS_ALL_SET';
const COUNT_TRANSACTIONS_PAYABLES_SET = 'COUNT_TRANSACTIONS_PAYABLES_SET';
const COUNT_TRANSACTIONS_RECEIVABLES_SET = 'COUNT_TRANSACTIONS_RECEIVABLES_SET';
const COUNT_TRANSACTIONS_FACTORING_REQUESTS_SET =
    'COUNT_TRANSACTIONS_FACTORING_REQUESTS_SET';
const COUNT_TRANSACTIONS_ARCHIVED_SET = 'COUNT_TRANSACTIONS_ARCHIVED_SET';
const COUNT_CIRCLES_ALL_SET = 'COUNT_CIRCLES_ALL_SET';
const COUNT_CHAINS_ALL_SET = 'COUNT_CHAINS_ALL_SET';
const COUNT_REPORTS_ALL_SET = 'COUNT_REPORTS_ALL_SET';
const COUNT_RECEIPTS_ALL_SET = 'COUNT_RECEIPTS_ALL_SET';
const COUNT_FACTORING_ORGANIZATIONS_SET = 'COUNT_FACTORING_ORGANIZATIONS_SET';
const COUNT_FACTORING_CLIENTS_SET = 'COUNT_FACTORING_CLIENTS_SET';

type ACTION =
    | {
          type: typeof COUNTS_RESET;
      }
    | {
          type: typeof COUNT_ACCOUNTS_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_ACCOUNT_TRANSACTIONS_SET;
          payload: { id: string; count: number };
      }
    | {
          type: typeof COUNT_CARDS_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_SHARED_CARDS_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_CARD_TRANSACTIONS_SET;
          payload: { id: string; count: number };
      }
    | {
          type: typeof COUNT_TRANSACTIONS_ALL_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_TRANSACTIONS_PAYABLES_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_TRANSACTIONS_RECEIVABLES_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_TRANSACTIONS_FACTORING_REQUESTS_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_TRANSACTIONS_ARCHIVED_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_CIRCLES_ALL_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_CHAINS_ALL_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_REPORTS_ALL_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_RECEIPTS_ALL_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_FACTORING_ORGANIZATIONS_SET;
          payload: number;
      }
    | {
          type: typeof COUNT_FACTORING_CLIENTS_SET;
          payload: number;
      };

// ACTIONS
export const countsReset = () => ({
    type: COUNTS_RESET,
});

export const countAccountsSet = (payload: number) => ({
    type: COUNT_ACCOUNTS_SET,
    payload,
});

export const countAccountTransactionsSet = (payload: {
    id: string;
    count: number;
}) => ({
    type: COUNT_ACCOUNT_TRANSACTIONS_SET,
    payload,
});

export const countCardsSet = (payload: number) => ({
    type: COUNT_CARDS_SET,
    payload,
});

export const countSharedCardsSet = (payload: number) => ({
    type: COUNT_SHARED_CARDS_SET,
    payload,
});

export const countCardTransactionsSet = (payload: {
    id: string;
    count: number;
}) => ({
    type: COUNT_CARD_TRANSACTIONS_SET,
    payload,
});

export const countTransactionsAllSet = (payload: number) => ({
    type: COUNT_TRANSACTIONS_ALL_SET,
    payload,
});

export const countTransactionsPayablesSet = (payload: number) => ({
    type: COUNT_TRANSACTIONS_PAYABLES_SET,
    payload,
});

export const countTransactionsReceivablesSet = (payload: number) => ({
    type: COUNT_TRANSACTIONS_RECEIVABLES_SET,
    payload,
});

export const countTransactionsFactoringRequestsSet = (payload: number) => ({
    type: COUNT_TRANSACTIONS_FACTORING_REQUESTS_SET,
    payload,
});

export const countTransactionsArchivedSet = (payload: number) => ({
    type: COUNT_TRANSACTIONS_ARCHIVED_SET,
    payload,
});

export const countCirclesAllSet = (payload: number) => ({
    type: COUNT_CIRCLES_ALL_SET,
    payload,
});

export const countChainsAllSet = (payload: number) => ({
    type: COUNT_CHAINS_ALL_SET,
    payload,
});

export const countReportsAllSet = (payload: number) => ({
    type: COUNT_REPORTS_ALL_SET,
    payload,
});

export const countReceiptsAllSet = (payload: number) => ({
    type: COUNT_RECEIPTS_ALL_SET,
    payload,
});

export const countFactoringOrganizationsSet = (payload: number) => ({
    type: COUNT_FACTORING_ORGANIZATIONS_SET,
    payload,
});

export const countFactoringClientsSet = (payload: number) => ({
    type: COUNT_FACTORING_CLIENTS_SET,
    payload,
});

const INITIAL_STATE: CollectionsCount = {
    countAccounts: 0,
    countAccountsTransactions: {},
    countCards: 0,
    countSharedCards: 0,
    countCardsTransactions: {},
    countTransactionAll: 0,
    countTransactionPayables: 0,
    countTransactionsReceivables: 0,
    countTransactionsFactoringRequests: 0,
    countTransactionsArchived: 0,
    countCirclesAll: 0,
    countChainsAll: 0,
    countReportsAll: 0,
    countReceiptsAll: 0,
    countFactoringOrganizations: 0,
    countFactoringClients: 0,
};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case COUNTS_RESET:
            return INITIAL_STATE;
        case COUNT_ACCOUNTS_SET:
            return { ...state, countAccounts: action.payload };
        case COUNT_ACCOUNT_TRANSACTIONS_SET:
            return {
                ...state,
                countAccountsTransactions: {
                    [action?.payload?.id]: action?.payload?.count,
                },
            };
        case COUNT_CARDS_SET:
            return { ...state, countCards: action.payload };
        case COUNT_SHARED_CARDS_SET:
            return { ...state, countSharedCards: action.payload };
        case COUNT_CARD_TRANSACTIONS_SET:
            return {
                ...state,
                countCardsTransactions: {
                    [action?.payload?.id]: action?.payload?.count,
                },
            };
        case COUNT_TRANSACTIONS_ALL_SET:
            return { ...state, countTransactionAll: action.payload };
        case COUNT_TRANSACTIONS_PAYABLES_SET:
            return { ...state, countTransactionPayables: action.payload };
        case COUNT_TRANSACTIONS_RECEIVABLES_SET:
            return { ...state, countTransactionsReceivables: action.payload };
        case COUNT_TRANSACTIONS_FACTORING_REQUESTS_SET:
            return {
                ...state,
                countTransactionsFactoringRequests: action.payload,
            };
        case COUNT_TRANSACTIONS_ARCHIVED_SET:
            return { ...state, countTransactionsArchived: action.payload };
        case COUNT_CIRCLES_ALL_SET:
            return { ...state, countCirclesAll: action.payload };
        case COUNT_CHAINS_ALL_SET:
            return { ...state, countChainsAll: action.payload };
        case COUNT_REPORTS_ALL_SET:
            return { ...state, countReportsAll: action.payload };
        case COUNT_RECEIPTS_ALL_SET:
            return { ...state, countReceiptsAll: action.payload };
        case COUNT_FACTORING_ORGANIZATIONS_SET:
            return { ...state, countFactoringOrganizations: action.payload };
        case COUNT_FACTORING_CLIENTS_SET:
            return { ...state, countFactoringClients: action.payload };

        default:
            return state;
    }
};
