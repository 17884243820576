import { createSelector } from 'reselect';
import { AppState, ReportsState, Report } from '../types/types';

// SELECTORS
export const getReports = (state: AppState) => state.reports;
export const getReportById = (id?: number) => {
    return createSelector(getReports, (reports: ReportsState) => {
        if (id) {
            return reports[id];
        }
        return undefined;
    });
};
export const getReportCanPayById = (id?: number) => {
    return createSelector(
        getReportById(id),
        (report: Report | undefined) => report?.can_pay,
    );
};

// ACTION TYPES
const REPORTS_SET = 'REPORTS_SET ';
const REPORTS_UPDATE = 'REPORTS_UPDATE';
const REPORT_UPDATE = 'REPORT_UPDATE';
const REPORT_ADD = 'REPORT_ADD';
const REPORT_DELETE = 'REPORT_DELETE';

type ACTION =
    | {
          type: typeof REPORTS_SET;
          payload?: Report[];
      }
    | {
          type: typeof REPORTS_UPDATE;
          payload?: Report[];
      }
    | {
          type: typeof REPORT_UPDATE;
          payload?: Report;
      }
    | {
          type: typeof REPORT_ADD;
          payload?: Report;
      }
    | {
          type: typeof REPORT_DELETE;
          payload?: Report['id'];
      };

// ACTIONS
export const reportsSet = (payload?: { [key: number]: Report }) => ({
    type: REPORTS_SET,
    payload,
});
export const reportsUpdate = (payload?: Report[]) => ({
    type: REPORTS_UPDATE,
    payload,
});

export const reportUpdate = (payload?: Report) => ({
    type: REPORT_UPDATE,
    payload,
});
export const reportAdd = (payload?: Report) => ({
    type: REPORT_ADD,
    payload,
});

export const reportDelete = (payload?: Report['id']) => ({
    type: REPORT_DELETE,
    payload,
});

const INITIAL_STATE: ReportsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case REPORTS_SET:
            const reports: ReportsState = {};
            if (action.payload?.length) {
                action.payload.forEach(report => (reports[report.id] = report));
            }
            return reports;
        case REPORTS_UPDATE:
            const nextReports: ReportsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    report => (nextReports[report.id] = report),
                );
            }
            return { ...state, ...nextReports };
        case REPORT_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case REPORT_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case REPORT_DELETE:
            const reportsState = { ...state };
            if (action.payload) {
                delete reportsState[action.payload];
            }
            return reportsState;

        default:
            return state;
    }
};
