import { createSelector } from 'reselect';
import { getMyCurrentOrganizationId } from './statistics';
import { getMyOrganizations } from './myOrganizations';
import { AppState, TBillingPlan } from '../types/types';

// SELECTORS
export const selectBillingPlans = (state: AppState) => state.billingPlans;
export const selectCurrentBillingPlan = createSelector(
    getMyCurrentOrganizationId,
    getMyOrganizations,
    selectBillingPlans,
    (currentOrganizationId, organizations, billingPlans) => {
        if (currentOrganizationId) {
            const planId = organizations[currentOrganizationId]?.plan_id || 1;
            return billingPlans?.find(plan => plan?.id === planId);
        }
        return undefined;
    },
);

export const selectBillingPlanById = (id?: number) =>
    createSelector(selectBillingPlans, plans =>
        plans?.find(plan => plan?.id === id),
    );

export const selectAvailableBillingPlans = createSelector(
    selectBillingPlans,
    selectCurrentBillingPlan,
    (billingPlans, currentBillingPlan) => {
        const currentPlanIndex = billingPlans?.findIndex(
            item => item?.id === currentBillingPlan?.id,
        );
        let availablePlans = [...billingPlans];
        if (currentPlanIndex > -1) {
            availablePlans = availablePlans.slice(currentPlanIndex + 1);
        }
        return availablePlans;
    },
);

// ACTION TYPES
const BILLING_PLANS_SET = 'BILLING_PLANS_SET';

type ACTION = {
    type: typeof BILLING_PLANS_SET;
    payload: TBillingPlan[];
};

// ACTIONS
export const billingPlansSet = (payload: TBillingPlan[]) => ({
    type: BILLING_PLANS_SET,
    payload,
});

const INITIAL_STATE: TBillingPlan[] = [];

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case BILLING_PLANS_SET:
            return action.payload;

        default:
            return state;
    }
};
