import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { LeftMenu } from './LeftMenu';
import {
    Circle,
    Circles,
    ExternalConnection,
    ExternalConnections,
    Factoring,
    FactoringClient,
    FactoringClients,
    FactoringList,
    MyGroup,
    MyGroups,
    Organization,
    OrganizationMember,
    OrganizationMembers,
    Profile,
    Receipt,
    Receipts,
    Report,
    Reports,
    Transaction,
    Transactions,
} from '..';
import {
    askNotificationPermission,
    fetchNotifications,
} from '../../services/NotificationsService';
import { fetchUser } from '../../services/UsersService';
import { getToken } from '../../state/token';
import { Chain } from '../Chain';
import { Chains } from '../Chains';
import { fetchMyOrganizations } from '../../services/OrganizationsService';
import {
    getAbilityEditCurrentOrganizationSettings,
    getAbilityToManageCirclesAndChains,
    getAbilityToManageMembers,
    getAbilityToManageTransactions,
    getMyCurrentOrganizationType,
} from '../../state/myOrganizations';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { fetchCurrencies } from '../../services/CurrenciesService';
import { getCountries } from '../../state/countries';
import { fetchCountries } from '../../services/CountriesService';
import { BillingDetails } from '../BillingDetails';

// let location: any;

const preFetchData = async () => {
    await fetchUser();
    await fetchCurrencies();
    await fetchMyOrganizations();
    await askNotificationPermission();
};

export const Home = () => {
    const history = useHistory();

    const token = useSelector(getToken);
    useEffect(() => {
        if (!token) {
            history.push('/sign-in');
        }
    }, [history, token]);

    useEffect(() => {
        (async () => {
            await preFetchData();
        })();
    }, []);

    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        if (currentOrganizationId) {
            (async () => {
                // await preFetchOrganizationDependData();
                await fetchNotifications();
            })();
        }
    }, [currentOrganizationId]);

    const myCurrentOrganizationType = useSelector(getMyCurrentOrganizationType);
    const abilityEditCurrentOrganizationSettings = useSelector(
        getAbilityEditCurrentOrganizationSettings,
    );
    const abilityToManageMembers = useSelector(getAbilityToManageMembers);
    const abilityToManageCirclesAndChains = useSelector(
        getAbilityToManageCirclesAndChains,
    );
    const abilityToManageTransactions = useSelector(
        getAbilityToManageTransactions,
    );

    const countries = useSelector(getCountries);

    useEffect(() => {
        if (!Object.keys(countries)?.length) {
            (async () => {
                await fetchCountries();
            })();
        }
    }, [countries]);

    return (
        <div className="block block-app">
            <LeftMenu />
            <Switch>
                {!!currentOrganizationId && (
                    <Route path="/transactions/payables">
                        <Transactions />
                    </Route>
                )}
                {!!currentOrganizationId && (
                    <Route path="/transactions/receivables">
                        <Transactions />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageTransactions && (
                    <Route path="/factoring-requests/transactions">
                        <Transactions />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageTransactions && (
                    <Route path="/transactions/receipts">
                        <Receipts />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageTransactions && (
                    <Route path="/transactions/archived">
                        <Transactions />
                    </Route>
                )}
                {!!currentOrganizationId && (
                    <Route path="/transactions/transaction/:id/:newOne?">
                        <Transaction />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageTransactions && (
                    <Route path="/factoring-requests/transaction/:id">
                        <Transaction />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageTransactions && (
                    <Route path="/transactions/receipt/:id">
                        <Receipt />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageCirclesAndChains && (
                    <Route path="/circles/all">
                        <Circles />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageCirclesAndChains && (
                    <Route path="/circles/circle/:id">
                        <Circle />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageCirclesAndChains && (
                    <Route path="/chains/all">
                        <Chains />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageCirclesAndChains && (
                    <Route path="/chains/chain/:id">
                        <Chain />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageCirclesAndChains && (
                    <Route path="/reports/all">
                        <Reports />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageCirclesAndChains && (
                    <Route path="/reports/report/:id">
                        <Report />
                    </Route>
                )}
                {!!currentOrganizationId &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/organization/:id">
                            <Organization />
                        </Route>
                    )}
                {!!currentOrganizationId &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/organization:id/billing-details">
                            <BillingDetails />
                        </Route>
                    )}
                {!!currentOrganizationId &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/organization:id/my-groups">
                            <MyGroups />
                        </Route>
                    )}
                {!!currentOrganizationId &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/my-group/:id">
                            <MyGroup />
                        </Route>
                    )}
                {!!currentOrganizationId && abilityToManageMembers && (
                    <Route path="/organization:id/members">
                        <OrganizationMembers />
                    </Route>
                )}
                {!!currentOrganizationId && abilityToManageMembers && (
                    <Route path="/organization:id/member/:memberId">
                        <OrganizationMember />
                    </Route>
                )}
                {!!currentOrganizationId &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/external-connections">
                            <ExternalConnections />
                        </Route>
                    )}
                {!!currentOrganizationId &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/external-connection/:id">
                            <ExternalConnection />
                        </Route>
                    )}

                {!!currentOrganizationId &&
                    !!myCurrentOrganizationType &&
                    myCurrentOrganizationType === 'regular' &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/factoring-organizations/">
                            <FactoringList />
                        </Route>
                    )}
                {!!currentOrganizationId &&
                    !!myCurrentOrganizationType &&
                    myCurrentOrganizationType === 'regular' &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/factoring-organization/:id">
                            <Factoring />
                        </Route>
                    )}

                {!!currentOrganizationId &&
                    !!myCurrentOrganizationType &&
                    myCurrentOrganizationType === 'factoring' &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/factoring-clients/">
                            <FactoringClients />
                        </Route>
                    )}
                {!!currentOrganizationId &&
                    !!myCurrentOrganizationType &&
                    myCurrentOrganizationType === 'factoring' &&
                    abilityEditCurrentOrganizationSettings && (
                        <Route path="/factoring-client/:id">
                            <FactoringClient />
                        </Route>
                    )}
                <Route path="/profile">
                    <Profile />
                </Route>
                <Route path="/change-password">
                    <Profile />
                </Route>
                <Route path="/new-organization">
                    <Organization />
                </Route>

                <Route
                    render={() => (
                        <Redirect
                            to={
                                currentOrganizationId
                                    ? '/transactions/payables'
                                    : '/new-organization'
                            }
                        />
                    )}
                />
            </Switch>
        </div>
    );
};
