import { AppState, IPermission } from '../types/types';

// SELECTORS
export const getPermissions = (state: AppState) => state.permissions;

// ACTION TYPES
const PERMISSIONS_SET = 'PERMISSIONS_SET';

type ACTION = {
    type: typeof PERMISSIONS_SET;
    payload: IPermission[];
};

// ACTIONS
export const permissionsSet = (payload: IPermission[]) => ({
    type: PERMISSIONS_SET,
    payload,
});

const INITIAL_STATE: IPermission[] = [];

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case PERMISSIONS_SET:
            return action.payload;

        default:
            return state;
    }
};
