import api from './axiosConfig';
import { userLogout } from '../state';
import { store } from '../state/store';
import { getToken } from '../state/token';

export const logOut = async () => await api.post('/auth/logout');

export const signOut = async () => {
    if (getToken(store.getState())) {
        await logOut();
    }
    store.dispatch(userLogout());
};
