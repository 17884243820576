import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { MenuLink } from './MenuLink';
import { Message } from './Message';
import { FactoringButtonWrapper, TransactionButtonWrapper } from './styles';
import { HeaderMenu } from './HeaderMenu';
import { Tab } from './Tab';
import menuIconHelp from '../../../assets/img/menu-icons/menu-icon-help.svg';
import menuIconProfile from '../../../assets/img/menu-icons/menu-icon-profile-black.svg';
import menuIconNotifications from '../../../assets/img/menu-icons/menu-icon-notifications-black.svg';
import { readNotifications } from '../../../services/NotificationsService';
import { Notifications } from '../../../screens';
import { useComponentVisible } from '../../../hooks';
import { FactoringButtons, Row, TransactionButtons } from '..';
import { Organization } from '../../../types/types';
import { getSumNotifications } from '../../../state/statistics';
import { Line1 } from '../Line1';
import { fetchCurrentOrganizationStatistics } from '../../../services/OrganizationsService';

const transitionStyles = {
    entering: {
        opacity: 1,
        transition: 'opacity 1s ease-in-out',
    },
    entered: { opacity: 0.85, transition: 'opacity 1s ease-in-out' },
    exiting: {
        opacity: 0.5,
        transition: 'opacity 1s ease-in-out',
    },
    exited: { opacity: 0, transition: 'opacity 1s ease-in-out' },
};

type Props = {
    type?: 'headerMenu' | 'breadcrumbs';
    title?: string;
    headerMenuItems?: { title: string; link?: string }[];
    headerSubtitles?: { subtitle?: string; subtitleLink?: string }[];
    isLoading?: boolean;
    isButtonDisabled?: boolean;
    singleButtonText?: string;
    singleButtonClassName?: string;
    singleButtonMdi?: string;
    singleButton2Text?: string;
    singleButton2ClassName?: string;
    singleButton2Mdi?: string;
    singleButton3Text?: string;
    singleButton3ClassName?: string;
    singleButton3Mdi?: string;
    singleButton4Text?: string;
    singleButton4ClassName?: string;
    singleButton4Mdi?: string;
    singleButton5Text?: string;
    singleButton5ClassName?: string;
    singleButton5Mdi?: string;
    downloadButton?: boolean;
    acceptButton?: boolean;
    rejectButton?: boolean;
    archiveButton?: boolean;
    pdfButton?: boolean;
    transactionButton?: boolean;
    logoutButton?: boolean;
    factoringButton?: boolean;
    factoringOrganizations?: Organization[];
    sendToFactorButton?: boolean;
    currentConnectedFactoringOrganizations?: Organization[];
    canFactorCancel?: boolean;
    canAcceptOrRejectFactorRequest?: boolean;
    onSingleButtonPress?: () => void;
    onSingleButton2Press?: () => void;
    onSingleButton3Press?: () => void;
    onSingleButton4Press?: () => void;
    onSingleButton5Press?: () => void;
    onAccept?: () => void;
    onReject?: () => void;
    onArchive?: () => void;
    onPdfDownloadPress?: () => void;
    createPtp?: () => void;
    createInvoice?: () => void;
    downloadPath?: string;
    logout?: () => void;
    tabs?: { name: string; active?: boolean; link?: string }[];
    activeOrganizationButton?: boolean;
    setOrganizationActive?: () => void;
    membersButton?: boolean;
    creditCardButton?: boolean;
    openMembers?: () => void;
    onCreditCardAdd?: () => void;
    message?: 'success' | 'fail' | undefined;
    resetStatus?: () => void;
    onFactoring?: (id: number) => Promise<void>;
    onSendToFactorOrganization?: (id: number) => Promise<void>;
    onCancelFactoringRequest?: () => Promise<void>;
    onAcceptFactoringRequest?: () => Promise<void>;
    onRejectFactoringRequest?: () => Promise<void>;
};

export const AppHeader = ({
    type,
    title,
    headerMenuItems,
    headerSubtitles,
    isLoading,
    isButtonDisabled,
    singleButtonText,
    singleButtonClassName,
    singleButtonMdi,
    singleButton2Text,
    singleButton2ClassName,
    singleButton2Mdi,
    singleButton3Text,
    singleButton3ClassName,
    singleButton3Mdi,
    singleButton4Text,
    singleButton4ClassName,
    singleButton4Mdi,
    singleButton5Text,
    singleButton5ClassName,
    singleButton5Mdi,
    downloadButton,
    acceptButton,
    rejectButton,
    archiveButton,
    pdfButton,
    transactionButton,
    logoutButton,
    factoringButton,
    factoringOrganizations,
    sendToFactorButton,
    currentConnectedFactoringOrganizations,
    canFactorCancel,
    canAcceptOrRejectFactorRequest,
    onSingleButtonPress,
    onSingleButton2Press,
    onSingleButton3Press,
    onSingleButton4Press,
    onSingleButton5Press,
    onAccept,
    onReject,
    onArchive,
    onPdfDownloadPress,
    createPtp,
    createInvoice,
    downloadPath,
    logout,
    tabs,
    activeOrganizationButton,
    setOrganizationActive,
    membersButton,
    creditCardButton,
    openMembers,
    onCreditCardAdd,
    message,
    resetStatus,
    onFactoring,
    onSendToFactorOrganization,
    onCancelFactoringRequest,
    onAcceptFactoringRequest,
    onRejectFactoringRequest,
}: Props) => {
    const location = useLocation();
    const [isNotificationsVisible, setNotificationsVisible] = useState(false);

    const openNotifications = useCallback(() => {
        setNotificationsVisible(true);
    }, []);

    const closeNotificationsPopup = useCallback(async () => {
        if (isNotificationsVisible) {
            setNotificationsVisible(false);
            await readNotifications();
            await fetchCurrentOrganizationStatistics();
        }
    }, [isNotificationsVisible]);

    const sumNotifications = useSelector(getSumNotifications);
    const [success, setSuccess] = useState<'success' | 'fail' | undefined>();

    useEffect(() => {
        if (message) {
            setSuccess(message);
        }
    }, [message]);

    useEffect(() => {
        let successTimer: any;
        let transitionTimer: any;
        if (success) {
            successTimer = setTimeout(() => {
                setSuccess(undefined);
                transitionTimer = setTimeout(() => {
                    if (resetStatus) resetStatus();
                }, 1000);
            }, 2000);
        }

        return () => {
            if (successTimer) {
                clearTimeout(successTimer);
            }
            if (transitionTimer) {
                clearTimeout(transitionTimer);
            }
        };
    }, [resetStatus, success]);

    const transitionRef = useRef(null);

    const {
        ref,
        buttonRef,
        isComponentVisible,
        setIsComponentVisible,
    } = useComponentVisible(false);

    const setSelectButtonsVisible = useCallback(
        () => setIsComponentVisible(prevState => !prevState),
        [setIsComponentVisible],
    );

    const onFactor = useCallback(
        async (id: number) => {
            setIsComponentVisible(false);
            if (onFactoring) {
                await onFactoring(id);
            }
        },
        [onFactoring, setIsComponentVisible],
    );

    const transactionButtonHook = useComponentVisible(false);

    const setTransactionSelectButtonsVisible = useCallback(
        () =>
            transactionButtonHook?.setIsComponentVisible(
                prevState => !prevState,
            ),
        [transactionButtonHook],
    );

    const createPtpMethod = useCallback(() => {
        transactionButtonHook?.setIsComponentVisible(false);
        if (createPtp) createPtp();
    }, [createPtp, transactionButtonHook]);

    const createInvoiceMethod = useCallback(() => {
        transactionButtonHook?.setIsComponentVisible(false);
        if (createInvoice) createInvoice();
    }, [createInvoice, transactionButtonHook]);

    const factoringButtonClick = useCallback(async () => {
        if (factoringOrganizations?.length) {
            if (factoringOrganizations?.length > 1) {
                setSelectButtonsVisible();
            } else {
                await onFactor(factoringOrganizations[0]?.id);
            }
        }
    }, [factoringOrganizations, onFactor, setSelectButtonsVisible]);

    const sendToFactoringButtonHook = useComponentVisible(false);

    const onSendToFactor = useCallback(
        async (id: number) => {
            sendToFactoringButtonHook?.setIsComponentVisible(false);
            if (onSendToFactorOrganization) {
                await onSendToFactorOrganization(id);
            }
        },
        [onSendToFactorOrganization, sendToFactoringButtonHook],
    );

    const sendToFactoringButtonClick = useCallback(async () => {
        if (currentConnectedFactoringOrganizations?.length) {
            if (currentConnectedFactoringOrganizations?.length > 1) {
                sendToFactoringButtonHook?.setIsComponentVisible(
                    prevState => !prevState,
                );
            } else {
                await onSendToFactor(
                    currentConnectedFactoringOrganizations[0]?.id,
                );
            }
        }
    }, [
        currentConnectedFactoringOrganizations,
        onSendToFactor,
        sendToFactoringButtonHook,
    ]);

    const notifElRef = useRef<any>(null);

    const getTriangleRight = useCallback(() => {
        if (
            typeof notifElRef?.current?.offsetLeft === 'number' &&
            typeof notifElRef?.current?.offsetLeft === 'number' &&
            typeof notifElRef?.current?.offsetWidth === 'number'
        ) {
            return (
                notifElRef?.current?.offsetParent?.clientWidth -
                notifElRef?.current?.offsetLeft -
                notifElRef?.current?.offsetWidth / 2
            );
        }
        return 107;
    }, []);

    return (
        <div className="app-header" style={{ position: 'relative' }}>
            {!!message && (
                <Transition
                    nodeRef={transitionRef}
                    in={!!success}
                    timeout={1000}
                    appear
                    mountOnEnter
                    unmountOnExit
                >
                    {state => {
                        return (
                            <div
                                style={{
                                    ...transitionStyles[state],
                                }}
                                ref={transitionRef}
                            >
                                <Message type={message} />
                            </div>
                        );
                    }}
                </Transition>
            )}
            <div className="flex flex-direction-row">
                <div className="flex">
                    <div style={styles.header}>
                        <HeaderMenu
                            location={location}
                            type={type}
                            title={title}
                            isLoading={isLoading}
                            headerMenuItems={headerMenuItems}
                            headerSubtitles={headerSubtitles}
                        />
                        {!!tabs?.length && (
                            <Row>
                                {tabs?.map((tab, index) => (
                                    <Tab
                                        key={index.toString()}
                                        tab={tab}
                                        tabIndex={index}
                                    />
                                ))}
                            </Row>
                        )}
                    </div>
                </div>

                <div className="flex flex-grow flex-end">
                    <div style={{ display: 'inline-block' }}>
                        <div className="app-header-buttons">
                            {!!acceptButton && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-success"
                                        disabled={isButtonDisabled}
                                        onClick={onAccept}
                                        onKeyDown={onAccept}
                                    >
                                        <em className="mdi mdi-check-all start" />
                                        Accept
                                    </button>
                                </div>
                            )}
                            {!!rejectButton && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-danger"
                                        disabled={isButtonDisabled}
                                        onClick={onReject}
                                        onKeyDown={onReject}
                                    >
                                        <em className="mdi mdi-close start" />
                                        Reject
                                    </button>
                                </div>
                            )}
                            {!!archiveButton && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-primary button-primary-outline"
                                        disabled={isButtonDisabled}
                                        onClick={onArchive}
                                        onKeyDown={onArchive}
                                    >
                                        <em className="mdi mdi-archive start" />
                                        Archive
                                    </button>
                                </div>
                            )}
                            {!!pdfButton && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-primary"
                                        disabled={isButtonDisabled}
                                        onClick={onPdfDownloadPress}
                                        onKeyDown={onPdfDownloadPress}
                                    >
                                        <em className="mdi mdi-download start" />
                                        Download PDF
                                    </button>
                                </div>
                            )}
                            {transactionButton && (
                                <div style={{ position: 'relative' }}>
                                    <button
                                        ref={transactionButtonHook?.buttonRef}
                                        type="button"
                                        className="button button-primary"
                                        onClick={
                                            setTransactionSelectButtonsVisible
                                        }
                                    >
                                        <em className="mdi mdi-plus start" />
                                        Create
                                    </button>
                                    <TransactionButtonWrapper
                                        ref={transactionButtonHook?.ref}
                                    >
                                        {transactionButtonHook?.isComponentVisible && (
                                            <TransactionButtons
                                                disabled={isButtonDisabled}
                                                createPtp={createPtpMethod}
                                                createInvoice={
                                                    createInvoiceMethod
                                                }
                                            />
                                        )}
                                    </TransactionButtonWrapper>
                                </div>
                            )}

                            {!!logoutButton && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-default"
                                        disabled={isButtonDisabled}
                                        onClick={logout}
                                        onKeyDown={logout}
                                    >
                                        <em className="mdi mdi-logout start" />
                                        Logout
                                    </button>
                                </div>
                            )}
                            {!!activeOrganizationButton && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-primary button-primary-outline"
                                        disabled={isButtonDisabled}
                                        onClick={setOrganizationActive}
                                        onKeyDown={setOrganizationActive}
                                    >
                                        Set as default
                                        <em className="mdi mdi-auto-fix end" />
                                    </button>
                                </div>
                            )}
                            {!!membersButton && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-primary"
                                        disabled={isButtonDisabled}
                                        onClick={openMembers}
                                        onKeyDown={openMembers}
                                    >
                                        <em className="mdi mdi-account-multiple-outline start" />
                                        Members
                                    </button>
                                </div>
                            )}
                            {!!creditCardButton && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-primary"
                                        disabled={isButtonDisabled}
                                        onClick={onCreditCardAdd}
                                        onKeyDown={onCreditCardAdd}
                                    >
                                        <em className="mdi mdi-plus start" />
                                        Add new card
                                    </button>
                                </div>
                            )}

                            {!!downloadButton && (
                                <div>
                                    <a
                                        href={downloadPath}
                                        download
                                        className="button button-default"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <em className="mdi mdi-download start" />
                                        Download Attachment
                                    </a>
                                </div>
                            )}
                            {sendToFactorButton &&
                                !!currentConnectedFactoringOrganizations?.length &&
                                !canFactorCancel && (
                                    <div
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        <button
                                            ref={
                                                sendToFactoringButtonHook?.buttonRef
                                            }
                                            type="button"
                                            className="button button-primary button-primary-outline"
                                            disabled={isButtonDisabled}
                                            onClick={sendToFactoringButtonClick}
                                        >
                                            {currentConnectedFactoringOrganizations?.length ===
                                                1 && (
                                                <em className="mdi mdi-cash start" />
                                            )}
                                            {`Send to${
                                                currentConnectedFactoringOrganizations?.length >
                                                1
                                                    ? ''
                                                    : ` ${currentConnectedFactoringOrganizations[0]?.company_name}`
                                            }`}
                                            {currentConnectedFactoringOrganizations?.length >
                                                1 && (
                                                <em
                                                    className={`mdi mdi-menu-${
                                                        sendToFactoringButtonHook?.isComponentVisible
                                                            ? 'up'
                                                            : 'down'
                                                    } end`}
                                                />
                                            )}
                                        </button>
                                        <FactoringButtonWrapper
                                            ref={sendToFactoringButtonHook?.ref}
                                        >
                                            {sendToFactoringButtonHook?.isComponentVisible && (
                                                <FactoringButtons
                                                    organizations={
                                                        currentConnectedFactoringOrganizations
                                                    }
                                                    onPress={onSendToFactor}
                                                />
                                            )}
                                        </FactoringButtonWrapper>
                                    </div>
                                )}

                            {canFactorCancel && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-primary button-primary-outline"
                                        disabled={isButtonDisabled}
                                        onClick={onCancelFactoringRequest}
                                    >
                                        Cancel factoring request
                                    </button>
                                </div>
                            )}

                            {!!canAcceptOrRejectFactorRequest && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-success"
                                        disabled={isButtonDisabled}
                                        onClick={onAcceptFactoringRequest}
                                        onKeyDown={onAcceptFactoringRequest}
                                    >
                                        <em className="mdi mdi-check-all start" />
                                        Accept Factoring Request
                                    </button>
                                </div>
                            )}

                            {!!canAcceptOrRejectFactorRequest && (
                                <div>
                                    <button
                                        type="button"
                                        className="button button-danger"
                                        disabled={isButtonDisabled}
                                        onClick={onRejectFactoringRequest}
                                        onKeyDown={onRejectFactoringRequest}
                                    >
                                        <em className="mdi mdi-close start" />
                                        Reject Factoring Request
                                    </button>
                                </div>
                            )}
                            {factoringButton &&
                                !!factoringOrganizations?.length && (
                                    <div style={{ position: 'relative' }}>
                                        <button
                                            ref={buttonRef}
                                            type="button"
                                            className="button button-primary button-primary-outline"
                                            disabled={isButtonDisabled}
                                            onClick={factoringButtonClick}
                                        >
                                            {factoringOrganizations?.length ===
                                                1 && (
                                                <em className="mdi mdi-cash start" />
                                            )}
                                            {`Factor by${
                                                factoringOrganizations?.length >
                                                1
                                                    ? ''
                                                    : ` ${factoringOrganizations[0]?.company_name}`
                                            }`}
                                            {factoringOrganizations?.length >
                                                1 && (
                                                <em
                                                    className={`mdi mdi-menu-${
                                                        isComponentVisible
                                                            ? 'up'
                                                            : 'down'
                                                    } end`}
                                                />
                                            )}
                                        </button>
                                        <FactoringButtonWrapper ref={ref}>
                                            {isComponentVisible && (
                                                <FactoringButtons
                                                    organizations={
                                                        factoringOrganizations
                                                    }
                                                    onPress={onFactor}
                                                />
                                            )}
                                        </FactoringButtonWrapper>
                                    </div>
                                )}
                            {!!singleButtonText && (
                                <div>
                                    <button
                                        type="button"
                                        className={
                                            singleButtonClassName ||
                                            'button button-primary'
                                        }
                                        disabled={isButtonDisabled}
                                        onClick={onSingleButtonPress}
                                    >
                                        {!!singleButtonMdi && (
                                            <em
                                                className={`mdi mdi-${singleButtonMdi} start`}
                                            />
                                        )}
                                        <Line1>{singleButtonText}</Line1>
                                    </button>
                                </div>
                            )}

                            {!!singleButton2Text && (
                                <div>
                                    <button
                                        type="button"
                                        className={
                                            singleButton2ClassName ||
                                            'button button-danger'
                                        }
                                        disabled={isButtonDisabled}
                                        onClick={onSingleButton2Press}
                                    >
                                        {!!singleButton2Mdi && (
                                            <em
                                                className={`mdi mdi-${singleButton2Mdi} start`}
                                            />
                                        )}
                                        <Line1>{singleButton2Text}</Line1>
                                    </button>
                                </div>
                            )}

                            {!!singleButton3Text && (
                                <div>
                                    <button
                                        type="button"
                                        className={
                                            singleButton3ClassName ||
                                            'button button-primary'
                                        }
                                        disabled={isButtonDisabled}
                                        onClick={onSingleButton3Press}
                                    >
                                        {!!singleButton3Mdi && (
                                            <em
                                                className={`mdi mdi-${singleButton3Mdi} start`}
                                            />
                                        )}
                                        <Line1>{singleButton3Text}</Line1>
                                    </button>
                                </div>
                            )}

                            {!!singleButton4Text && (
                                <div>
                                    <button
                                        type="button"
                                        className={
                                            singleButton4ClassName ||
                                            'button button-primary'
                                        }
                                        disabled={isButtonDisabled}
                                        onClick={onSingleButton4Press}
                                    >
                                        {!!singleButton4Mdi && (
                                            <em
                                                className={`mdi mdi-${singleButton4Mdi} start`}
                                            />
                                        )}
                                        <Line1>{singleButton4Text}</Line1>
                                    </button>
                                </div>
                            )}

                            {!!singleButton5Text && (
                                <div>
                                    <button
                                        type="button"
                                        className={
                                            singleButton5ClassName ||
                                            'button button-danger'
                                        }
                                        disabled={isButtonDisabled}
                                        onClick={onSingleButton5Press}
                                    >
                                        {!!singleButton5Mdi && (
                                            <em
                                                className={`mdi mdi-${singleButton5Mdi} start`}
                                            />
                                        )}
                                        <Line1>{singleButton5Text}</Line1>
                                    </button>
                                </div>
                            )}

                            <MenuLink
                                type="blank"
                                path="https://troccircle.com/contact"
                                src={menuIconHelp}
                                label="Help"
                                style={{ marginLeft: 10 }}
                            />
                            <MenuLink
                                ref={notifElRef}
                                src={menuIconNotifications}
                                isNotificationsVisible={isNotificationsVisible}
                                sumNotifications={sumNotifications}
                                openNotifications={openNotifications}
                            />
                            <MenuLink path="/profile" src={menuIconProfile} />
                        </div>
                    </div>
                </div>
            </div>
            <Notifications
                triangleRight={getTriangleRight()}
                isOpen={isNotificationsVisible}
                closeNotificationsPopup={closeNotificationsPopup}
            />
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties | undefined } = {
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
};
