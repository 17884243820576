/* eslint-disable  @typescript-eslint/no-explicit-any */
import { combineReducers } from 'redux';
import backgroundNotification from './backgroundNotification';
import billingPlans from './billingPlans';
import chains from './chains';
import circles from './circles';
import collectionsCount from './collectionsCount';
import connectedFactoringOrganizations from './connectedFactoringOrganizations';
import countries from './countries';
import currencies from './currencies';
import externalConnections from './externalConnections';
import groups from './groups';
import legalForms from './legalForms';
import members from './members';
import myOrganizations from './myOrganizations';
import notifications from './notifications';
import organizations from './organizations';
import organizationsFactoring from './organizationsFactoring';
import organizationsFactoringClients from './organizationsFactoringClients';
import permissions from './permissions';
import receipts from './receipts';
import reports from './reports';
import statistics from './statistics';
import token from './token';
import transactions from './transactions';
import user from './user';
import users from './users';

const appReducer = combineReducers({
    backgroundNotification,
    billingPlans,
    chains,
    circles,
    collectionsCount,
    connectedFactoringOrganizations,
    countries,
    currencies,
    externalConnections,
    groups,
    legalForms,
    members,
    myOrganizations,
    notifications,
    organizations,
    organizationsFactoring,
    organizationsFactoringClients,
    permissions,
    receipts,
    reports,
    statistics,
    token,
    transactions,
    user,
    users,
});

export const userLogout = () => ({
    type: 'USER_LOGOUT',
});

export const rootReducer = (state: any, action: any) => {
    let newState = state;
    if (action.type === 'USER_LOGOUT') {
        localStorage.removeItem('persist:root');
        localStorage.removeItem('token');
        newState = undefined;
    }
    return appReducer(newState, action);
};
