import { createSelector } from 'reselect';
import { AppState, Member, MembersState } from '../types/types';

// SELECTORS
export const getMembers = (state: AppState) => state?.members;
export const getMemberById = (id: number) =>
    createSelector(getMembers, (members: MembersState | undefined) => {
        if (members?.hasOwnProperty(id) && !!id) {
            return members[id];
        }
        return undefined;
    });

// ACTION TYPES
const MEMBERS_SET = 'MEMBERS_SET';
const MEMBER_UPDATE = 'MEMBER_UPDATE';
const MEMBER_DELETE = 'MEMBER_DELETE';

type ACTION =
    | {
          type: typeof MEMBERS_SET;
          payload?: Member[];
      }
    | {
          type: typeof MEMBER_UPDATE;
          payload?: Member;
      }
    | {
          type: typeof MEMBER_DELETE;
          payload: Member['id'];
      };

// ACTIONS
export const membersSet = (payload?: Member[]) => ({
    type: MEMBERS_SET,
    payload,
});

export const memberUpdate = (payload?: Member) => ({
    type: MEMBER_UPDATE,
    payload,
});

export const memberDelete = (payload: Member['id']) => ({
    type: MEMBER_DELETE,
    payload,
});

const INITIAL_STATE: MembersState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case MEMBERS_SET:
            const members: MembersState = {};
            if (action.payload?.length) {
                action.payload.forEach(member => (members[member.id] = member));
            }
            return members;
        case MEMBER_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case MEMBER_DELETE:
            const membersState = { ...state };
            if (action.payload) {
                delete membersState[action.payload];
            }
            return membersState;

        default:
            return state;
    }
};
