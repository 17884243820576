import { createSelector } from 'reselect';
import { AppState, Organization, OrganizationsState } from '../types/types';

// SELECTORS
export const getOrganizations = (state: AppState) => state.organizations;
export const getOrganizationById = (id: number) => {
    return createSelector(
        getOrganizations,
        (organizations: OrganizationsState) => {
            return organizations[id];
        },
    );
};

// ACTION TYPES
const ORGANIZATIONS_SET = 'ORGANIZATIONS_SET';
const ORGANIZATIONS_UPDATE = 'ORGANIZATIONS_UPDATE';
const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE';
const ORGANIZATION_ADD = 'ORGANIZATION_ADD';
const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE';

type ACTION =
    | {
          type: typeof ORGANIZATIONS_SET;
          payload?: Organization[];
      }
    | {
          type: typeof ORGANIZATIONS_UPDATE;
          payload?: Organization[];
      }
    | {
          type: typeof ORGANIZATION_UPDATE;
          payload?: Organization;
      }
    | {
          type: typeof ORGANIZATION_ADD;
          payload?: Organization;
      }
    | {
          type: typeof ORGANIZATION_DELETE;
          payload?: Organization['id'];
      };

// ACTIONS
export const organizationsSet = (payload?: Organization[]) => ({
    type: ORGANIZATIONS_SET,
    payload,
});
export const organizationsUpdate = (payload?: Organization[]) => ({
    type: ORGANIZATIONS_UPDATE,
    payload,
});

export const organizationUpdate = (payload?: Organization) => ({
    type: ORGANIZATION_UPDATE,
    payload,
});
export const organizationAdd = (payload?: Organization) => ({
    type: ORGANIZATION_ADD,
    payload,
});

export const organizationDelete = (payload?: Organization['id']) => ({
    type: ORGANIZATION_DELETE,
    payload,
});

const INITIAL_STATE: OrganizationsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case ORGANIZATIONS_SET:
            const organizations: OrganizationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    organization =>
                        (organizations[organization.id] = organization),
                );
            }
            return organizations;
        case ORGANIZATIONS_UPDATE:
            const nextOrganizations: OrganizationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    organization =>
                        (nextOrganizations[organization.id] = organization),
                );
            }
            return { ...state, ...nextOrganizations };
        case ORGANIZATION_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case ORGANIZATION_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case ORGANIZATION_DELETE:
            const organizationsState = { ...state };
            if (action.payload) {
                delete organizationsState[action.payload];
            }
            return organizationsState;

        default:
            return state;
    }
};
