import { createSelector } from 'reselect';
import { AppState, Currency } from '../types/types';

// SELECTORS
export const getCurrencies = (state: AppState) => state.currencies;
export const getCurrencyById = (id: number) => {
    return createSelector(getCurrencies, (currencies: Currency[]) => {
        return currencies.find(currency => currency?.id === id);
    });
};

export const getCurrencyByCode = (code?: string) =>
    createSelector(getCurrencies, (currencies: Currency[]) =>
        currencies?.find(currency => currency?.code === code),
    );

export const getDefaultCurrency = createSelector(
    getCurrencies,
    (currencies: Currency[]) => {
        const index = currencies.findIndex(
            currency => currency?.is_default === 1,
        );
        return currencies[index];
    },
);

// ACTION TYPES
const CURRENCIES_SET = 'CURRENCIES_SET';
const CURRENCIES_UPDATE = 'CURRENCIES_UPDATE';

type ACTION =
    | {
          type: typeof CURRENCIES_SET;
          payload: Currency[];
      }
    | {
          type: typeof CURRENCIES_UPDATE;
          payload: Currency[];
      };

// ACTIONS
export const currenciesSet = (payload: Currency[]) => ({
    type: CURRENCIES_SET,
    payload,
});
export const currenciesUpdate = (payload: Currency[]) => ({
    type: CURRENCIES_UPDATE,
    payload,
});

const INITIAL_STATE: Currency[] = [];

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case CURRENCIES_SET:
            return action.payload;
        case CURRENCIES_UPDATE:
            return [...state, ...action.payload];

        default:
            return state;
    }
};
