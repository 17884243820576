import { AppState } from '../types/types';

// SELECTORS
export const getToken = (state: AppState) => state.token.bearer;
export const getFcmToken = (state: AppState) => state.token.fcm;

// ACTION TYPES
const TOKEN_SET = 'TOKEN_SET';
const FCM_TOKEN_SET = 'FCM_TOKEN_SET';

type ACTION =
    | {
          type: typeof TOKEN_SET;
          payload: string;
      }
    | {
          type: typeof FCM_TOKEN_SET;
          payload: string;
      };

// ACTIONS
export const tokenSet = (payload: string) => ({
    type: TOKEN_SET,
    payload,
});

export const fcmTokenSet = (payload: string) => ({
    type: FCM_TOKEN_SET,
    payload,
});

const INITIAL_STATE = { bearer: undefined, fcm: undefined };

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case TOKEN_SET:
            return { ...state, bearer: action.payload };
        case FCM_TOKEN_SET:
            return { ...state, fcm: action.payload };
        default:
            return state;
    }
};
