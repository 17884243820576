import React, { memo } from 'react';

type BlockTitleProps = {
    title: string;
    buttons?: {
        title: string;
        className: string;
        iconClassName?: string;
        style?: React.CSSProperties | undefined;
        onPress?: () => void;
    }[];
    style?: React.CSSProperties | undefined;
};

export const BlockTitle = memo(({ title, buttons, style }: BlockTitleProps) => {
    return (
        <div className="block block-title" style={style}>
            <div className="block-title-wrapper">{title}</div>
            {!!buttons?.length && (
                <div className="block-title-actions">
                    {buttons?.map((button, index) => (
                        <div
                            role="button"
                            tabIndex={index}
                            className={button.className}
                            style={
                                button?.style ||
                                (index !== buttons?.length - 1
                                    ? {
                                          marginRight: 12,
                                      }
                                    : undefined)
                            }
                            onClick={button?.onPress}
                            onKeyDown={button?.onPress}
                        >
                            {!!button.iconClassName && (
                                <em className={button.iconClassName} />
                            )}
                            {button.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});
