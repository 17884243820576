import { createSelector } from 'reselect';
import {
    AppState,
    NotificationsState,
    INotification,
    ReportsState,
} from '../types/types';

// SELECTORS
export const getNotifications = (state: AppState) => state.notifications;
export const getNotificationById = (id: number) => {
    return createSelector(
        getNotifications,
        (notifications: NotificationsState) => {
            return notifications[id];
        },
    );
};

// ACTION TYPES
const NOTIFICATIONS_SET = 'NOTIFICATIONS_SET ';
const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';
const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';

type ACTION =
    | {
          type: typeof NOTIFICATIONS_SET;
          payload?: INotification[];
      }
    | {
          type: typeof NOTIFICATIONS_UPDATE;
          payload?: INotification[];
      }
    | {
          type: typeof NOTIFICATION_UPDATE;
          payload?: INotification;
      }
    | {
          type: typeof NOTIFICATION_ADD;
          payload?: INotification;
      }
    | {
          type: typeof NOTIFICATION_DELETE;
          payload?: INotification['id'];
      };

// ACTIONS
export const notificationsSet = (payload?: {
    [key: number]: INotification;
}) => ({
    type: NOTIFICATIONS_SET,
    payload,
});
export const notificationsUpdate = (payload?: INotification[]) => ({
    type: NOTIFICATIONS_UPDATE,
    payload,
});

export const notificationUpdate = (payload?: INotification) => ({
    type: NOTIFICATION_UPDATE,
    payload,
});
export const notificationAdd = (payload?: INotification) => ({
    type: NOTIFICATION_ADD,
    payload,
});

export const notificationDelete = (payload?: INotification['id']) => ({
    type: NOTIFICATION_DELETE,
    payload,
});

const INITIAL_STATE: ReportsState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case NOTIFICATIONS_SET:
            const notifications: NotificationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    notification =>
                        (notifications[notification.id] = notification),
                );
            }
            return notifications;
        case NOTIFICATIONS_UPDATE:
            const nextNotifications: NotificationsState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    notification =>
                        (nextNotifications[notification.id] = notification),
                );
            }
            return { ...state, ...nextNotifications };
        case NOTIFICATION_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case NOTIFICATION_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case NOTIFICATION_DELETE:
            const notificationsState = { ...state };
            if (action.payload) {
                delete notificationsState[action.payload];
            }
            return notificationsState;

        default:
            return state;
    }
};
