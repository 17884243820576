import { AppState, User } from '../types/types';

// SELECTORS
export const getMyUser = (state: AppState) => state.user;
export const getMyUserId = (state: AppState) => state.user?.id;
export const getMyUserName = (state: AppState) => {
    return `${!!state.user?.first_name && state.user?.first_name} ${
        !!state.user?.last_name && state.user?.last_name
    }`;
};
export const getMyUserFcmTokens = (state: AppState) => state.user?.fcm_tokens;
export const getMyUserAvatarUid = (state: AppState) => state.user?.avatar?.uid;
export const getDefaultCurrency = (state: AppState) => state.user?.currency;

// ACTION TYPES
const MY_USER_SET = 'MY_USER_SET';
const MY_USER_UPDATE = 'MY_USER_UPDATE';

type ACTION =
    | {
          type: typeof MY_USER_SET;
          payload: User;
      }
    | {
          type: typeof MY_USER_UPDATE;
          payload: Partial<User>;
      };

// ACTIONS
export const myUserSet = (payload: User | null) => ({
    type: MY_USER_SET,
    payload,
});
export const myUserUpdate = (payload: Partial<User>) => ({
    type: MY_USER_UPDATE,
    payload,
});

const INITIAL_STATE = {} as User;

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case MY_USER_SET:
            return action.payload;
        case MY_USER_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
