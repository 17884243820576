import styled from 'styled-components';

export const Row = styled.div<{
    alignItems?: 'flex-start' | 'center';
    justifyContent?: 'space-between' | 'center';
    marginTop?: number;
    marginBottom?: number;
}>`
    margin-top: ${({ marginTop = 0 }) => marginTop}px;
    display: flex;
    flex-direction: row;
    align-items: ${({ alignItems = 'center' }) => alignItems};
    justify-content: ${({ justifyContent = 'unset' }) => justifyContent};
    margin-bottom: ${({ marginBottom = 0 }) => marginBottom}px;
`;
