import { createSelector } from 'reselect';
import { selectCurrentOrganizationCurrentGroupId } from './myOrganizations';
import { AppState, TGroup, TGroupsMetaState } from '../types/types';

// SELECTORS
export const selectGroupsState = (state: AppState) => state.groups;
export const selectGroups = createSelector(
    selectGroupsState,
    groupsState => groupsState.data,
);
export const selectGroupsMeta = createSelector(
    selectGroupsState,
    groupsState => groupsState.meta,
);

export const selectGroupById = (id: number) => {
    return createSelector(selectGroups, groups =>
        groups?.find(group => group?.id === id),
    );
};

export const selectCurrentGroupForCurrentOrganization = createSelector(
    selectCurrentOrganizationCurrentGroupId,
    selectGroups,
    (id, groups) => groups?.find(group => group?.group_id === id),
);

// ACTION TYPES
const GROUPS_SET = 'GROUPS_SET';
const GROUPS_UPDATE = 'GROUPS_UPDATE';
const GROUP_SET = 'GROUP_SET';

type ACTION =
    | {
          type: typeof GROUPS_SET;
          payload: TGroupsMetaState;
      }
    | {
          type: typeof GROUPS_UPDATE;
          payload: TGroupsMetaState;
      }
    | {
          type: typeof GROUP_SET;
          payload: TGroup;
      };

// ACTIONS
export const groupsSet = (payload?: TGroupsMetaState) => ({
    type: GROUPS_SET,
    payload,
});
export const groupsUpdate = (payload: TGroupsMetaState) => ({
    type: GROUPS_UPDATE,
    payload,
});
export const groupSet = (payload: TGroup) => ({
    type: GROUP_SET,
    payload,
});

const INITIAL_STATE: TGroupsMetaState = {
    data: [],
    links: null,
    meta: {
        current_page: 1,
        from: null,
        last_page: 1,
        links: null,
        path: '',
        per_page: 10,
        to: null,
        total: 0,
    },
};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case GROUPS_SET:
            return action.payload || INITIAL_STATE;
        case GROUPS_UPDATE:
            return {
                data: [...state.data, ...action.payload.data],
                links: action.payload.links,
                meta: action.payload.meta,
            };
        case GROUP_SET: {
            const data = [...state.data];
            const index = data?.findIndex(
                group => group?.id === action?.payload?.id,
            );
            if (index > -1) {
                data[index] = action.payload;
            } else {
                data[data?.length + 1] = action.payload;
            }
            return {
                data,
                links: state.links,
                meta: state.meta,
            };
        }
        default:
            return state;
    }
};
