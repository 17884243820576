import styled from 'styled-components';

type Props = { type: 'success' | 'fail' };

export const MessageContainer = styled.div<Props>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background: ${({ type }) =>
        type === 'success'
            ? 'rgba(25, 149, 107, 0.5)'
            : 'rgba(250, 0, 0, 0.5)'};
`;

export const Icon = styled.em<Props>`
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    background: #ffffff;
    border-radius: 20px;

    color: ${({ type }) =>
        type === 'success' ? 'rgba(25, 149, 107, 1)' : 'rgba(255, 0, 0, 1)'};
`;

export const MessageText = styled.div`
    margin-left: 5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    color: #ffffff;
`;

export const FactoringButtonWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: 101%;
`;

export const TransactionButtonWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: 101%;
`;
