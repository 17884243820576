import React, { memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type TabProps = {
    tab: { name: string; active?: boolean; link?: string };
    tabIndex: number;
};

export const Tab = memo(({ tab, tabIndex }: TabProps) => {
    const history = useHistory();
    const location = useLocation();
    const openTab = () => {
        if (tab.link) {
            history.push(tab.link);
        }
    };
    const active = tab?.active || location?.pathname === tab.link;
    return (
        <div
            key={tabIndex?.toString()}
            tabIndex={tabIndex}
            className="header-menu"
            style={{ marginRight: 20 }}
            role="button"
            onClick={openTab}
            onKeyDown={openTab}
        >
            <div className={`header-menu-item ${active ? 'active' : ''}`}>
                {tab?.name}
            </div>
        </div>
    );
});
