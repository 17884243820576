import { AppState, Organization } from '../types/types';

// SELECTORS
export const getConnectedFactoringOrganizations = (state: AppState) =>
    state?.connectedFactoringOrganizations;

// ACTION TYPES
const CONNECTED_FACTORING_ORGANIZATIONS_SET =
    'CONNECTED_FACTORING_ORGANIZATIONS_SET';

type ACTION = {
    type: typeof CONNECTED_FACTORING_ORGANIZATIONS_SET;
    payload?: Organization[];
};

// ACTIONS
export const connectedFactoringOrganizationsSet = (
    payload?: Organization[],
) => ({
    type: CONNECTED_FACTORING_ORGANIZATIONS_SET,
    payload,
});

const INITIAL_STATE: [] = [];

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case CONNECTED_FACTORING_ORGANIZATIONS_SET:
            return action.payload;

        default:
            return state;
    }
};
