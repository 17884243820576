import { createSelector } from 'reselect';
import { AppState, Circle, CirclesState } from '../types/types';

// SELECTORS
export const getCircles = (state: AppState) => state.circles;
export const getCircleById = (id: number) => {
    return createSelector(getCircles, (circles: CirclesState) => {
        return circles[id];
    });
};

// ACTION TYPES
const CIRCLES_SET = 'CIRCLES_SET';
const CIRCLES_UPDATE = 'CIRCLES_UPDATE';
const CIRCLE_UPDATE = 'CIRCLE_UPDATE';
const CIRCLE_ADD = 'CIRCLE_ADD';
const CIRCLE_DELETE = 'CIRCLE_DELETE';

type ACTION =
    | {
          type: typeof CIRCLES_SET;
          payload?: Circle[];
      }
    | {
          type: typeof CIRCLES_UPDATE;
          payload?: Circle[];
      }
    | {
          type: typeof CIRCLE_UPDATE;
          payload?: Circle;
      }
    | {
          type: typeof CIRCLE_ADD;
          payload?: Circle;
      }
    | {
          type: typeof CIRCLE_DELETE;
          payload?: Circle['id'];
      };

// ACTIONS
export const circlesSet = (payload?: { [key: number]: Circle }) => ({
    type: CIRCLES_SET,
    payload,
});
export const circlesUpdate = (payload?: Circle[]) => ({
    type: CIRCLES_UPDATE,
    payload,
});

export const circleUpdate = (payload?: Circle) => ({
    type: CIRCLE_UPDATE,
    payload,
});
export const circleAdd = (payload?: Circle) => ({
    type: CIRCLE_ADD,
    payload,
});

export const circleDelete = (payload?: Circle['id']) => ({
    type: CIRCLE_DELETE,
    payload,
});

const INITIAL_STATE: CirclesState = {};

// REDUCER
export default (state = INITIAL_STATE, action: ACTION) => {
    switch (action.type) {
        case CIRCLES_SET:
            const circles: CirclesState = {};
            if (action.payload?.length) {
                action.payload.forEach(circle => (circles[circle.id] = circle));
            }
            return circles;
        case CIRCLES_UPDATE:
            const nextCircles: CirclesState = {};
            if (action.payload?.length) {
                action.payload.forEach(
                    circle => (nextCircles[circle.id] = circle),
                );
            }
            return { ...state, ...nextCircles };
        case CIRCLE_UPDATE:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case CIRCLE_ADD:
            if (action.payload?.id) {
                return {
                    ...state,
                    [action.payload.id]: action.payload,
                };
            }
            return state;
        case CIRCLE_DELETE:
            const circlesState = { ...state };
            if (action.payload) {
                delete circlesState[action.payload];
            }
            return circlesState;

        default:
            return state;
    }
};
